import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { FOLDERS, SERVICES, THIRD_PARTIES, THIRD_PARTY_ID, TPRM } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';

import { DeleteTPRMThirdPartyModal, DeleteTPRMThirdPartyModalProps } from './DeleteTPRMThirdPartyModal/DeleteTPRMThirdPartyModal';
import styles from './ManageTPRMThirdParties.module.css';
import { SaveTPRMThirdPartyModal, SaveTPRMThirdPartyModalProps } from './SaveTPRMThirdPartyModal/SaveTPRMThirdPartyModal';
import { ThirdPartyListing, ThirdPartyListingProps } from './ThirdPartyListing/ThirdPartyListing';
import { ManageTPRMServicesRouteState } from '../ManageTPRMServices/ManageTPRMServices';

export interface ManageTPRMThirdPartiesProps {
    tprmApi: TPRMApi;
}

export enum Modal {
    DeleteTPRMThirdPartyModal,
    SaveTPRMThirdPartyModal,
    None,
}

export const ManageTPRMThirdParties = (props: ManageTPRMThirdPartiesProps) => {
    const navigate = useNavigate();
    const { users } = useUsers();
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>();
    const [displayedModal, setDisplayedModal] = useState<Modal>();
    const [selectedThirdParty, setSelectedThirdParty] = useState<ThirdPartyResponseWithServices>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();

    const getThirdParties = useCallback(async (): Promise<void> => {
        try {
            const thirdPartyResponse = await props.tprmApi.getThirdParties();
            setThirdParties(thirdPartyResponse.data);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.tprmApi]);

    useEffect(() => {
        getThirdParties();
    }, [getThirdParties]);

    const handleRequestError = (error: Error): void => {
        if (isForbiddenResponseError(error)) {
            setTprmAccessDenied(true);
        } else {
            console.error('Error: ', error);
        }
    };

    const displayModal = (modal: Modal): void => {
        setDisplayedModal(modal);
    };

    const hideModal = (): void => {
        setDisplayedModal(Modal.None);
        setSelectedThirdParty(undefined);
    };

    const selectedModifyThirdParty = (thirdPartyId: string): void => {
        if (thirdParties) {
            const thirdParty = thirdParties.find((v) => v.id === thirdPartyId);
            setDisplayedModal(Modal.SaveTPRMThirdPartyModal);
            setSelectedThirdParty(thirdParty);
        }
    };

    const selectedDeleteThirdParty = (thirdPartyId: string): void => {
        if (thirdParties) {
            const thirdParty = thirdParties.find((v) => v.id === thirdPartyId);
            setDisplayedModal(Modal.DeleteTPRMThirdPartyModal);
            setSelectedThirdParty(thirdParty);
        }
    };

    const getDeleteTPRMThirdPartyModalProps = (): DeleteTPRMThirdPartyModalProps => {
        const deleteTPRMThirdPartyModalProps: DeleteTPRMThirdPartyModalProps = {
            hideModal: hideModal,
            thirdPartyDeleted: () => getThirdParties(),
            tprmApi: props.tprmApi,
            thirdParty: selectedThirdParty!,
        };

        return deleteTPRMThirdPartyModalProps;
    };

    const createServiceForThirdParty = (thirdParty: ThirdPartyResponseWithServices): void => {
        const state: ManageTPRMServicesRouteState = { createServiceForThirdParty: thirdParty };
        navigate(`/${TPRM}/${SERVICES}`, { state: state });
    };

    const viewAllServicesForThirdParty = (thirdParty: ThirdPartyResponseWithServices): void => {
        navigate(`/${TPRM}/${SERVICES}?${THIRD_PARTY_ID}=${thirdParty.id}`);
    };

    const viewFolders = (thirdPartyId: string): void => {
        navigate(`/${TPRM}/${THIRD_PARTIES}/${thirdPartyId}/${FOLDERS}`);
    };

    if (tprmAccessDenied) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>{UNAUTHORIZED_MESSAGE}</Text>
            </div>
        );
    }

    if (thirdParties && users) {
        const thirdPartyListingProps: ThirdPartyListingProps = {
            users: users,
            thirdParties: thirdParties,
            selectedModifyThirdParty: selectedModifyThirdParty,
            selectedDeleteThirdParty: selectedDeleteThirdParty,
            selectedCreateServiceForThirdParty: createServiceForThirdParty,
            selectedViewAllServicesForThirdParty: viewAllServicesForThirdParty,
            selectedViewFolders: viewFolders,
        };

        const saveTPRMThirdPartyModalProps: SaveTPRMThirdPartyModalProps = {
            hideModal: hideModal,
            tprmApi: props.tprmApi,
            thirdParty: selectedThirdParty,
            thirdPartySaved: () => getThirdParties(),
        };

        return (
            <>
                {displayedModal === Modal.SaveTPRMThirdPartyModal && <SaveTPRMThirdPartyModal {...saveTPRMThirdPartyModalProps} />}
                {displayedModal === Modal.DeleteTPRMThirdPartyModal && <DeleteTPRMThirdPartyModal {...getDeleteTPRMThirdPartyModalProps()} />}

                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue">
                                Manage Third Parties
                            </Text>
                            <Button variant="primary" onClick={() => displayModal(Modal.SaveTPRMThirdPartyModal)} fontAwesomeImage={faPlus}>
                                CREATE NEW THIRD PARTY
                            </Button>
                        </div>
                    </PageContent>
                </PageBackground>

                <PageBackground color="white">
                    <PageContent>
                        <ThirdPartyListing {...thirdPartyListingProps} />
                    </PageContent>
                </PageBackground>
            </>
        );
    }

    return <Placeholder />;
};

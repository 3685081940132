import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { GovernanceApi } from 'Api/Governance/GovernanceApi';
import { Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { ViewGovernanceLibraryDefinitionModal, ViewGovernanceLibraryDefinitionModalProps } from 'Components/Governance/ViewGovernanceLibraryDefinitionModal/ViewGovernanceLibraryDefinitionModal';
import { AssociatedControlsModal, AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { CREATE, GOVERNANCE } from 'Config/Paths';
import { Navigator } from 'Helpers/Navigator';
import { GovernanceVersion, TextBasedGovernanceVersion } from 'Models/Governance';

import { ActiveGovernanceTab, ActiveGovernanceTabProps } from './ActiveGovernanceTab/ActiveGovernanceTab';
import { RetiredGovernanceTab, RetiredGovernanceTabProps } from './RetiredGovernanceTab/RetiredGovernanceTab';

export interface GovernanceLibraryProps {
    governanceApi: GovernanceApi;
    documentApi: DocumentApi;
    controlsApi: ControlsApi;
    navigator: Navigator;
}

export enum PageElements {
    GovernanceCommentModal,
    AssociatedControlModal,
    None,
}

export const ACTIVE_TAB_KEY = 'activeGovernance';
export const RETIRED_TAB_KEY = 'retiredGovernance';

export const GovernanceLibrary = (props: GovernanceLibraryProps) => {
    const location = useLocation();
    const [activeVersions, setActiveVersions] = useState<GovernanceVersion[]>();
    const [retiredVersions, setRetiredVersions] = useState<GovernanceVersion[]>();
    const [displayedElement, setDisplayedElement] = useState<PageElements>(PageElements.None);
    const { users } = useUsers();
    const [textBasedGovernanceBeingViewed, setTextBasedGovernanceBeingViewed] = useState<TextBasedGovernanceVersion>();
    const [governanceWithAssociatedControls, setGovernanceWithAssociatedControls] = useState<GovernanceVersion>();

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    const defaultActiveTab = (() => {
        if (location.hash && location.hash.length > 0) {
            return location.hash.substring(1);
        }

        return ACTIVE_TAB_KEY;
    })();

    const getActiveVersions = useCallback(async (): Promise<void> => {
        try {
            const versions = (await props.governanceApi.getAllActiveVersions()).data;
            setActiveVersions(versions);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.governanceApi]);

    const getRetiredVersions = useCallback(async (): Promise<void> => {
        try {
            const versions = (await props.governanceApi.getAllRetiredVersions()).data;
            setRetiredVersions(versions);
        } catch (error) {
            handleRequestError(error);
        }
    }, [props.governanceApi]);

    const updateGovernanceListing = useCallback((): void => {
        getActiveVersions();
        getRetiredVersions();
    }, [getActiveVersions, getRetiredVersions]);

    useEffect(() => {
        updateGovernanceListing();
    }, [updateGovernanceListing]);

    const displayGovernanceTextModal = (governance: TextBasedGovernanceVersion): void => {
        setTextBasedGovernanceBeingViewed(governance);
        setDisplayedElement(PageElements.GovernanceCommentModal);
    };

    const displayGovernanceWithAssociatedControlsModal = (governanceWithAssociatedControls: GovernanceVersion): void => {
        setGovernanceWithAssociatedControls(governanceWithAssociatedControls);
        setDisplayedElement(PageElements.AssociatedControlModal);
    };

    const viewGovernanceLibraryDefinitionModalProps = (governance: TextBasedGovernanceVersion): ViewGovernanceLibraryDefinitionModalProps => {
        return {
            hideModal: () => setDisplayedElement(PageElements.None),
            governance: governance,
        };
    };

    const associatedControlModalProps = (governanceWithAssociatedControls: GovernanceVersion): AssociatedControlsModalProps => {
        return {
            hideModal: () => setDisplayedElement(PageElements.None),
            associatedControls: governanceWithAssociatedControls.associated_controls,
        };
    };

    if (users && activeVersions && retiredVersions) {
        const activeGovernanceTabProps: ActiveGovernanceTabProps = {
            governanceApi: props.governanceApi,
            documentApi: props.documentApi,
            governanceItems: activeVersions,
            users: users,
            openExternalUrl: (url) => props.navigator.openExternalUrl(url, true),
            displayGovernanceTextModal: displayGovernanceTextModal,
            displayGovernanceWithAssociatedControlsModal: displayGovernanceWithAssociatedControlsModal,
        };

        const retiredGovernanceTabProps: RetiredGovernanceTabProps = {
            governanceApi: props.governanceApi,
            documentApi: props.documentApi,
            governanceItems: retiredVersions,
            users: users,
            openExternalUrl: (url) => props.navigator.openExternalUrl(url, true),
            displayGovernanceTextModal: displayGovernanceTextModal,
            displayGovernanceWithAssociatedControlsModal: displayGovernanceWithAssociatedControlsModal,
        };

        return (
            <>
                {displayedElement === PageElements.GovernanceCommentModal && textBasedGovernanceBeingViewed && <ViewGovernanceLibraryDefinitionModal {...viewGovernanceLibraryDefinitionModalProps(textBasedGovernanceBeingViewed)} />}
                {displayedElement === PageElements.AssociatedControlModal && governanceWithAssociatedControls && <AssociatedControlsModal {...associatedControlModalProps(governanceWithAssociatedControls)} />}
                <Page
                    headerTitle="Governance Integration"
                    headerButtons={
                        <Link variant="primaryButton" to={`/${GOVERNANCE}/${CREATE}`} fontAwesomeImage={faPlus}>
                            Add New
                        </Link>
                    }
                    body={[
                        {
                            content: (
                                <PrimaryTabs defaultActiveTab={defaultActiveTab} removePadding>
                                    <Tab eventKey={ACTIVE_TAB_KEY} title="Active">
                                        <ActiveGovernanceTab {...activeGovernanceTabProps} />
                                    </Tab>
                                    <Tab eventKey={RETIRED_TAB_KEY} title="Retired">
                                        <RetiredGovernanceTab {...retiredGovernanceTabProps} />
                                    </Tab>
                                </PrimaryTabs>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else {
        return <Placeholder />;
    }
};

/*
	Metric.tsx -- A component for rendering a Metric (e.g. Percent of Threats Blocked) within ExternalIntegrations. Designed to be a child of the Integration component.
*/
import { faPaperclip, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';

import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { Switch } from 'Components/Buttons/Switch';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { IntegrationName, Metric as MetricModel, MetricName } from 'Models/ExternalIntegrations';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './Metric.module.css';
import { Modal } from '../../ExternalIntegrations';

export interface MetricProps {
    externalIntegrationsApi: ExternalIntegrationsApi;
    displayAssociatedControls: (metric: MetricModel) => void; // View the Controls that are currently mapped to a Metric.
    displayControlAssociation: (controlAssociationTitleSecondary: string, controlAssociationIdentifier: any, control_mapping: OperationalControl[]) => void; // Map a Metric to Controls.
    displayMetricModal: (modal: Modal, metric: MetricModel) => void;
    setSuccessFailureMessages: (newSuccessMessage: string | undefined, newFailureMessage: string | undefined) => void;
    integrationName: IntegrationName;
    metric: MetricModel;
}
interface MetricState {
    enabled: boolean;
}

export class Metric extends Component<MetricProps, MetricState> {
    constructor(props: MetricProps) {
        super(props);

        this.state = {
            enabled: this.props.metric.enabled ? this.props.metric.enabled : false,
        };
    }

    /**
     * Toggle the Metric enabled/disabled.
     */
    toggleMetricHandler = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
        this.props.setSuccessFailureMessages(undefined, undefined);

        const newEnabledValue = !this.state.enabled;
        this.setState({ enabled: newEnabledValue });
        try {
            await this.props.externalIntegrationsApi.toggleMetric(this.props.integrationName, this.props.metric.metric_name, { enabled: newEnabledValue });
        } catch (err) {
            this.props.setSuccessFailureMessages(undefined, err.message);
        }
    };

    /**
     * Renders the column that shows the Controls that the Metric is mapped to.
     */
    renderControlMapping = (): JSX.Element => {
        if (this.props.metric.metric_name === MetricName.THIRD_PARTY_MONITORING) {
            return <Text noStyles>N/A</Text>;
        } else {
            return <Button variant="linkText" size="sm" onClick={() => this.props.displayAssociatedControls(this.props.metric)}>{`${this.props.metric.control_mapping?.length || '0'} ${this.props.metric.control_mapping && this.props.metric.control_mapping.length === 1 ? 'Control' : 'Controls'}`}</Button>;
        }
    };

    /**
     * Generates the props for the overflow menu for each Metric. Certain TPRM Metrics (e.g. Third-Party Monitoring) cannot be mapped to Controls, so that item within the overflow menu is conditional.
     */
    getOverflowMenuProps = (): OverflowMenuProps => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Description',
                    onClickAction: () => this.props.displayMetricModal(Modal.MetricDescription, this.props.metric),
                    icon: faQuestion,
                },
            ],
            accessibilityTitle: `${this.props.metric.metric_name} Menu`,
        };

        if (this.props.metric.metric_name !== MetricName.THIRD_PARTY_MONITORING) {
            overflowMenuProps.overflowItems.push({
                text: 'Map to Control',
                onClickAction: () => this.props.displayControlAssociation(`${this.props.integrationName}: ${this.props.metric.metric_name}`, { integrationName: this.props.integrationName, metricName: this.props.metric.metric_name }, this.props.metric.control_mapping ?? []),
                icon: faPaperclip,
            });
        }

        return overflowMenuProps;
    };

    render(): JSX.Element {
        return (
            <div className={styles.metricContainer}>
                <Switch checked={this.state.enabled} onChange={this.toggleMetricHandler} />
                <div className={styles.metricName}>
                    <Text noStyles>{this.props.metric.metric_name}</Text>
                </div>
                <div className={styles.metricLastPoll}>
                    <Text noStyles>Last Poll:</Text>
                    {this.props.metric.last_poll_success === true && (
                        <Text noStyles color="darkGreen">
                            &nbsp;SUCCESS&nbsp;
                        </Text>
                    )}
                    {this.props.metric.last_poll_success === false && (
                        <Text noStyles color="red">
                            &nbsp;FAIL&nbsp;
                        </Text>
                    )}
                    {this.props.metric.last_poll_success === undefined && <Text noStyles>&nbsp;N/A&nbsp;</Text>}
                    {this.props.metric.last_poll_timestamp && <Text noStyles>{`(${iso8601ToUsTimestampLong(this.props.metric.last_poll_timestamp)})`}</Text>}
                </div>
                <div className={styles.metricControlMapping}>{this.renderControlMapping()}</div>
                <OverflowMenu {...this.getOverflowMenuProps()} />
            </div>
        );
    }
}

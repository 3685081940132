import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { EvidenceExportRow } from 'Models/Exports';
import { effectivenessAsString } from 'Models/OperationalControls';

import styles from '../DataExports.module.css';

export interface EvidenceControlProps {
    exportsApi: ExportsApi;
    disableVirtualization?: boolean;
}

export const EvidenceControl = (props: EvidenceControlProps): JSX.Element => {
    const [error, setError] = useState<string>();
    const [controlEvidence, setControlEvidence] = useState<EvidenceExportRow[]>();
    const { users } = useUsers();

    useEffect(() => {
        const getEvidenceResponse = async () => {
            try {
                const evidenceResponse = await props.exportsApi.getEvidenceControlExport();
                setControlEvidence(evidenceResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };
        getEvidenceResponse();
    }, [props.exportsApi]);

    const columns: GridColumn<EvidenceExportRow>[] = [
        dateColumn('submission_date', 'Submission Date'),
        {
            field: 'control',
            headerName: 'Control',
            width: 300,
            valueGetter: (value) => getHumanReadableControlIdFromControl(value),
            type: 'string',
        },
        { field: 'submitter', headerName: 'Submitter', width: 300, valueGetter: (value) => getUserNameFromSubject(value, users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'submitter_department', headerName: 'Submitter Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.submitter, users) },
        { field: 'file_name', headerName: 'File Name', width: 300 },
        { field: 'external_url', headerName: 'External URL', width: 300 },
        { field: 'comment', headerName: 'Comment', width: 300 },
        { field: 'evidence_effectiveness', headerName: 'Evidence Effectiveness', width: 300, valueGetter: (value) => effectivenessAsString(value) },
    ];

    if (controlEvidence) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                            <BreadcrumbText>Control Evidence </BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1">Control Evidence Export</Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        {/* The same evidence item may exist for multiple different controls, so getRowId must include the control as well. */}
                        <DataGrid columns={columns} getRowId={(controlEvidence: EvidenceExportRow) => `${controlEvidence.id} ${controlEvidence.control.identifier}`} rows={controlEvidence} title="Control Evidence Export Preview" fileName="Control Evidence" disableVirtualization={props.disableVirtualization} />
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};

import { useEffect, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { Text } from 'Components/Text/Text';
import { DashboardType } from 'Models/Dashboards';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';

import { TPRMCompletedAssessmentsChart } from './TPRMAssessmentsChart/TPRMCompletedAssessmentsChart';
import { TPRMUpcomingAssessmentsChart } from './TPRMAssessmentsChart/TPRMUpcomingAssessmentsChart';
import { useThirdPartyServicesQuery } from './TPRMDashboard.hooks';
import styles from './TPRMDashboard.module.css';
import { TPRMDonutCharts } from './TPRMDonutCharts/TPRMDonutCharts';
import { RiskPortfolioHistoryChart } from './TPRMRiskPortfolioHistoryChart/TPRMRiskPortfolioHistoryChart';
import { TPRMRiskProgressCharts } from './TPRMRiskProgressCharts/TPRMRiskProgressCharts';
import { TPRMServiceCallouts } from './TPRMServiceCallouts/TPRMServiceCallouts';
import { DashboardClosedExceptions } from '../Components/DashboardsHistoricalExceptions/DashboardsHistoricalExceptions';
import { DashboardClosedIssues } from '../Components/DashboardsHistoricalIssues/DashboardsHistoricalIssues';
import { DashboardsTrackingExceptions } from '../Components/DashboardsTrackingExceptions/DashboardsTrackingExceptions';
import { DashboardOpenIssues } from '../Components/DashboardsTrackingIssues/DashboardsTrackingIssues';

export interface TPRMDashboardProps {
    dashboardApi: DashboardApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    tprmApi: TPRMApi;
}

export const TPRMDashboard = (props: TPRMDashboardProps): JSX.Element => {
    const { thirdPartyCount, thirdPartyServices, overdueAssessmentsCount, thirdPartyServicesError } = useThirdPartyServicesQuery(props.dashboardApi);
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>();
    const [thirdPartyError, setThirdPartyError] = useState<string>();

    useEffect(() => {
        const getThirdParties = async (): Promise<void> => {
            try {
                const thirdPartyResponse = await props.tprmApi.getThirdParties();
                const thirdParties = thirdPartyResponse.data;

                setThirdParties(thirdParties);
            } catch (error) {
                setThirdPartyError(error.message);
            }
        };
        getThirdParties();
    }, [props.tprmApi]);

    return (
        <PageContent>
            <Text variant="Header1" color="white">
                Third Parties and Third-Party Services
            </Text>
            <div className={styles.thirdPartyServiceSection}>
                <TPRMServiceCallouts overdueAssessments={overdueAssessmentsCount} thirdPartyCount={thirdPartyCount} thirdPartyServicesCount={thirdPartyServices?.length} thirdPartyServiceError={thirdPartyServicesError} />
            </div>
            <div className={styles.thirdPartyServiceSection}>
                <TPRMDonutCharts thirdPartyServices={thirdPartyServices} thirdPartyServicesError={thirdPartyServicesError} />
            </div>
            <div className={styles.thirdPartyServiceSection}>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <TPRMCompletedAssessmentsChart dashboardApi={props.dashboardApi} />
                    </PageCell>
                </div>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <TPRMUpcomingAssessmentsChart thirdPartyServices={thirdPartyServices} thirdPartyServicesError={thirdPartyServicesError} />
                    </PageCell>
                </div>
            </div>
            <div className={styles.thirdPartyServiceSection}>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <TPRMRiskProgressCharts thirdPartyServices={thirdPartyServices} thirdPartyServicesError={thirdPartyServicesError} />
                    </PageCell>
                </div>
                <div className={styles.section2}>
                    <PageCell variant="transparentBlue">
                        <RiskPortfolioHistoryChart dashboardApi={props.dashboardApi} />
                    </PageCell>
                </div>
            </div>
            <div className={styles.cellHeader}>
                <Text variant="Header1" color="white">
                    Issue Management
                </Text>
            </div>
            <div className={styles.overviewSection}>
                <DashboardClosedIssues issuesApi={props.issuesApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />
                <DashboardOpenIssues issuesApi={props.issuesApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />
            </div>
            <div className={styles.cellHeader}>
                <Text variant="Header1" color="white">
                    Exception Management
                </Text>
            </div>
            <div className={styles.overviewSection}>
                <DashboardClosedExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />
                <DashboardsTrackingExceptions exceptionsApi={props.exceptionsApi} dashboardType={DashboardType.THIRD_PARTY} thirdParties={thirdParties} thirdPartyError={thirdPartyError} />
            </div>
        </PageContent>
    );
};

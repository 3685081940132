import { cloneDeep, isUndefined } from 'lodash-es';
import { useState } from 'react';

import { Placeholder } from 'Components/Placeholder/Placeholder';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Text } from 'Components/Text/Text';
import { undefinedComparator } from 'Helpers/Compare';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { ResidualRiskFilterOptionTypes, RiskRatingFilterOptionTypes, ServiceListingSortFilterOptions, ServiceSummary, ThirdPartyResponse } from 'Models/TPRM';
import { Filter, Filters, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './ServiceListing.module.css';
import { ServiceListingBodyToolbar, ServiceListingBodyToolbarProps } from './ServiceListingBodyToolbar/ServiceListingBodyToolbar';
import { ServiceListingGridView, ServiceListingGridViewProps } from './ServiceListingGridView/ServiceListingGridView';
import { ServiceListingTableView, ServiceListingTableViewProps } from './ServiceListingTableView/ServiceListingTableView';

export interface ServiceListingProps {
    thirdParties: ThirdPartyResponse[];
    services: ServiceDisplayData[];
    users: UserResponse[];
    defaultThirdPartyByWhichToFilter?: ThirdPartyResponse;
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
}

export interface ServiceDisplayData {
    thirdPartyName: string;
    thirdPartyId: string;
    serviceName: string;
    serviceID: string;
    riskRating: number;
    thirdPartyManagerId: string;
    dateCreated: string;
    assessmentDueDate?: string;
    commonAssessmentChildren: ServiceSummary[];
    residualRisk: number;
    [key: string]: any;
}

export enum ServiceListingView {
    LIST = 'list',
    GRID = 'grid',
}

export const ServiceListing = (props: ServiceListingProps) => {
    const [activeServiceListingView, setActiveServiceListingView] = useState<ServiceListingView>(ServiceListingView.GRID);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>(() => {
        let selectedFilterOptions: Filters = {};

        if (props.defaultThirdPartyByWhichToFilter) {
            selectedFilterOptions = {
                thirdPartyId: {
                    key: 'thirdPartyId',
                    value: [props.defaultThirdPartyByWhichToFilter.id],
                },
            };
        }

        return selectedFilterOptions;
    });
    const [currentSort, setCurrentSort] = useState<string>(ServiceListingSortFilterOptions.SERVICE);
    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.ASC);

    const createFilterOptions = (thirdParties: ThirdPartyResponse[]): GroupedOptions[] => {
        const thirdPartyOptions: GroupOptionType[] = [];
        thirdParties.forEach((thirdParty) => {
            thirdPartyOptions.push({
                groupId: ServiceListingSortFilterOptions.THIRD_PARTY_ID,
                label: thirdParty.name,
                value: thirdParty.id,
            });
        });
        const filterOptions: GroupedOptions[] = [
            {
                label: 'Third Parties',
                options: thirdPartyOptions,
            },
            {
                label: 'Service Risk Rating',
                options: RiskRatingFilterOptionTypes,
            },
            {
                label: 'Service Residual Risk',
                options: ResidualRiskFilterOptionTypes,
            },
            {
                label: 'Assessment Due Date',
                options: [
                    {
                        groupId: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
                        label: 'Set',
                        value: 1,
                    },
                    {
                        groupId: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
                        label: 'Not Set',
                        value: 0,
                    },
                ],
            },
        ];
        return filterOptions;
    };

    const getDefaultSelectedOptions = (): GroupOptionType[] | undefined => {
        if (props.defaultThirdPartyByWhichToFilter) {
            return [
                {
                    groupId: ServiceListingSortFilterOptions.THIRD_PARTY_ID,
                    label: props.defaultThirdPartyByWhichToFilter.name,
                    value: props.defaultThirdPartyByWhichToFilter.id,
                },
            ];
        }

        return undefined;
    };

    const filterAndSortThirdPartyServices = (): ServiceDisplayData[] => {
        let serviceList = [...props.services];

        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                serviceList = serviceList.filter((service) => {
                    if (filter.key === ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE) {
                        // Filters can only be numbers or strings. 1 is Set 0 is Not Set
                        return (filter.value.includes(1) && !isUndefined(service.assessmentDueDate)) || (filter.value.includes(0) && isUndefined(service.assessmentDueDate));
                    }
                    return filter.value.includes(service[filter.key]);
                });
            }
        }

        if (!currentSort) {
            return serviceList;
        }
        return serviceList.sort((serviceA, serviceB) => {
            let sortResult = 0;

            switch (currentSort) {
                case ServiceListingSortFilterOptions.RISKRATING:
                case ServiceListingSortFilterOptions.RESIDUAL_RISK:
                    const riskA = serviceA[currentSort];
                    const riskB = serviceB[currentSort];
                    if (riskA === riskB) {
                        sortResult = 0;
                    } else if (riskA > riskB) {
                        sortResult = 1;
                    } else {
                        sortResult = -1;
                    }
                    break;
                case ServiceListingSortFilterOptions.THIRD_PARTY_SERVICE_MANAGER_USER_ID:
                    sortResult = compareUsersBySubjectForSorting(serviceA.thirdPartyManagerId, serviceB.thirdPartyManagerId, props.users);
                    break;
                case ServiceListingSortFilterOptions.DATE_CREATED:
                    sortResult = serviceA.dateCreated < serviceB.dateCreated ? 1 : -1;
                    break;
                case ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE:
                    sortResult = undefinedComparator(serviceA.assessmentDueDate, serviceB.assessmentDueDate, (assessmentDueDateA, assessmentDueDateB) => (assessmentDueDateA > assessmentDueDateB ? 1 : -1));
                    break;
                default:
                    sortResult = serviceA[currentSort].localeCompare(serviceB[currentSort]);
                    break;
            }

            return currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    const setCurrentSortAndDirection = (newSort: string, newSortDirection: SortDirection): void => {
        setCurrentSort(newSort as ServiceListingSortFilterOptions);
        setCurrentSortDirection(newSortDirection);
    };

    const switchServiceListingView = (activeServiceListingView: ServiceListingView): void => {
        setActiveServiceListingView(activeServiceListingView);
    };

    if (props.thirdParties.length === 0) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>You cannot create a third-party service until you have created a third party. To do so, select "Manage Third Parties" from the main menu on the left.</Text>
            </div>
        );
    } else if (props.services.length === 0) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>You have not created any third-party services. To do so, select "CREATE NEW SERVICE" in the top-right.</Text>
            </div>
        );
    } else if (props.services) {
        const services = filterAndSortThirdPartyServices();

        const serviceListingGridViewProps: ServiceListingGridViewProps = {
            services: services,
            selectedDeleteService: props.selectedDeleteService,
            selectedModifyService: props.selectedModifyService,
            selectedServiceAssessmentDueDateService: props.selectedServiceAssessmentDueDateService,
            users: props.users,
        };
        const serviceListingTableViewProps: ServiceListingTableViewProps = {
            serviceRows: services,
            applySorting: setCurrentSortAndDirection,
            currentSort: currentSort,
            currentSortDirection: currentSortDirection,
            selectedDeleteService: props.selectedDeleteService,
            selectedModifyService: props.selectedModifyService,
            selectedServiceAssessmentDueDateService: props.selectedServiceAssessmentDueDateService,
            users: props.users,
        };
        const serviceListingBodyToolbarProps: ServiceListingBodyToolbarProps = {
            activeServiceListingView: activeServiceListingView,
            switchListingView: switchServiceListingView,
            filterOptions: createFilterOptions(props.thirdParties),
            defaultSelectedOptions: getDefaultSelectedOptions(),
            thirdPartyFilter: setCurrentFilterSelection,
            sortCardsBy: setCurrentSortAndDirection,
            currentSort: currentSort,
            users: props.users,
        };
        const thirdPartyListingView = activeServiceListingView;

        return (
            <>
                <ServiceListingBodyToolbar {...serviceListingBodyToolbarProps} />
                {{ list: <ServiceListingTableView {...serviceListingTableViewProps} />, grid: <ServiceListingGridView {...serviceListingGridViewProps} /> }[thirdPartyListingView]}
            </>
        );
    } else {
        return <Placeholder />;
    }
};

import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { DateOptionSelectOptions, TprmDashboardCompletedRiskAssessment, TprmDashboardMonthlyRiskPortfolioSummary, TprmDashboardServiceExtended } from 'Models/Dashboards';
import { ExceptionResponse } from 'Models/Exceptions';
import { IssueResponse } from 'Models/Issues';
import { ServiceAssessmentState } from 'Models/TPRM';
import { CustomDateRange } from 'Pages/Dashboards/Components/CustomDateRangeModal/CustomDateRangeModal';

export const getDatesFromPreset = (preset: DateOptionSelectOptions) => {
    const nowUtc = moment().toDate();

    switch (preset) {
        case DateOptionSelectOptions.PREVIOUS_YEAR:
            return { start: moment().subtract(1, 'year').startOf('month').toDate(), end: nowUtc };
        case DateOptionSelectOptions.PREVIOUS_SIX_MONTHS:
            return { start: moment().subtract(6, 'months').startOf('month').toDate(), end: nowUtc };
        case DateOptionSelectOptions.PREVIOUS_QUARTER:
            return { start: moment().subtract(3, 'months').startOf('month').toDate(), end: nowUtc };
        case DateOptionSelectOptions.NEXT_QUARTER:
            return { start: nowUtc, end: moment().add(3, 'months').endOf('month').toDate() };
        case DateOptionSelectOptions.NEXT_SIX_MONTHS:
            return { start: nowUtc, end: moment().add(6, 'months').endOf('month').toDate() };
        case DateOptionSelectOptions.NEXT_YEAR:
            return { start: nowUtc, end: moment().add(1, 'year').endOf('month').toDate() };
        default:
            return { start: nowUtc, end: nowUtc };
    }
};

export const useDateFilter = (defaultOption: DateOptionSelectOptions) => {
    const [preset, setPreset] = useState<DateOptionSelectOptions>(defaultOption);
    const [startDate, setStartDate] = useState<Date>(getDatesFromPreset(defaultOption).start);
    const [endDate, setEndDate] = useState<Date>(getDatesFromPreset(defaultOption).end);

    useEffect(() => {
        if (preset !== DateOptionSelectOptions.CUSTOM) {
            const presetResult = getDatesFromPreset(preset);

            setStartDate(presetResult.start);
            setEndDate(presetResult.end);
        }
    }, [preset]);

    const setDateRange = (dateRange: CustomDateRange) => {
        if (dateRange.startDate && dateRange.endDate) {
            setPreset(DateOptionSelectOptions.CUSTOM);
            setStartDate(dateRange.startDate);
            setEndDate(dateRange.endDate);
        }
    };

    return {
        dateFilter: {
            preset,
            start: startDate,
            end: endDate,
        },
        updateDateFilter: {
            setPreset,
            setDateRange,
        },
    };
};

export const useThirdPartyServicesQuery = (api: DashboardApi) => {
    const [thirdPartyCount, setThirdPartyCount] = useState<number>();
    const [thirdPartyServices, setThirdPartyServices] = useState<TprmDashboardServiceExtended[]>();
    const [thirdPartyServicesError, setThirdPartyServicesError] = useState<string>();

    useEffect(() => {
        const fetchThirdPartyServicesData = async (): Promise<void> => {
            try {
                const thirdPartyAndServiceResponse = await api.getThirdPartiesAndServicesInfo();
                setThirdPartyCount(thirdPartyAndServiceResponse.data.third_party_count);

                const services = thirdPartyAndServiceResponse.data.services;
                const extendedServices: TprmDashboardServiceExtended[] = services.map((service) => {
                    const isOverdue = service.assessment_state !== ServiceAssessmentState.ARCHIVING && service.assessment_due_date && moment(service.assessment_due_date).isBefore();
                    const isUpcoming = service.assessment_state !== ServiceAssessmentState.ARCHIVING && service.assessment_due_date && moment(service.assessment_due_date).isAfter();

                    return {
                        ...service,
                        is_overdue: !!isOverdue,
                        is_upcoming: !!isUpcoming,
                    };
                });

                setThirdPartyServices(extendedServices);
            } catch (err) {
                setThirdPartyServicesError(err.message);
            }
        };

        fetchThirdPartyServicesData();
    }, [api]);

    const overdueAssessmentsCount = useMemo(() => {
        return thirdPartyServices?.filter((service) => service.is_overdue);
    }, [thirdPartyServices]);

    return { thirdPartyCount: thirdPartyCount, thirdPartyServices: thirdPartyServices, overdueAssessmentsCount, thirdPartyServicesError: thirdPartyServicesError };
};

export const useCompletedRiskAssessmentsQuery = (
    api: DashboardApi,
    filter: {
        preset: DateOptionSelectOptions;
        start: Date;
        end: Date;
    }
) => {
    const [completedRiskAssessments, setCompletedRiskAssessments] = useState<TprmDashboardCompletedRiskAssessment[]>();
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const [completedRiskAssessmentsError, setCompletedRiskAssessmentsError] = useState<string>();

    useEffect(() => {
        const fetchCompletedRiskAssessmentsData = async (): Promise<void> => {
            if (startDate && endDate) {
                try {
                    const completedRiskAssessmentsResponse = await api.getCompletedRiskAssessments(startDate, endDate);
                    setCompletedRiskAssessments(completedRiskAssessmentsResponse.data);
                } catch (err) {
                    setCompletedRiskAssessmentsError(err.message);
                }
            }
        };

        fetchCompletedRiskAssessmentsData();
    }, [api, startDate, endDate]);

    useEffect(() => {
        setStartDate(jsDateToIso8601(filter.start));
    }, [filter.start]);

    useEffect(() => {
        setEndDate(jsDateToIso8601(filter.end));
    }, [filter.end]);

    return { completedRiskAssessments, completedRiskAssessmentsError };
};

export const useRiskPortfolioHistoryQuery = (api: DashboardApi) => {
    const [history, setHistory] = useState<TprmDashboardMonthlyRiskPortfolioSummary[]>();
    const [historyError, setHistoryError] = useState<string>();

    const [startDate, setStartDate] = useState<string>(moment().subtract(3, 'months').startOf('month').format());
    const [endDate, setEndDate] = useState<string>(moment().format());

    useEffect(() => {
        const fetchRiskPortfolioHistory = async (): Promise<void> => {
            try {
                const historyResponse = await api.getRiskPortfolioHistory(startDate, endDate);
                setHistory(historyResponse.data);
            } catch (err) {
                setHistoryError(err.message);
            }
        };

        fetchRiskPortfolioHistory();
    }, [api, startDate, endDate]);

    return { riskPortfolioHistory: history, updateDateFilters: { setStartDate, setEndDate }, riskPortfolioHistoryError: historyError };
};

export const useIssuesQuery = (api: IssuesApi) => {
    const [issues, setIssues] = useState<IssueResponse[]>();
    const [issuesError, setIssuesError] = useState<string>();

    useEffect(() => {
        const fetchIssuesData = async (): Promise<void> => {
            try {
                const issuesResponse = await api.getAllIssues();
                setIssues(issuesResponse.data);
            } catch (err) {
                setIssuesError(err.message);
            }
        };

        fetchIssuesData();
    }, [api]);

    return { issues, issuesError };
};

export const useExceptionsQuery = (api: ExceptionsApi) => {
    const [exceptions, setExceptions] = useState<ExceptionResponse[]>();
    const [exceptionsError, setExceptionsError] = useState<string>();

    useEffect(() => {
        const fetchExceptionsData = async (): Promise<void> => {
            try {
                const exceptionsResponse = await api.getAllExceptions();
                setExceptions(exceptionsResponse.data);
            } catch (err) {
                setExceptionsError(err.message);
            }
        };

        fetchExceptionsData();
    }, [api]);

    return { exceptions, exceptionsError };
};

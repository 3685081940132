import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { UserFilter } from 'Components/Filters/UserFilter/UserFilter';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { getCreateIssueUrl } from 'Helpers/URLBuilder/URLBuilder';
import { ControlIssueResponse, IssuePriorityFilterOptionTypes, IssueResponse, IssueStatus, IssueStatusFilterOptionTypes, IssuesExceptionsModule, IssuesSortFilterOptions, ThirdPartyIssueResponse, sortIssues, titleCaseIssueStatus } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponse } from 'Models/TPRM';
import { ColorTheme, Filter, Filters } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';
import { ThirdPartyFilter } from 'Pages/TPRM/Components/Filters/ThirdPartiesFilter';

import styles from './IssuesListing.module.css';
import { IssuesMultiOptionFilter, IssuesMultiOptionFilterProps } from './IssuesMultiOptionFilter';
import { IssuesTableRow, IssuesTableRowProps } from '../IssuesTable/IssuesTableRow/IssuesTableRow';

/**
 * @param users
 * @param colorTheme    - The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
 * @param hideTitle     - If `true`, the title will not be displayed. This is useful since sometimes this component is used in a tab of the same name, making the title redundant. In other cases, Issues and Exceptions are shown together, so having a title for each is necessary to differentiate them.
 */
export interface IssuesListingBaseProps {
    users: UserResponse[];
    colorTheme?: ColorTheme;
    hideTitle?: boolean;
}

export interface IssuesListingControlsProps extends IssuesListingBaseProps {
    type: IssuesExceptionsModule.CONTROLS;
    issues: ControlIssueResponse[];
    preselectedControlIdForCreate?: string;
    displayMappedControlsModal: (mappedControls: OperationalControl[]) => void;
    thirdParties?: never; // Typed like this so that `third parties` can be used unconditionally as a `useMemo` dependency.
}

export interface IssuesListingThirdPartiesProps extends IssuesListingBaseProps {
    type: IssuesExceptionsModule.TPRM;
    issues: ThirdPartyIssueResponse[];
    thirdParties: ThirdPartyResponse[];
    preselectedThirdPartyIdForCreate?: string;
    preselectedThirdPartyIdForFilter?: string;
    disableThirdPartyFilter?: boolean;
}

export type IssuesListingProps = IssuesListingControlsProps | IssuesListingThirdPartiesProps;

/**
 * Renders a table of issues, which are either Operational Controls issues or TPRM issues.
 * The `type` prop determines which type of issues are displayed. This affects some minor things, such as whether a "Controls" or "Third Party" column is rendered.
 *
 * In addition to the table, this component renders:
 * * A header section with the text "issues" and a "Create Issue" button.
 * * Filters for owner, priority, status, and third party (if TPRM issues are being listed).
 */
export const IssuesListing = (props: IssuesListingProps) => {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>(() => {
        const defaults: Filters = {
            [IssuesSortFilterOptions.STATUS]: { key: IssuesSortFilterOptions.STATUS, value: [IssueStatus.DRAFT_OPEN, IssueStatus.OPEN] },
        };

        if (props.type === IssuesExceptionsModule.TPRM && props.preselectedThirdPartyIdForFilter) {
            defaults[IssuesSortFilterOptions.THIRD_PARTY] = { key: IssuesSortFilterOptions.THIRD_PARTY, value: [props.preselectedThirdPartyIdForFilter] };
        }

        return defaults;
    });
    const [sortBy, setSortBy] = useState<string>(IssuesSortFilterOptions.STATUS);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);
    const [sortedAndFilteredIssues, setSortedAndFilteredIssues] = useState<IssueResponse[]>([]);

    const thirdPartyIdToNameMap: Map<string, string> | undefined = useMemo(() => {
        return props.type === IssuesExceptionsModule.TPRM ? new Map(props.thirdParties.map((thirdParty) => [thirdParty.id, thirdParty.name])) : undefined;
    }, [props.type, props.thirdParties]);

    const getThirdPartyName = thirdPartyIdToNameMap ? (thirdPartyId: string) => thirdPartyIdToNameMap.get(thirdPartyId)! : undefined;

    useEffect(() => {
        let issues = [...props.issues];

        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                issues = issues.filter((issue) => {
                    return filter.value.includes(issue[filter.key as keyof IssueResponse] as string);
                });
            }
        }
        sortIssues(issues, props.users, sortBy as IssuesSortFilterOptions, sortDirection, thirdPartyIdToNameMap);
        setSortedAndFilteredIssues(issues);
    }, [props.type, props.issues, props.users, selectedFilterOptions, sortBy, sortDirection, thirdPartyIdToNameMap]);

    const applySorting = (sortBy: string, sortDirection: SortDirection): void => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
    };

    const updateFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    const issuesMultiOptionFilterProps: IssuesMultiOptionFilterProps = {
        filterOptions: [
            {
                label: 'Priority',
                options: IssuePriorityFilterOptionTypes,
            },
            {
                label: 'Status',
                options: IssueStatusFilterOptionTypes,
            },
        ],
        selectedFilterOptions: updateFilterSelection,
        defaultSelectedOptions: [
            { groupId: IssuesSortFilterOptions.STATUS, label: titleCaseIssueStatus(IssueStatus.DRAFT_OPEN), value: IssueStatus.DRAFT_OPEN },
            { groupId: IssuesSortFilterOptions.STATUS, label: titleCaseIssueStatus(IssueStatus.OPEN), value: IssueStatus.OPEN },
        ],
        colorTheme: props.colorTheme,
    };

    const headerValues: HeaderData[] = [
        { dataKey: IssuesSortFilterOptions.TITLE, label: 'TITLE' },
        { dataKey: IssuesSortFilterOptions.DUE_DATE, label: 'DUE' },
        { dataKey: IssuesSortFilterOptions.SOURCE, label: 'SOURCE' },
        { dataKey: IssuesSortFilterOptions.OWNER, label: 'OWNER' },
        { dataKey: IssuesSortFilterOptions.PRIORITY, label: 'PRIORITY' },
        // Render "Controls" or "Third Party" column based on the type of issues being displayed.
        props.type === IssuesExceptionsModule.CONTROLS ? { dataKey: IssuesSortFilterOptions.CONTROLS, label: 'CONTROLS' } : { dataKey: IssuesSortFilterOptions.THIRD_PARTY, label: 'THIRD PARTY' },
        { dataKey: IssuesSortFilterOptions.STATUS, label: 'STATUS' },
    ];

    const tableRow = (issue: IssueResponse): JSX.Element => {
        const issuesTableRowProps: IssuesTableRowProps = {
            users: props.users,
            colorTheme: props.colorTheme,
            ...(props.type === IssuesExceptionsModule.CONTROLS ? { type: props.type, displayMappedControlsModal: props.displayMappedControlsModal, issue: issue as ControlIssueResponse } : { type: props.type, getThirdPartyName: getThirdPartyName!, issue: issue as ThirdPartyIssueResponse }),
        };
        return <IssuesTableRow key={issue.id} {...issuesTableRowProps} />;
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: applySorting,
        currentSort: sortBy,
        currentSortDirection: sortDirection,
        variant: (props.colorTheme ?? 'light') === 'light' ? 'standard' : 'dashboard',
    };

    const createButtonDestination: string = (() => {
        if (props.type === IssuesExceptionsModule.CONTROLS) {
            return getCreateIssueUrl(IssuesExceptionsModule.CONTROLS, props.preselectedControlIdForCreate);
        } else {
            return getCreateIssueUrl(IssuesExceptionsModule.TPRM, props.preselectedThirdPartyIdForCreate);
        }
    })();

    return (
        <>
            {!props.hideTitle ? (
                <div className={styles.titleAndButtonContainer}>
                    <Text variant="Header2" color={props.colorTheme === 'dark' ? 'white' : 'blue'}>
                        Issues
                    </Text>
                    <Link variant="primaryButton" to={createButtonDestination} fontAwesomeImage={faPlus}>
                        CREATE ISSUE
                    </Link>
                </div>
            ) : (
                <div className={styles.alignRight}>
                    <Link variant="primaryButton" to={createButtonDestination} fontAwesomeImage={faPlus}>
                        CREATE ISSUE
                    </Link>
                </div>
            )}
            <div className={styles.alignRight}>
                <div className={styles.filtersContainer}>
                    {props.type === IssuesExceptionsModule.TPRM && (
                        <div className={styles.filter}>
                            <ThirdPartyFilter colorTheme={props.colorTheme} disabled={props.disableThirdPartyFilter} thirdParties={props.thirdParties} selectedFilterOptions={updateFilterSelection} selectedThirdPartyIds={selectedFilterOptions[IssuesSortFilterOptions.THIRD_PARTY] && selectedFilterOptions[IssuesSortFilterOptions.THIRD_PARTY].value ? (selectedFilterOptions[IssuesSortFilterOptions.THIRD_PARTY].value as string[]) : []} />
                        </div>
                    )}
                    <div className={styles.filter}>
                        <UserFilter colorTheme={props.colorTheme} filterLabel="Filter by Owner" onUsersSelected={updateFilterSelection} users={props.users} userFilterId="owner_subject" formFieldId="issuesOwnerFilter" />
                    </div>
                    <div className={styles.filter}>
                        <IssuesMultiOptionFilter {...issuesMultiOptionFilterProps} />
                    </div>
                </div>
            </div>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{sortedAndFilteredIssues.map(tableRow)}</TableBody>
            </Table>
        </>
    );
};

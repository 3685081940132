import { ThirdPartyResponseWithServices } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import { ThirdPartyCard } from './ThirdPartyCard/ThirdPartyCard';
import styles from './ThirdPartyListingGridView.module.css';

export interface ThirdPartyListingGridViewProps {
    thirdParties: ThirdPartyResponseWithServices[];
    selectedDeleteThirdParty: (thirdPartyId: string) => void;
    selectedModifyThirdParty: (thirdPartyId: string) => void;
    selectedCreateServiceForThirdParty: (thirdParty: ThirdPartyResponseWithServices) => void;
    selectedViewFolders: (thirdPartyId: string) => void;
    users: UserResponse[];
}

export const ThirdPartyListingGridView = (props: ThirdPartyListingGridViewProps) => {
    return (
        <div className={styles.gridView}>
            {props.thirdParties.map((thirdParty) => (
                <ThirdPartyCard key={thirdParty.id} thirdParty={thirdParty} selectedCreateServiceForThirdParty={props.selectedCreateServiceForThirdParty} selectedModifyThirdParty={props.selectedModifyThirdParty} selectedDeleteThirdParty={props.selectedDeleteThirdParty} selectedViewFolders={props.selectedViewFolders} users={props.users} />
            ))}
        </div>
    );
};

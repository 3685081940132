import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button, Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { CONFIGURATION, FOLDERS, THIRD_PARTIES, TPRM, VERSIONS } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { UploadedFile } from 'Models/Files';
import { FolderAllVersions, ThirdPartyResponse } from 'Models/TPRM';

import { DeleteFolderModal, DeleteFolderModalProps } from './DeleteFolderModal/DeleteFolderModal';
import styles from './FolderDetails.module.css';

export interface FolderDetailsProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
}

interface UrlParams {
    third_party_id: string;
    folder_id: string;
}

export const FolderDetails = (props: FolderDetailsProps) => {
    const { third_party_id, folder_id } = useParams<keyof UrlParams>() as UrlParams;
    const [errorMessage, setErrorMessage] = useState<string>();
    const [folderDeleted, setFolderDeleted] = useState<boolean>();
    const [showModal, setShowModal] = useState<boolean>();
    const [thirdPartyDetails, setThirdPartyDetails] = useState<ThirdPartyResponse>();
    const [versions, setVersions] = useState<FolderAllVersions>();
    const { users } = useUsers();

    useEffect(() => {
        const getAllVersions = async (): Promise<void> => {
            try {
                const response = await props.tprmApi.getAllFolderVersions(third_party_id, folder_id);
                setVersions(response.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getAllVersions();
    }, [props.tprmApi, third_party_id, folder_id]);

    useEffect(() => {
        const getThirdPartyDetails = async (): Promise<void> => {
            try {
                const detailedThirdPartyResponse = await props.tprmApi.getThirdPartyDetails(third_party_id);
                setThirdPartyDetails(detailedThirdPartyResponse.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };
        getThirdPartyDetails();
    }, [props.tprmApi, third_party_id]);

    const getModalProps = (): DeleteFolderModalProps => {
        return {
            tprmApi: props.tprmApi,
            folder: versions!.current_version,
            hideModal: () => setShowModal(false),
            thirdPartyId: third_party_id,
            onFolderDeleted: () => setFolderDeleted(true),
        };
    };

    const getFileName = (file: UploadedFile, isTable: boolean): JSX.Element => {
        return (
            <div key={file.file_id} className={isTable ? styles.uploadedFileInTable : styles.uploadedFileInCurrentSection}>
                <UploadedFileAndState file={file} documentApi={props.documentApi} />;
            </div>
        );
    };

    if (thirdPartyDetails && versions) {
        return (
            <>
                {showModal && <DeleteFolderModal {...getModalProps()} />}
                <Page
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}`}>{thirdPartyDetails.name}</BreadcrumbLink>
                            <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}`}>Third-Party Documents</BreadcrumbLink>
                            <BreadcrumbText>{versions.current_version.name}</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle={versions.current_version.name}
                    headerButtons={
                        <>
                            <Button variant="danger" onClick={() => setShowModal(true)} fontAwesomeImage={faTrash} disabled={folderDeleted}>
                                delete
                            </Button>
                            <Link variant="primaryButton" to={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folder_id}/${VERSIONS}`} fontAwesomeImage={faAdd} disabled={folderDeleted}>
                                add new version
                            </Link>
                        </>
                    }
                    body={[
                        {
                            title: 'Current Folder Version',
                            buttons: (
                                <Link variant="secondaryButton" to={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${folder_id}/${CONFIGURATION}`} fontAwesomeImage={faEdit} disabled={folderDeleted}>
                                    manage
                                </Link>
                            ),
                            content: (
                                <>
                                    <div className={styles.currentFolder}>
                                        <div className={styles.column}>
                                            <Text variant="Header4">documents</Text>
                                            {versions.current_version.files?.map((file) => getFileName(file, false))}
                                        </div>
                                        <div className={styles.column}>
                                            <Text variant="Header4" color="darkBlue">
                                                folder type
                                            </Text>
                                            <Text color="blue">{versions.current_version.type}</Text>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.column}>
                                                <Text variant="Header4" color="darkBlue">
                                                    last updated by
                                                </Text>
                                                <Text color="blue">{getUserNameFromSubject(versions.current_version.last_updated_by, users)}</Text>
                                            </div>
                                            <div className={styles.column}>
                                                <Text variant="Header4" color="darkBlue">
                                                    last updated date
                                                </Text>
                                                <Text color="blue">{iso8601ToUsDateShort(versions.current_version.last_updated)}</Text>
                                            </div>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.column}>
                                                <Text variant="Header4" color="darkBlue">
                                                    effective date
                                                </Text>
                                                {versions.current_version.effective_date ? <Text color="blue">{iso8601ToUsDateShort(versions.current_version.effective_date)}</Text> : <Text color="blue">N/A</Text>}
                                            </div>
                                            <div className={styles.column}>
                                                <Text variant="Header4" color="darkBlue">
                                                    expiration date
                                                </Text>
                                                {versions.current_version.expiration_date ? <Text color="blue">{iso8601ToUsDateShort(versions.current_version.expiration_date)}</Text> : <Text color="blue">N/A</Text>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.accordionColumnComments}>
                                        <Text variant="Header4" color="darkBlue">
                                            comments
                                        </Text>
                                        {versions.current_version.comments ? <Text color="blue">{versions.current_version.comments}</Text> : <Text color="blue">N/A</Text>}
                                    </div>
                                </>
                            ),
                        },
                        {
                            title: 'Past Folder Versions',
                            content: (
                                <>
                                    <div className={styles.accordionHeaderContainer}>
                                        <div className={styles.accordionHeaderEffective}>
                                            <Text variant="Text3" noStyles>
                                                EFFECTIVE DATE
                                            </Text>
                                        </div>
                                        <div className={styles.accordionHeaderExpiration}>
                                            <Text variant="Text3" noStyles>
                                                EXPIRATION DATE
                                            </Text>
                                        </div>
                                        <div className={styles.accordionHeaderFiles}>
                                            <Text variant="Text3" noStyles>
                                                DOCUMENTS
                                            </Text>
                                        </div>
                                        <div className={styles.accordionHeaderLastUpdatedBy}>
                                            <Text variant="Text3" noStyles>
                                                LAST UPDATED BY
                                            </Text>
                                        </div>
                                        <div className={styles.accordionHeaderUpdated}>
                                            <Text variant="Text3" noStyles>
                                                LAST UPDATED DATE
                                            </Text>
                                        </div>
                                    </div>

                                    {versions.past_versions.length > 0 &&
                                        versions.past_versions.map((pastVersion, index) => {
                                            return (
                                                <Accordion key={index}>
                                                    <div className={styles.accordionContainer}>
                                                        <div className={styles.accordionColumnEffective}>
                                                            <AccordionToggle eventKey={pastVersion.id} ariaLabelSuffix={'Effective'} />
                                                            {pastVersion.effective_date ? (
                                                                <Text variant="Text3" noStyles>
                                                                    {iso8601ToUsDateShort(pastVersion.effective_date)}
                                                                </Text>
                                                            ) : (
                                                                <Text>N/A</Text>
                                                            )}
                                                        </div>
                                                        <div className={styles.accordionColumnExpiration}>
                                                            {pastVersion.expiration_date ? (
                                                                <Text variant="Text3" noStyles>
                                                                    {iso8601ToUsDateShort(pastVersion.expiration_date)}
                                                                </Text>
                                                            ) : (
                                                                <Text>N/A</Text>
                                                            )}
                                                        </div>
                                                        <div className={styles.accordionColumnFiles}>{pastVersion.files?.length === 0 ? <Text>N/A</Text> : pastVersion.files?.map((file) => getFileName(file, true))}</div>
                                                        <div className={styles.accordionColumnLastUpdatedBy}>
                                                            <Text variant="Text3" noStyles>
                                                                {getUserNameFromSubject(pastVersion.last_updated_by, users)}
                                                            </Text>
                                                        </div>
                                                        <div className={styles.accordionColumnLastUpdatedDate}>
                                                            <Text variant="Text3" noStyles>
                                                                {iso8601ToUsDateShort(pastVersion.last_updated)}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    <AccordionCollapse eventKey={pastVersion.id}>
                                                        <div className={styles.accordionCollapseComments}>
                                                            <Text variant="Header4">Comments</Text>
                                                            <Text variant="Text3" noStyles>
                                                                {pastVersion.comments}
                                                            </Text>
                                                        </div>
                                                    </AccordionCollapse>
                                                </Accordion>
                                            );
                                        })}
                                </>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else if (errorMessage) {
        return <Text>{errorMessage}</Text>;
    } else {
        return <Placeholder />;
    }
};

/*
    NavHeader.tsx -- Component for the header at the top of the navigation menu.
*/

import { useEffect, useState } from 'react';

import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { Text } from 'Components/Text/Text';

import styles from './NavHeader.module.css';

export interface NavHeaderProps {
    clientDetailsApi: ClientDetailsApi;
}

export const NavHeader: React.FC<NavHeaderProps> = ({ clientDetailsApi }) => {
    const [clientName, setClientName] = useState<string | null>(null);

    useEffect(() => {
        const getClientDetails = async (): Promise<void> => {
            try {
                const clientDetailsResponse = await clientDetailsApi.getClientDetails();
                const clientDetails = clientDetailsResponse.data;
                const clientName = clientDetails.client_name;
                setClientName(clientName);
            } catch (err) {
                console.error('Error: ', err);
            }
        };
        getClientDetails();
    }, [clientDetailsApi]);

    return (
        <div className={styles.navHeader}>
            <Text color="white" variant="Text2">
                {clientName}
            </Text>
        </div>
    );
};

import { Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';

import { AdornedDonutChart } from 'Components/BaseCharts/AdornedDonutChart';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { Text } from 'Components/Text/Text';
import { TprmDashboardServiceExtended } from 'Models/Dashboards';
import { Effectiveness, effectivenessLabelAsNumber } from 'Models/OperationalControls';
import { RiskRating, riskRatingLabelAsNumber } from 'Models/TPRM';

import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';
import { buildEffectivenessChartData, buildRiskRatingsChartData } from '../TPRMDashboard.helpers';
import styles from '../TPRMDashboard.module.css';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
}

export interface TPRMDonutChartsProps {
    thirdPartyServices?: TprmDashboardServiceExtended[];
    thirdPartyServicesError?: string;
}

export const TPRMDonutCharts = (props: TPRMDonutChartsProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [servicesModalSubtitle, setServicesModalSubtitle] = useState<string>();
    const [selectedServices, setSelectedServices] = useState<SelectedServiceDetails[]>();

    const totalServices = props.thirdPartyServices?.length;

    const inherentRiskDonutChart = useMemo(() => {
        if (props.thirdPartyServices) {
            const scores: number[] = props.thirdPartyServices.map((service) => service.inherent_risk_score);
            return buildRiskRatingsChartData(scores);
        }
    }, [props.thirdPartyServices]);

    const controlEffectivenessDonutChart = useMemo(() => {
        if (props.thirdPartyServices) {
            const scores = props.thirdPartyServices.map((service) => service.assessment_control_effectiveness);
            return buildEffectivenessChartData(scores);
        }
    }, [props.thirdPartyServices]);

    const residualRiskDonutChart = useMemo(() => {
        if (props.thirdPartyServices) {
            const scores = props.thirdPartyServices.map((service) => service.assessment_residual_risk_score);
            return buildRiskRatingsChartData(scores);
        }
    }, [props.thirdPartyServices]);

    const handleInherentRiskChartClick = (targetRating: string): void => {
        const targetRatingScore = riskRatingLabelAsNumber(targetRating);
        setServicesModalSubtitle(targetRating);
        setModalState(Modals.DISPLAY_SERVICES);
        setSelectedServices(
            props
                // Special logic is not _currently_ needed here, as the inherent risk score is always defined. However, the same special logic as below is included here to prevent future bugs if inherent risk scores are ever undefined in the future.
                .thirdPartyServices!.filter((service) => service.inherent_risk_score === targetRatingScore || (targetRatingScore === RiskRating.INACTIVE && service.inherent_risk_score === undefined))
                .map((service) => ({
                    serviceId: service.id,
                    serviceName: service.name,
                    thirdPartyId: service.third_party_id,
                    thirdPartyName: service.third_party_name,
                }))
        );
    };

    const handleEffectivenessChartClick = (effectiveness: string): void => {
        const effectivenessScore = effectivenessLabelAsNumber(effectiveness);
        setServicesModalSubtitle(effectiveness);
        setModalState(Modals.DISPLAY_SERVICES);
        setSelectedServices(
            props
                // Special logic needed here because INACTIVE and undefined should be considered equivalent.
                .thirdPartyServices!.filter((service) => service.assessment_control_effectiveness === effectivenessScore || (effectivenessScore === Effectiveness.INACTIVE && service.assessment_control_effectiveness === undefined))
                .map((service) => ({
                    serviceId: service.id,
                    serviceName: service.name,
                    thirdPartyId: service.third_party_id,
                    thirdPartyName: service.third_party_name,
                }))
        );
    };

    const handleResidualRiskChartClick = (targetRating: string): void => {
        const targetRatingScore = riskRatingLabelAsNumber(targetRating);
        setServicesModalSubtitle(targetRating);
        setModalState(Modals.DISPLAY_SERVICES);
        setSelectedServices(
            props
                // Special logic needed here because INACTIVE and undefined should be considered equivalent.
                .thirdPartyServices!.filter((service) => service.assessment_residual_risk_score === targetRatingScore || (targetRatingScore === RiskRating.INACTIVE && service.assessment_residual_risk_score === undefined))
                .map((service) => ({
                    serviceId: service.id,
                    serviceName: service.name,
                    thirdPartyId: service.third_party_id,
                    thirdPartyName: service.third_party_name,
                }))
        );
    };

    const handleServicesModalClose = () => {
        setModalState(Modals.NONE);
        setServicesModalSubtitle(undefined);
        setSelectedServices(undefined);
    };

    if (props.thirdPartyServicesError) {
        return (
            <>
                <div className={styles.section3}>
                    <PageCell variant="transparentBlue">
                        <Text variant="Header2" color="white">
                            Inherent Risk
                        </Text>
                        <Text color="white">{props.thirdPartyServicesError}</Text>
                    </PageCell>
                </div>
                <div className={styles.section3}>
                    <PageCell variant="transparentBlue">
                        <div>
                            <Text variant="Header2" color="white">
                                Overall Control Effectiveness
                            </Text>
                            <Text color="white">{props.thirdPartyServicesError}</Text>
                        </div>
                    </PageCell>
                </div>
                <div className={styles.section3}>
                    <PageCell variant="transparentBlue">
                        <div>
                            <Text variant="Header2" color="white">
                                Residual Risk
                            </Text>
                            <Text color="white">{props.thirdPartyServicesError}</Text>
                        </div>
                    </PageCell>
                </div>
            </>
        );
    }

    return (
        <>
            {modalState === Modals.DISPLAY_SERVICES && servicesModalSubtitle && selectedServices && <ServicesDisplayModal hideModal={handleServicesModalClose} subtitle={servicesModalSubtitle} selectedServices={selectedServices} />}
            <div className={styles.section3}>
                <PageCell variant="transparentBlue">
                    <Text variant="Header2" color="white">
                        Inherent Risk
                    </Text>
                    {inherentRiskDonutChart ? (
                        <AdornedDonutChart name="Inherent Risk" data={inherentRiskDonutChart.data} adornment={{ body: { value: inherentRiskDonutChart.average, fontSize: 60 }, subtitle: { value: inherentRiskDonutChart.averageLabel, fontSize: 20 } }} onChartClick={handleInherentRiskChartClick} />
                    ) : (
                        <div className={styles.services}>
                            <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                        </div>
                    )}
                    <div className={styles.services}>
                        {inherentRiskDonutChart ? (
                            <Text variant="Header4" color="lightGray">
                                {inherentRiskDonutChart.numberOfScoredServices} of {totalServices} Services Scored
                            </Text>
                        ) : (
                            <Skeleton>
                                <Text variant="Header4" color="lightGray">
                                    --- of --- Services
                                </Text>
                            </Skeleton>
                        )}
                    </div>
                </PageCell>
            </div>
            <div className={styles.section3}>
                <PageCell variant="transparentBlue">
                    <div>
                        <Text variant="Header2" color="white">
                            Overall Control Effectiveness
                        </Text>
                        {controlEffectivenessDonutChart ? (
                            <AdornedDonutChart name="Control Effectiveness" data={controlEffectivenessDonutChart.data} adornment={{ body: { value: controlEffectivenessDonutChart.average, fontSize: 60 }, subtitle: { value: controlEffectivenessDonutChart.averageLabel, fontSize: 20 } }} onChartClick={handleEffectivenessChartClick} />
                        ) : (
                            <div className={styles.services}>
                                <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                            </div>
                        )}
                        <div className={styles.services}>
                            {controlEffectivenessDonutChart ? (
                                <Text variant="Header4" color="lightGray">
                                    {controlEffectivenessDonutChart.numberOfScoredServices} of {totalServices} Services Assessed
                                </Text>
                            ) : (
                                <Skeleton>
                                    <Text variant="Header4" color="lightGray">
                                        --- of --- Services
                                    </Text>
                                </Skeleton>
                            )}
                        </div>
                    </div>
                </PageCell>
            </div>
            <div className={styles.section3}>
                <PageCell variant="transparentBlue">
                    <div>
                        <Text variant="Header2" color="white">
                            Residual Risk
                        </Text>
                        {residualRiskDonutChart ? (
                            <AdornedDonutChart name="Residual Risk" data={residualRiskDonutChart.data} adornment={{ body: { value: residualRiskDonutChart.average, fontSize: 60 }, subtitle: { value: residualRiskDonutChart.averageLabel, fontSize: 20 } }} onChartClick={handleResidualRiskChartClick} />
                        ) : (
                            <div className={styles.services}>
                                <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                            </div>
                        )}
                        <div className={styles.services}>
                            {residualRiskDonutChart ? (
                                <Text variant="Header4" color="lightGray">
                                    {residualRiskDonutChart.numberOfScoredServices} of {totalServices} Services Scored
                                </Text>
                            ) : (
                                <Skeleton>
                                    <Text variant="Header4" color="lightGray">
                                        --- of --- Services
                                    </Text>
                                </Skeleton>
                            )}
                        </div>
                    </div>
                </PageCell>
            </div>
        </>
    );
};

import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { useUsers } from 'Components/Context/UsersContext';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { ControlTargetEffectivenessTabProps, TargetEffectivenessTab, TargetEffectivenessTabType } from 'Components/OperationalControls/TargetEffectivenessTab/TargetEffectivenessTab';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { getFrameworkGroupControlParts, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { IntegrationResponse } from 'Models/ExternalIntegrations';
import { LimitResponse } from 'Models/Limits';
import { OperationalControl } from 'Models/OperationalControls';
import { ControlText } from 'Pages/ControlDetails/ControlText/ControlText';

import { ControlAssessmentTab, ControlAssessmentTabProps } from './ControlAssessmentTab/ControlAssessmentTab';
import { ControlConfigurationTab, ControlConfigurationTabProps } from './ControlConfigurationTab/ControlConfigurationTab';
import { ControlLimitsTab, ControlLimitsTabProps } from './ControlLimitsTab/ControlLimitsTab';

export interface UrlParams {
    controlFramework: string;
    controlGroupId: string;
    controlId: string;
}
export interface ControlSettingsProps {
    controlsApi: ControlsApi;
    alertsLimitsApi: AlertsLimitsApi;
}

export interface ControlSettingsRouteState {
    controlDetailsTab?: string;
}

export interface ControlDeleted {
    controlIsDeleted?: boolean;
}

export const ControlSettings = (props: ControlSettingsProps) => {
    const [isControlBreadcrumbEnabled, setIsControlBreadcrumbEnabled] = useState(true);
    const [detailedControlResponse, setDetailedControlResponse] = useState<OperationalControl>();
    const [limitResponseList, setLimitResponseList] = useState<LimitResponse[]>();
    const [controlMetricMapping, setControlMetricMapping] = useState<IntegrationResponse[]>();
    const [controlIsDeleted, setControlIsDeleted] = useState(false);
    const { users } = useUsers();
    const { controlFramework, controlGroupId, controlId } = useParams<keyof UrlParams>() as UrlParams;
    const location = useLocation();

    const getControlDetails = useCallback(async (): Promise<void> => {
        try {
            const controlDetailsResponse = await props.controlsApi.getControlDetails(controlFramework, controlGroupId, controlId);
            const controlDetails = controlDetailsResponse.data;
            setDetailedControlResponse(controlDetails);
        } catch (error) {
            handleRequestError(error);
        }
    }, [controlFramework, controlGroupId, controlId, props.controlsApi]);

    const getControlLimits = useCallback(async (): Promise<void> => {
        try {
            const subject_id = `${controlFramework}#${controlGroupId}#${controlId}`;
            const controlLimitsResponse = await props.alertsLimitsApi.getControlLimits(subject_id);
            const controlLimits = controlLimitsResponse.data;

            setLimitResponseList(controlLimits);
        } catch (error) {
            handleRequestError(error);
        }
    }, [controlFramework, controlGroupId, controlId, props.alertsLimitsApi]);

    useEffect(() => {
        getControlDetails();
        getControlLimits();
    }, [getControlDetails, getControlLimits]);

    useEffect(() => {
        const getControlMappingMetric = async (): Promise<void> => {
            try {
                const formattedControlId = `${controlFramework}#${controlGroupId}#${controlId}`;
                const controlMetricMappingResponse = await props.alertsLimitsApi.getControlMappingMetric(formattedControlId);
                const controlMetricMapping = controlMetricMappingResponse.data;
                setControlMetricMapping(controlMetricMapping);
            } catch (error) {
                handleRequestError(error);
            }
        };

        if (limitResponseList) {
            getControlMappingMetric();
        }
    }, [controlFramework, controlGroupId, controlId, limitResponseList, props.alertsLimitsApi]);

    const onControlDeleted = (): void => {
        setIsControlBreadcrumbEnabled(false);
        setControlIsDeleted(true);
    };

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    const getUrlHash = (): string => {
        if (location.state && location.state.controlDetailsTab) {
            return `#${location.state.controlDetailsTab}`;
        } else {
            return '';
        }
    };

    if (users && detailedControlResponse && limitResponseList && controlMetricMapping) {
        const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(detailedControlResponse);

        const controlAssessmentTabProps: ControlAssessmentTabProps & ControlDeleted = {
            users: users,
            controlsApi: props.controlsApi,
            detailedControlResponse: detailedControlResponse,
            controlIsDeleted: controlIsDeleted,
        };

        const controlLimitsProps: ControlLimitsTabProps & ControlDeleted = {
            alertsLimitsApi: props.alertsLimitsApi,
            getControlLimits: getControlLimits,
            limitResponseList: limitResponseList,
            detailedControlResponse: detailedControlResponse,
            controlMetricMapping: controlMetricMapping,
            controlIsDeleted: controlIsDeleted,
        };

        const controlConfigurationTabProps: ControlConfigurationTabProps & ControlDeleted = {
            onControlDeleted: onControlDeleted,
            onControlSaved: getControlDetails,
            controlsApi: props.controlsApi,
            detailedControlResponse: detailedControlResponse,
            controlIsDeleted: controlIsDeleted,
        };

        const controlEffectivenessTargetTabProps: ControlTargetEffectivenessTabProps = {
            _type: TargetEffectivenessTabType.CONTROL,
            controlsApi: props.controlsApi,
            detailedControlResponse: detailedControlResponse,
            targetEffectivenessUpdated: getControlDetails,
            linkOnSuccess: getFrameworkGroupControlURL(detailedControlResponse.identifier),
            isDeleted: controlIsDeleted,
        };

        const defaultActiveTab = detailedControlResponse.metadata.is_custom ? 'configuration' : 'assessment';

        return (
            <Page
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                        <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{detailedControlResponse.metadata.control_group_name}</BreadcrumbLink>
                        {isControlBreadcrumbEnabled ? <BreadcrumbLink link={`${getFrameworkGroupControlURL(detailedControlResponse.identifier)}${getUrlHash()}`}>{detailedControlResponse.metadata.is_custom ? detailedControlResponse.metadata.control_name : controlId}</BreadcrumbLink> : <BreadcrumbText>{detailedControlResponse.metadata.is_custom ? detailedControlResponse.metadata.control_name : controlId}</BreadcrumbText>}
                        <BreadcrumbText>Settings</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={`${getHumanReadableControlIdFromControl(detailedControlResponse)} Settings`}
                headerDescription={<ControlText color="darkBlue" controlText={detailedControlResponse.metadata.control_text} textVariant="Header3" />}
                body={[
                    {
                        content: (
                            <PrimaryTabs defaultActiveTab={defaultActiveTab} removePadding>
                                {detailedControlResponse.metadata.is_custom ? (
                                    <Tab eventKey="configuration" title="Configuration">
                                        <ControlConfigurationTab {...controlConfigurationTabProps} />
                                    </Tab>
                                ) : (
                                    <></>
                                )}
                                <Tab eventKey="assessment" title="Assessment">
                                    <ControlAssessmentTab {...controlAssessmentTabProps} />
                                </Tab>
                                <Tab eventKey="limits" title="Limits">
                                    <ControlLimitsTab {...controlLimitsProps} />
                                </Tab>
                                <Tab eventKey="target" title="Target">
                                    <TargetEffectivenessTab {...controlEffectivenessTargetTabProps} />
                                </Tab>
                            </PrimaryTabs>
                        ),
                    },
                ]}
            />
        );
    }

    return <Placeholder />;
};

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import { ActionsApi } from 'Api/Actions/ActionsApi';
import { ControlsApi } from 'Api/Controls/ControlsApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { FormFieldMultiOptionSelect } from 'Components/FormField/FormFieldMultiOptionSelect/FormFieldMultiOptionSelect';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { MultipleActionMapping } from 'Components/MultipleActionMapping/MultipleActionMapping';
import { MultipleControlMapping } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { RISKS, RISK_REGISTER } from 'Config/Paths';
import { controlTextToString, getFrameworkGroupControlParts, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { isUserWithRiskRole } from 'Helpers/UserUtils';
import { useControlMappingItems } from 'Hooks/ControlMapping';
import { useSortedCategorizedTagsOptions } from 'Hooks/Tags';
import { Action } from 'Models/Actions';
import { ValidationError } from 'Models/ErrorTypes';
import { OperationalControl } from 'Models/OperationalControls';
import { RiskCategoryResponse, RiskResponse, RiskStrategy, SaveRiskRequest, WARNING_MESSAGE_INCOMPLETE_CONTROL_DISTRIBUTION } from 'Models/RiskRegister';
import { GroupOptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import { ControlDistribution } from './ControlDistribution/ControlDistribution';
import styles from './ManageRisk.module.css';

enum SubmitState {
    None,
    Saving,
    Saved,
    Deleted,
}

enum Modal {
    Delete,
    None,
}

interface UrlParams {
    risk_id: string;
}

export enum RiskPageType {
    CREATE_NEW,
    MANAGE,
}

export interface ManageRiskProps {
    actionsApi: ActionsApi;
    riskRegisterApi: RiskRegisterApi;
    controlsApi: ControlsApi;
    tagsApi: TagsApi;
    pageType: RiskPageType;
}

export interface SelectedControl {
    identifier: string;
    weight: number;
    name: string;
    description: string;
}

const selectedControlFromOperationalControl = (control: OperationalControl, weight: number): SelectedControl => {
    return {
        identifier: control.identifier,
        weight,
        name: getHumanReadableControlIdFromControl(control),
        description: controlTextToString(control.metadata.control_text),
    };
};

export const ManageRisk = (props: ManageRiskProps): JSX.Element => {
    const { users } = useUsers();
    const { risk_id } = useParams<keyof UrlParams>();

    const [submitState, setSubmitState] = useState(SubmitState.None);
    const [displayedModal, setDisplayedModal] = useState(Modal.None);

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [reference, setReference] = useState<string>();
    const [riskCategory, setRiskCategory] = useState<string>();
    const [riskStrategy, setRiskStrategy] = useState<string>();
    const [tags, setTags] = useState<string[]>([]);
    const [owner, setOwner] = useState<UserResponse>();
    const [treatmentPlan, setTreatmentPlan] = useState<string>();
    const [toastErrorMessage, setToastErrorMessage] = useState<string>();
    const [toastSuccess, setToastSuccess] = useState<{ text: string; link: string; linkText: string }>();
    const [initialRisk, setInitialRisk] = useState<RiskResponse>();
    const [riskCategoryOptions, setRiskCategoryOptions] = useState<RiskCategoryResponse[]>();
    const [riskStrategyOptions, setRiskStrategyOptions] = useState<RiskStrategy[]>();

    const [pageErrorMessage, setPageErrorMessage] = useState<string>();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const controlIdQueryParam = query.get('controlId');

    const tagOptionsState = useSortedCategorizedTagsOptions(props.tagsApi);
    const [actions, setActions] = useState<Action[]>();
    const [mappedActionIds, setMappedActionIds] = useState<string[]>();
    const [controlMappingItems, controlMappingItemsError, controlNavigatedFrom] = useControlMappingItems(props.controlsApi, controlIdQueryParam ? controlIdQueryParam : undefined);
    const [selectedControls, setSelectedControls] = useState<SelectedControl[]>();

    const MITIGATING_CONTROLS_ACCORDION = 'mitigatingControls';
    const TREATMENT_PLAN_ACCORDION = 'treatmentPlan';

    // Maintain a cache of controls indexed by identifier, so that names of controls can be retrieved quickly for children that need them.
    const controlsByIdentifier: Map<string, OperationalControl> | undefined = useMemo(() => {
        if (controlMappingItems === undefined) {
            return undefined;
        }

        return new Map(controlMappingItems.map((item) => [item.identifier, item]));
    }, [controlMappingItems]);

    // Sets the initial `selectedControls`. There are three cases for the initial value:
    // 1. The user navigated to this page from a Control Details page. A new risk is being created, and the control navigated from is the single selected control.
    // 2. The user is managing an existing Risk. The selected controls are the controls (if any) that are already mapped to the Risk.
    // 3. The user is creating a new Risk. No controls are selected.
    useEffect(() => {
        if (controlsByIdentifier === undefined) {
            return;
        }

        if (controlNavigatedFrom) {
            setSelectedControls([selectedControlFromOperationalControl(controlNavigatedFrom, 100)]);
        } else if (initialRisk) {
            const newSelectedControls: SelectedControl[] = [];
            let someControlNotFound = false;
            initialRisk.controls.forEach((controlMapping) => {
                const controlIdentifier = controlMapping.control.identifier;
                const controlMappingItem = controlsByIdentifier.get(controlIdentifier);
                if (controlMappingItem === undefined) {
                    someControlNotFound = true;
                    return;
                } else {
                    newSelectedControls.push(selectedControlFromOperationalControl(controlMappingItem, controlMapping.weight));
                }
            });

            if (someControlNotFound) {
                setPageErrorMessage(GENERIC_ERROR_MESSAGE);
            } else {
                setSelectedControls(newSelectedControls);
            }
        } else {
            setSelectedControls([]);
        }
    }, [controlsByIdentifier, controlNavigatedFrom, initialRisk]);

    useEffect(() => {
        const getRiskCategories = async (): Promise<void> => {
            try {
                const riskCategoryResponse = await props.riskRegisterApi.getRiskCategories();
                setRiskCategoryOptions(riskCategoryResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };
        getRiskCategories();
    }, [props.riskRegisterApi]);

    useEffect(() => {
        const getRiskStrategies = async (): Promise<void> => {
            try {
                const riskCategoryResponse = await props.riskRegisterApi.getRiskStrategies();
                setRiskStrategyOptions(riskCategoryResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        getRiskStrategies();
    }, [props.riskRegisterApi]);

    useEffect(() => {
        const getActions = async (): Promise<void> => {
            try {
                const response = await props.actionsApi.getAllActions();
                setActions(response.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };
        getActions();
    }, [props.actionsApi]);

    useEffect(() => {
        const getInitialVersion = async () => {
            if (risk_id !== undefined) {
                try {
                    const riskDetailResponse = await props.riskRegisterApi.getRiskDetails(risk_id);
                    setInitialRisk(riskDetailResponse.data);

                    setTitle(riskDetailResponse.data.title);
                    setDescription(riskDetailResponse.data.description);
                    setReference(riskDetailResponse.data.reference);
                    setRiskCategory(riskDetailResponse.data.category.id);
                    setRiskStrategy(riskDetailResponse.data.strategy?.id);
                    setTags(riskDetailResponse.data.tags);
                    setOwner(users.find((user) => user.cognito_subject === riskDetailResponse.data.owner));
                    setTreatmentPlan(riskDetailResponse.data.treatment_plan_description);
                } catch (error) {
                    setPageErrorMessage(error.message);
                }
            }
        };
        getInitialVersion();
    }, [props.riskRegisterApi, risk_id, users]);

    useEffect(() => {
        if (props.pageType === RiskPageType.CREATE_NEW) {
            setMappedActionIds([]);
        } else if (props.pageType === RiskPageType.MANAGE && initialRisk) {
            setMappedActionIds(initialRisk.associated_actions.map((action) => action.id));
        }
    }, [props.pageType, initialRisk]);

    const currentlyMappedActionIds = (() => {
        if (props.pageType === RiskPageType.CREATE_NEW) {
            return [];
        } else if (props.pageType === RiskPageType.MANAGE && initialRisk) {
            return initialRisk.associated_actions.map((action) => action.id);
        } else {
            return undefined;
        }
    })();

    const createBreadcrumb = (): JSX.Element => {
        if (controlNavigatedFrom) {
            const { controlFramework, controlGroupId, controlId } = getFrameworkGroupControlParts(controlNavigatedFrom);
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={getFrameworkGroupControlURL(controlFramework)}>{controlFramework}</BreadcrumbLink>
                    <BreadcrumbLink link={getFrameworkGroupControlURL(`${controlFramework}#${controlGroupId}`)}>{controlNavigatedFrom.metadata.control_group_name}</BreadcrumbLink>
                    <BreadcrumbLink link={`${getFrameworkGroupControlURL(controlNavigatedFrom.identifier)}#risks`}>{controlNavigatedFrom.metadata.is_custom ? controlNavigatedFrom.metadata.control_name : controlId}</BreadcrumbLink>
                    <BreadcrumbText>Create New Risk</BreadcrumbText>
                </Breadcrumb>
            );
        } else if (risk_id && props.pageType === RiskPageType.MANAGE && initialRisk) {
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}`}>Risk Listing</BreadcrumbLink>
                    {submitState === SubmitState.Deleted ? <BreadcrumbText>{initialRisk.title}</BreadcrumbText> : <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}/${risk_id}`}>{initialRisk.title}</BreadcrumbLink>}
                    <BreadcrumbText>Manage Risk</BreadcrumbText>
                </Breadcrumb>
            );
        } else
            return (
                <Breadcrumb textColor="blue">
                    <BreadcrumbLink link={`/${RISK_REGISTER}/${RISKS}`}>Risk Listing</BreadcrumbLink>
                    <BreadcrumbText>Create New Risk</BreadcrumbText>
                </Breadcrumb>
            );
    };

    const deleteRisk = async (): Promise<string> => {
        await props.riskRegisterApi.deleteRisk(risk_id!);
        setSubmitState(SubmitState.Deleted);
        return 'Risk deleted.';
    };

    if (controlMappingItemsError) {
        return <Text>{controlMappingItemsError.message}</Text>;
    }

    if (pageErrorMessage) {
        return <Text>{pageErrorMessage}</Text>;
    }

    if (tagOptionsState.type === 'failure') {
        return <Text>{tagOptionsState.message}</Text>;
    }

    if (riskCategoryOptions && riskCategoryOptions.length < 1) {
        return <Text>There are no categories created for Risks. To continue, please create a category in Settings.</Text>;
    }

    if (selectedControls && actions && currentlyMappedActionIds && mappedActionIds && riskStrategyOptions && riskCategoryOptions && tagOptionsState.type === 'success' && controlMappingItems !== undefined && controlsByIdentifier !== undefined && ((props.pageType === RiskPageType.MANAGE && initialRisk) || props.pageType === RiskPageType.CREATE_NEW)) {
        const tagOptions = tagOptionsState.data;
        const totalWeight = selectedControls.map((control) => control.weight).reduce((a, b) => a + b, 0);
        const distributionWarningMessage = (() => {
            if (totalWeight > 100) {
                return 'The total control distribution cannot be greater than 100%.';
            } else if (totalWeight < 100) {
                return WARNING_MESSAGE_INCOMPLETE_CONTROL_DISTRIBUTION;
            } else {
                return undefined;
            }
        })();

        const handleSelectedControlsChanged = (controls: string[]) => {
            const currentWeights = new Map(selectedControls.map((control) => [control.identifier, control.weight]));

            setSelectedControls(
                // For any control that is newly added, give it a weight of 0%.
                controls.map((controlIdentifier) => selectedControlFromOperationalControl(controlsByIdentifier.get(controlIdentifier)!, currentWeights.get(controlIdentifier) ?? 0))
            );
        };

        const handleControlWeightsChanged = (weights: number[]) => {
            setSelectedControls(selectedControls.map((selectedControl, index) => ({ ...selectedControl, weight: weights[index] })));
        };

        const validateFormInput = () => {
            if (title.length === 0) {
                throw new ValidationError('Title is required.');
            }

            if (description.length === 0) {
                throw new ValidationError('Description is required.');
            }

            if (owner === undefined) {
                throw new ValidationError('Owner is required.');
            }

            if (riskCategory === undefined) {
                throw new ValidationError('Risk Category is required.');
            }

            if (totalWeight > 100) {
                throw new ValidationError('The total control distribution cannot be greater than 100%.');
            }
        };

        const createNewRisk = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
            event.preventDefault();

            setSubmitState(SubmitState.Saving);
            try {
                validateFormInput();
                const selectedCategory = riskCategoryOptions.find((category) => category.id === riskCategory);
                if (owner && description && selectedCategory && mappedActionIds) {
                    const saveRiskRequest: SaveRiskRequest = {
                        category: selectedCategory.id,
                        description: description,
                        tags: tags,
                        title: title,
                        owner: owner.cognito_subject,
                        strategy: riskStrategy,
                        reference: reference,
                        treatment_plan_description: treatmentPlan,
                        associated_actions: mappedActionIds,
                        controls: selectedControls.map((control) => ({ control: control.identifier, weight: control.weight })),
                    };
                    if (props.pageType === RiskPageType.MANAGE && risk_id) {
                        await props.riskRegisterApi.updateRisk(risk_id, saveRiskRequest);
                        // An existing Risk was updated. Currently, this can only be done from the Risk details page.
                        setToastSuccess({ text: 'Risk saved.', link: `/${RISK_REGISTER}/${RISKS}/${risk_id}`, linkText: 'View Risk' });
                    } else {
                        const createdRiskId = (await props.riskRegisterApi.createRisk(saveRiskRequest)).data;
                        if (controlNavigatedFrom) {
                            // A new Risk was created from the Control details page.
                            setToastSuccess({ text: 'Risk saved.', link: `${getFrameworkGroupControlURL(controlNavigatedFrom.identifier)}#risks`, linkText: 'Return to control' });
                        } else {
                            // A new Risk was created from the Risk listing page.
                            setToastSuccess({ text: 'Risk saved.', link: `/${RISK_REGISTER}/${RISKS}/${createdRiskId}`, linkText: 'View Risk' });
                        }
                    }
                    setSubmitState(SubmitState.Saved);
                }
            } catch (error) {
                setToastErrorMessage(error.message);
                setSubmitState(SubmitState.None);
            }
        };

        return (
            <>
                {displayedModal === Modal.Delete && initialRisk && (
                    <ConfirmationModal operationType="delete" headerText="Delete Risk" areYouSureText={`Are you sure you want to delete "${initialRisk.title}"?`} performOperation={deleteRisk} hideModal={() => setDisplayedModal(Modal.None)}>
                        <ul className={styles.deleteConfirmationList}>
                            <li>
                                <Text>The risk will be unmapped from any controls that are mitigating it.</Text>
                            </li>
                            <li>
                                <Text>If the risk's treatment plan includes any actions, they will be unmapped from the treatment plan.</Text>
                            </li>
                        </ul>
                    </ConfirmationModal>
                )}
                {toastSuccess && <LinkButtonToast variant="success" clearToast={() => setToastSuccess(undefined)} text={toastSuccess.text} linkButtonText={toastSuccess.linkText} linkButtonTo={toastSuccess.link} />}
                {toastErrorMessage && <TextToast variant="failure" clearToast={() => setToastErrorMessage(undefined)} text={toastErrorMessage} />}
                <Page
                    headerBreadcrumb={createBreadcrumb()}
                    headerTitle={props.pageType === RiskPageType.CREATE_NEW ? 'Create New Risk' : 'Manage Risk'}
                    body={[
                        {
                            title: 'Risk Details',
                            content: (
                                <Form onSubmit={createNewRisk} id="manageRisk" noValidate>
                                    <fieldset disabled={submitState === SubmitState.Saved || submitState === SubmitState.Deleted}>
                                        <div className={styles.formFieldsContainer}>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldText formFieldLabel="Title" formFieldId="title" required handleChange={(event: React.FormEvent<HTMLInputElement>) => setTitle(event.currentTarget.value)} value={title} />
                                            </div>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldTextArea formFieldLabel="Description" formFieldId="description" required handleChange={(event: React.FormEvent<HTMLInputElement>) => setDescription(event.currentTarget.value)} value={description} />
                                            </div>
                                            <div className={styles.fieldGroupContainer}>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldSelect formFieldLabel="Risk Category" isRequiredField formFieldId="risk_category" handleChange={(value: ChangeEventType) => setRiskCategory(value as string)} options={riskCategoryOptions.sort((categoryA, categoryB) => (categoryA.title > categoryB.title ? 1 : -1)).map((option) => ({ label: option.title, value: option.id }))} selectedOption={riskCategory} tooltip="The classification/grouping to which the risk belongs. Risk categories are defined in Settings." />
                                                </div>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldSelect formFieldLabel="Risk Strategy" formFieldId="risk_strategy" handleChange={(value: ChangeEventType) => setRiskStrategy(value as string)} options={riskStrategyOptions.sort((strategyA, strategyB) => (strategyA.title > strategyB.title ? 1 : -1)).map((option) => ({ label: option.title, value: option.id }))} selectedOption={riskStrategy} tooltip="How your organization will address the risk. Risk strategies are defined in Settings." />
                                                </div>
                                            </div>
                                            <div className={styles.fieldGroupContainer}>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldMultiOptionSelect
                                                        defaultSelectedOptions={tagOptions
                                                            .map((group) => group.options)
                                                            .flat()
                                                            .filter((option) => tags.includes(option.value as string))}
                                                        formFieldLabel="Tags"
                                                        formFieldId="risk_tags"
                                                        handleChange={(value: GroupOptionType[]) => setTags(value.map((tag) => tag.value as string))}
                                                        options={tagOptions}
                                                        accessibilityLabel="tag selection"
                                                    />
                                                </div>
                                                <div className={styles.fieldContainer}>
                                                    <FormFieldUserSelect users={users.filter(isUserWithRiskRole)} onUserSelected={(user: UserResponse) => setOwner(user)} formFieldId="owner" selectedUser={owner} formFieldLabel="Owner" isRequiredField tooltip="The individual responsible for the risk. Only users with access to Risk Management can own risks." />
                                                </div>
                                            </div>
                                            <div className={styles.fieldContainer}>
                                                <FormFieldText formFieldLabel="Reference" formFieldId="reference" handleChange={(event: React.FormEvent<HTMLInputElement>) => setReference(event.currentTarget.value)} value={reference ?? ''} tooltip="An optional reference to an external system, such as an ID, a ticket number, or a URL." />
                                            </div>
                                        </div>
                                        <div className={styles.accordionMargin}>
                                            <Accordion defaultActiveKey={initialRisk && selectedControls.length > 0 ? MITIGATING_CONTROLS_ACCORDION : undefined}>
                                                <div className={styles.accordionContainer}>
                                                    <AccordionToggle eventKey={MITIGATING_CONTROLS_ACCORDION} ariaLabelSuffix={'Map Mitigating Controls'} />
                                                    <div className={styles.accordionHeaderContainer}>
                                                        <Text variant="Header2">Map Mitigating Controls</Text>
                                                    </div>
                                                </div>
                                                <hr />
                                                <AccordionCollapse eventKey={MITIGATING_CONTROLS_ACCORDION}>
                                                    <div className={styles.accordionCollapse}>
                                                        <MultipleControlMapping controls={controlMappingItems} handleControlChange={handleSelectedControlsChanged} currentMappedControlIdentifiers={selectedControls.map((control) => control.identifier)} />
                                                        <div className={styles.controlDistribution}>
                                                            <Text variant="Header3">Control Distribution</Text>
                                                            {/* <hr /> */}
                                                            {selectedControls.length > 0 ? <ControlDistribution controls={selectedControls} totalWeight={totalWeight} warningMessage={distributionWarningMessage} changeWeights={handleControlWeightsChanged} /> : <Text>No controls have been selected.</Text>}
                                                        </div>
                                                    </div>
                                                </AccordionCollapse>
                                            </Accordion>
                                        </div>
                                        <div className={styles.accordionMargin}>
                                            <Accordion defaultActiveKey={initialRisk && currentlyMappedActionIds.length > 0 ? TREATMENT_PLAN_ACCORDION : undefined}>
                                                <div className={styles.accordionContainer}>
                                                    <AccordionToggle eventKey={TREATMENT_PLAN_ACCORDION} ariaLabelSuffix={'Treatment Plan'} />
                                                    <div className={styles.accordionHeaderContainer}>
                                                        <Text variant="Header2">Treatment Plan</Text>
                                                    </div>
                                                </div>
                                                <hr />
                                                <AccordionCollapse eventKey={TREATMENT_PLAN_ACCORDION}>
                                                    <div className={styles.accordionCollapse}>
                                                        <div className={styles.fieldContainer}>
                                                            <FormFieldTextArea formFieldLabel="Treatment Plan Description" formFieldId="treatment_plan_description" handleChange={(event: React.FormEvent<HTMLInputElement>) => setTreatmentPlan(event.currentTarget.value)} value={treatmentPlan} />
                                                        </div>
                                                        <Text variant="Header3">Map Actions</Text>
                                                        <MultipleActionMapping actions={actions} handleChange={(actionIds: string[]) => setMappedActionIds(actionIds)} currentlyMappedActionIds={currentlyMappedActionIds} />
                                                    </div>
                                                </AccordionCollapse>
                                            </Accordion>
                                        </div>
                                        <div className={styles.buttonContainer}>
                                            {props.pageType === RiskPageType.MANAGE && (
                                                <Button variant="danger" disabled={submitState !== SubmitState.None} onClick={() => setDisplayedModal(Modal.Delete)} fontAwesomeImage={faTrash}>
                                                    Delete
                                                </Button>
                                            )}
                                            <div className={styles.submitButton}>
                                                <Button variant="submit" form="manageRisk" disabled={submitState !== SubmitState.None} isLoading={submitState === SubmitState.Saving} loadingText="Submitting...">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </Form>
                            ),
                        },
                    ]}
                />
            </>
        );
    } else return <Placeholder />;
};

import { groupBy, values } from 'lodash-es';
import moment from 'moment';

import { CSSColors } from 'Components/Colors';
import { TprmDashboardCompletedRiskAssessment, TprmDashboardRiskType } from 'Models/Dashboards';
import { Effectiveness, effectivenessAsString, numberAsEffectivenessString } from 'Models/OperationalControls';
import { RiskRating, RiskRatingAsString, numberAsRiskRatingString } from 'Models/TPRM';

// chart styles

export const getRiskRatingChartColor = (riskRating: RiskRating): CSSColors => {
    switch (riskRating) {
        case RiskRating.INACTIVE:
            return CSSColors.DARK_GRAY;
        case RiskRating.LOW:
            return CSSColors.DARK_GREEN;
        case RiskRating.LOWMODERATE:
            return CSSColors.LIGHT_GREEN;
        case RiskRating.MODERATE:
            return CSSColors.YELLOW;
        case RiskRating.MODERATEHIGH:
            return CSSColors.ORANGE;
        case RiskRating.HIGH:
            return CSSColors.RED;
    }
};

export const getEffectivenessChartColor = (riskRating: Effectiveness): CSSColors => {
    switch (riskRating) {
        case Effectiveness.INACTIVE:
            return CSSColors.DARK_GRAY;
        case Effectiveness.FAIL:
            return CSSColors.RED;
        case Effectiveness.WEAK:
            return CSSColors.ORANGE;
        case Effectiveness.MODERATE:
            return CSSColors.YELLOW;
        case Effectiveness.STRONG:
            return CSSColors.LIGHT_GREEN;
        case Effectiveness.ROBUST:
            return CSSColors.DARK_GREEN;
    }
};

// data transformers

const mapUndefinedRiskRatingOrEffectivenessScoresToZero = (scores: (number | undefined)[]): number[] => scores.map((score) => (score === undefined ? 0 : score));
const filterInactiveRiskRatingsOrEffectivenessScores = (scores: (number | undefined)[]): number[] => scores.filter((score) => score !== undefined && score !== 0) as number[];

export const calculateRiskRatingOrEffectivenessAverage = (values: (number | undefined)[]): number => {
    const activeValues = filterInactiveRiskRatingsOrEffectivenessScores(values);
    if (activeValues.length === 0) {
        return 0;
    }

    return activeValues.reduce((acc, val) => (acc += val), 0) / activeValues.length;
};

interface RiskRatingsDataArray {
    value: number;
    name: string;
    color: CSSColors;
}

/**
 * Groups risk ratings into named and color-coded groups.
 */
export const buildRiskRatingDataArray = (scores: number[]): RiskRatingsDataArray[] => {
    const allPossibleRiskRatings = [RiskRating.INACTIVE, RiskRating.LOW, RiskRating.LOWMODERATE, RiskRating.MODERATE, RiskRating.MODERATEHIGH, RiskRating.HIGH];
    const getScoreCount = (searchValue: RiskRating) => scores.filter((score: number) => searchValue === score).length;

    return allPossibleRiskRatings.map((riskRatingValue: RiskRating) => ({
        value: getScoreCount(riskRatingValue),
        name: RiskRatingAsString(riskRatingValue),
        color: getRiskRatingChartColor(riskRatingValue),
    }));
};

interface RiskRatingsData {
    data: RiskRatingsDataArray[];
    numberOfScoredServices: number;
    average: string;
    averageLabel: string;
}

/**
 * Calculates an average risk rating and the number of "active" scores, and groups risk ratings into named and color-coded groups.
 */
export const buildRiskRatingsChartData = (scores: (number | undefined)[]): RiskRatingsData => {
    const average = calculateRiskRatingOrEffectivenessAverage(scores);
    const activeScores = filterInactiveRiskRatingsOrEffectivenessScores(scores);
    const numberOfScoredServices = activeScores.length;

    return { data: buildRiskRatingDataArray(mapUndefinedRiskRatingOrEffectivenessScoresToZero(scores)), numberOfScoredServices, average: average.toFixed(1), averageLabel: numberAsRiskRatingString(average) };
};

export const buildEffectivenessDataArray = (scores: number[]): RiskRatingsDataArray[] => {
    const allPossibleEffectivenessRatings = [Effectiveness.INACTIVE, Effectiveness.FAIL, Effectiveness.WEAK, Effectiveness.MODERATE, Effectiveness.STRONG, Effectiveness.ROBUST];
    const getScoreCount = (searchValue: Effectiveness) => scores.filter((score: number) => searchValue === score).length;

    return allPossibleEffectivenessRatings.map((effectivenessValue: Effectiveness) => ({
        value: getScoreCount(effectivenessValue),
        name: effectivenessAsString(effectivenessValue),
        color: getEffectivenessChartColor(effectivenessValue),
    }));
};

/**
 * Calculates an average effectiveness rating and the number of "active" scores, and groups effectiveness ratings into named and color-coded groups.
 */
export const buildEffectivenessChartData = (scores: (number | undefined)[]): RiskRatingsData => {
    const average = calculateRiskRatingOrEffectivenessAverage(scores);
    const activeScores = filterInactiveRiskRatingsOrEffectivenessScores(scores);
    const numberOfScoredServices = activeScores.length;

    return { data: buildEffectivenessDataArray(mapUndefinedRiskRatingOrEffectivenessScoresToZero(scores)), numberOfScoredServices, average: average.toFixed(1), averageLabel: numberAsRiskRatingString(average) };
};

export const getMonths = (startDate: Date, endDate: Date): string[] => {
    const months = [];

    // create a copy to avoid mutating original date
    const currentDate = moment(startDate);
    const end = moment(endDate).endOf('M');

    while (currentDate.isBefore(end)) {
        months.push(currentDate.format('MMM YY'));
        currentDate.add(1, 'month');
    }

    return months;
};

export interface AssessmentsBarChartDataInput {
    monthKey: string; // formatted string key "MMM YY" or "overdue"
    riskScore?: number;
    completedOnTime?: boolean;
}

export interface AssessmentsBarChartDataRow {
    data: number[];
    name: string;
    color: CSSColors;
}

export const buildAssessmentsBarChartData = (assessments: AssessmentsBarChartDataInput[], months: string[]): number[] => {
    return months.map((month) => assessments.filter((assessment) => assessment.monthKey === month).length);
};

export const getPercentageCompletedOnTime = (assessments: AssessmentsBarChartDataInput[], months: string[]): number[] => {
    return months.map((month) => {
        const currentMonthAssessments = assessments.filter((assessment) => assessment.monthKey === month);
        return (currentMonthAssessments.filter((assessment) => assessment.completedOnTime).length / currentMonthAssessments.length) * 100;
    });
};

export const buildAssessmentsBarChartByMonth = (assessments: AssessmentsBarChartDataInput[], riskType: TprmDashboardRiskType, months: string[]): AssessmentsBarChartDataRow[] => {
    const displayValues: number[] = riskType === TprmDashboardRiskType.CONTROL_EFFECTIVENESS ? [Effectiveness.INACTIVE, Effectiveness.FAIL, Effectiveness.WEAK, Effectiveness.MODERATE, Effectiveness.STRONG, Effectiveness.ROBUST] : [RiskRating.INACTIVE, RiskRating.HIGH, RiskRating.MODERATEHIGH, RiskRating.MODERATE, RiskRating.LOWMODERATE, RiskRating.LOW];
    return displayValues.map((displayValue: number) => ({
        data: buildAssessmentsBarChartData(
            assessments.filter((assessment) => assessment.riskScore === displayValue),
            months
        ),
        name: riskType === TprmDashboardRiskType.CONTROL_EFFECTIVENESS ? numberAsEffectivenessString(displayValue) : RiskRatingAsString(displayValue),
        color: riskType === TprmDashboardRiskType.CONTROL_EFFECTIVENESS ? getEffectivenessChartColor(displayValue) : getRiskRatingChartColor(displayValue),
    }));
};

/**
 * Given an array of Third-Party Service Assessments, removes any assessments that supersede earlier assessments of the same service in the same month.
 * The rationale for doing this is that if a client assesses a service multiple times in a short period of time, it is most likely because a mistake was made or new information was brought to light that invalidates the earlier assessment(s).
 * Returns a new array.
 */
export const onlyMostRecentAssessmentsPerMonth = (assessments: TprmDashboardCompletedRiskAssessment[]): TprmDashboardCompletedRiskAssessment[] => {
    const assessmentsByMonth = groupBy(assessments, (assessment) => {
        const completedDate = moment(assessment.completed_time);
        return `${completedDate.year().toString()} ${completedDate.month().toString()}`;
    });

    const latestAssessments: TprmDashboardCompletedRiskAssessment[] = [];
    values(assessmentsByMonth).forEach((assessmentsforMonth) => {
        const assessmentsByService = groupBy(assessmentsforMonth, (assessment) => assessment.service_id);

        values(assessmentsByService).forEach((assessmentsForService) => {
            const mostRecentAssessmentForService = assessmentsForService.reduce((latest, assessment) => (assessment.completed_time > latest.completed_time ? assessment : latest));
            latestAssessments.push(mostRecentAssessmentForService);
        });
    });

    return latestAssessments;
};

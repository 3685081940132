import { Component } from 'react';
import { Toast as BootstrapToast, ToastBody, ToastHeader } from 'react-bootstrap';

import { Link } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';

import styles from './Toast.module.css';

interface ToastProps {
    variant: 'success' | 'failure';
    clearToast: () => void;
    autohide?: boolean;
    header: JSX.Element;
    body: JSX.Element;
}
class Toast extends Component<ToastProps> {
    render(): JSX.Element {
        if (this.props.variant === 'success') {
            return (
                <BootstrapToast className={styles.toast} onClose={this.props.clearToast} delay={3000} autohide={this.props.autohide}>
                    <ToastHeader className={styles.toastHeaderSuccess}>{this.props.header}</ToastHeader>
                    <ToastBody>{this.props.body}</ToastBody>
                </BootstrapToast>
            );
        } else {
            return (
                <BootstrapToast className={styles.toast} onClose={this.props.clearToast} delay={3000} autohide={this.props.autohide}>
                    <ToastHeader className={styles.toastHeaderFailure}>{this.props.header}</ToastHeader>
                    <ToastBody>{this.props.body}</ToastBody>
                </BootstrapToast>
            );
        }
    }
}

export interface TextToastProps {
    variant: 'success' | 'failure';
    clearToast: () => void;
    autohide?: boolean;
    text: string;
}
export class TextToast extends Component<TextToastProps> {
    render(): JSX.Element {
        if (this.props.variant === 'success') {
            return (
                <Toast
                    variant={this.props.variant}
                    clearToast={this.props.clearToast}
                    autohide={this.props.autohide}
                    header={
                        <Text color="darkGreen" variant="Text2" noStyles>
                            Success!
                        </Text>
                    }
                    body={<Text variant="Text3">{this.props.text}</Text>}
                ></Toast>
            );
        } else {
            return (
                <Toast
                    variant={this.props.variant}
                    clearToast={this.props.clearToast}
                    autohide={this.props.autohide}
                    header={
                        <Text color="red" variant="Text2" noStyles>
                            Failure!
                        </Text>
                    }
                    body={<Text variant="Text3">{this.props.text}</Text>}
                ></Toast>
            );
        }
    }
}

export interface LinkButtonToastProps {
    variant: 'success' | 'failure';
    clearToast: () => void;
    autohide?: boolean;
    text: string;
    linkButtonText: string;
    linkButtonTo: string;
}
// TODO: This is rendering "Success!" and the link regardless of the variant. Is this being used for failure cases anywhere? If so, I doubt it's working as intended.
export class LinkButtonToast extends Component<LinkButtonToastProps> {
    render(): JSX.Element {
        if (this.props.variant === 'success') {
            return (
                <Toast
                    variant={this.props.variant}
                    clearToast={this.props.clearToast}
                    autohide={this.props.autohide}
                    header={
                        <Text color="darkGreen" variant="Text2" noStyles>
                            Success!
                        </Text>
                    }
                    body={
                        <>
                            <Text variant="Text3">{this.props.text}</Text>
                            <Link size="sm" to={this.props.linkButtonTo}>
                                {this.props.linkButtonText}
                            </Link>
                        </>
                    }
                ></Toast>
            );
        } else {
            return (
                <Toast
                    variant={this.props.variant}
                    clearToast={this.props.clearToast}
                    autohide={this.props.autohide}
                    header={
                        <Text color="red" variant="Text2" noStyles>
                            Success!
                        </Text>
                    }
                    body={
                        <>
                            <Text variant="Text3" color="red">
                                {this.props.text}
                            </Text>
                            <Link size="sm" to={this.props.linkButtonTo}>
                                {this.props.linkButtonText}
                            </Link>
                        </>
                    }
                ></Toast>
            );
        }
    }
}

export interface SuccessGoBackToastProps {
    clearToast: () => void;
    text: string;
}

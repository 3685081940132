import { Button } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ActionHistoryResponse } from 'Models/Actions';

import styles from './ActionHistoryTable.module.css';

export interface ActionHistoryTableProps {
    histories: ActionHistoryResponse[];
    onSelectHistory: (history: ActionHistoryResponse) => void;
    showRisks: (history: ActionHistoryResponse) => void;
}

export const ActionHistoryTable = (props: ActionHistoryTableProps) => {
    const { users } = useUsers();
    const sortedHistories = props.histories.sort((a, b) => (a.last_updated > b.last_updated ? -1 : 1));

    // `dataKey`s are arbitrary; the table does not allow sorting.
    const headerValues: HeaderData[] = [
        { dataKey: 'DATE', label: 'DATE', disableSort: true },
        { dataKey: 'STATUS', label: 'STATUS', disableSort: true },
        { dataKey: 'OWNER', label: 'OWNER', disableSort: true },
        { dataKey: 'RISKS', label: 'RISKS', disableSort: true },
        { dataKey: 'DUE DATE', label: 'DUE DATE', disableSort: true },
    ];

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: () => void 0, // Arbitrary; the table does not allow sorting.
        currentSort: 'DATE', // Arbitrary; the table does not allow sorting.
        currentSortDirection: SortDirection.ASC, // Arbitrary; the table does not allow sorting.
    };

    const tableRow = (history: ActionHistoryResponse) => {
        return (
            <TableRow key={history.last_updated}>
                <TableCell className={styles.titleCell}>
                    <Button variant="linkText" size="sm" onClick={() => props.onSelectHistory(history)}>
                        {iso8601ToUsDateShort(history.last_updated)}
                    </Button>
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles>{history.status}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(history.owner, users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Button variant="linkText" size="sm" onClick={() => props.showRisks(history)}>{`${history.associated_risks.length} ${history.associated_risks.length === 1 ? 'Risk' : 'Risks'}`}</Button>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{history.due_date ? iso8601ToUsDateShort(history.due_date) : '-'}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortedHistories.map(tableRow)}</TableBody>
        </Table>
    );
};

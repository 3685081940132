import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { Link } from 'Components/Buttons/Buttons';
import { UserFilter } from 'Components/Filters/UserFilter/UserFilter';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { getCreateExceptionUrl } from 'Helpers/URLBuilder/URLBuilder';
import { ControlExceptionResponse, ExceptionResponse, ExceptionRiskScoreFilterOptionTypes, ExceptionStatus, ExceptionStatusFilterOptionTypes, ExceptionsSortFilterOptions, ThirdPartyExceptionResponse, sortExceptions, titleCaseExceptionStatus } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponse } from 'Models/TPRM';
import { ColorTheme, Filter, Filters } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';
import { ThirdPartyFilter } from 'Pages/TPRM/Components/Filters/ThirdPartiesFilter';

import styles from './ExceptionsListing.module.css';
import { ExceptionsMultiOptionFilter, ExceptionsMultiOptionFilterProps } from './ExceptionsMultiOptionFilter';
import { ExceptionsTableRow, ExceptionsTableRowProps } from '../ExceptionsTable/ExceptionsTableRow/ExceptionsTableRow';

/**
 * @param users
 * @param colorTheme    - The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
 * @param hideTitle     - If `true`, the title will not be displayed. This is useful since sometimes this component is used in a tab of the same name, making the title redundant. In other cases, Issues and Exceptions are shown together, so having a title for each is necessary to differentiate them.
 */
export interface ExceptionsListingBaseProps {
    users: UserResponse[];
    colorTheme?: ColorTheme;
    hideTitle?: boolean;
}

export interface ExceptionsListingControlsProps extends ExceptionsListingBaseProps {
    type: IssuesExceptionsModule.CONTROLS;
    exceptions: ControlExceptionResponse[];
    preselectedControlIdForCreate?: string;
    displayMappedControlsModal: (mappedControls: OperationalControl[]) => void;
    thirdParties?: never; // Typed like this so that `third parties` can be used unconditionally as a `useMemo` dependency.
}

export interface ExceptionsListingThirdPartiesProps extends ExceptionsListingBaseProps {
    type: IssuesExceptionsModule.TPRM;
    exceptions: ThirdPartyExceptionResponse[];
    thirdParties: ThirdPartyResponse[];
    preselectedThirdPartyIdForCreate?: string;
    preselectedThirdPartyIdForFilter?: string;
    disableThirdPartyFilter?: boolean;
}

export type ExceptionsListingProps = ExceptionsListingControlsProps | ExceptionsListingThirdPartiesProps;

/**
 * Renders a table of exceptions, which are either Operational Controls exceptions or TPRM exceptions.
 * The `type` prop determines which type of exceptions are displayed. This affects some minor things, such as whether a "Controls" or "Third Party" column is rendered.
 *
 * In addition to the table, this component renders:
 * * A header section with the text "Exceptions" and a "Create Exception" button.
 * * Filters for owner, reviewer, risk score, status, and third party (if TPRM exceptions are being listed).
 */
export const ExceptionsListing = (props: ExceptionsListingProps) => {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>(() => {
        const defaults: Filters = {
            [ExceptionsSortFilterOptions.STATUS]: { key: ExceptionsSortFilterOptions.STATUS, value: [ExceptionStatus.DRAFT_OPEN, ExceptionStatus.APPROVED, ExceptionStatus.DRAFT_CLOSE] },
        };

        if (props.type === IssuesExceptionsModule.TPRM && props.preselectedThirdPartyIdForFilter) {
            defaults[ExceptionsSortFilterOptions.THIRD_PARTY] = { key: ExceptionsSortFilterOptions.THIRD_PARTY, value: [props.preselectedThirdPartyIdForFilter] };
        }

        return defaults;
    });
    const [sortBy, setSortBy] = useState<string>(ExceptionsSortFilterOptions.STATUS);
    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);
    const [sortedAndFilteredExceptions, setSortedAndFilteredExceptions] = useState<ExceptionResponse[]>([]);

    const thirdPartyIdToNameMap: Map<string, string> | undefined = useMemo(() => {
        return props.type === IssuesExceptionsModule.TPRM ? new Map(props.thirdParties.map((thirdParty) => [thirdParty.id, thirdParty.name])) : undefined;
    }, [props.type, props.thirdParties]);

    const getThirdPartyName = thirdPartyIdToNameMap ? (thirdPartyId: string) => thirdPartyIdToNameMap.get(thirdPartyId)! : undefined;

    useEffect(() => {
        let exceptions = [...props.exceptions];

        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                exceptions = exceptions.filter((exception) => {
                    return filter.value.includes(exception[filter.key as keyof ExceptionResponse] as string);
                });
            }
        }
        sortExceptions(exceptions, props.users, sortBy as ExceptionsSortFilterOptions, sortDirection, thirdPartyIdToNameMap);
        setSortedAndFilteredExceptions(exceptions);
    }, [props.type, props.exceptions, props.users, selectedFilterOptions, sortBy, sortDirection, thirdPartyIdToNameMap]);

    const applySorting = (sortBy: string, sortDirection: SortDirection): void => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
    };

    const updateFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    const exceptionsMultiOptionFilterProps: ExceptionsMultiOptionFilterProps = {
        filterOptions: [
            {
                label: 'Risk Score',
                options: ExceptionRiskScoreFilterOptionTypes,
            },
            {
                label: 'Status',
                options: ExceptionStatusFilterOptionTypes,
            },
        ],
        selectedFilterOptions: updateFilterSelection,
        defaultSelectedOptions: [
            { groupId: ExceptionsSortFilterOptions.STATUS, label: titleCaseExceptionStatus(ExceptionStatus.DRAFT_OPEN), value: ExceptionStatus.DRAFT_OPEN },
            { groupId: ExceptionsSortFilterOptions.STATUS, label: titleCaseExceptionStatus(ExceptionStatus.APPROVED), value: ExceptionStatus.APPROVED },
            { groupId: ExceptionsSortFilterOptions.STATUS, label: titleCaseExceptionStatus(ExceptionStatus.DRAFT_CLOSE), value: ExceptionStatus.DRAFT_CLOSE },
        ],
        colorTheme: props.colorTheme,
    };

    const headerValues: HeaderData[] = [
        { dataKey: ExceptionsSortFilterOptions.TITLE, label: 'TITLE' },
        { dataKey: ExceptionsSortFilterOptions.EXPIRATION, label: 'EXPIRATION' },
        { dataKey: ExceptionsSortFilterOptions.OWNER, label: 'OWNER' },
        { dataKey: ExceptionsSortFilterOptions.REVIEWER, label: 'REVIEWER' },
        { dataKey: ExceptionsSortFilterOptions.RISK_SCORE, label: 'RISK SCORE' },
        // Render "Controls" or "Third Party" column based on the type of exceptions being displayed.
        props.type === IssuesExceptionsModule.CONTROLS ? { dataKey: ExceptionsSortFilterOptions.CONTROLS, label: 'CONTROLS' } : { dataKey: ExceptionsSortFilterOptions.THIRD_PARTY, label: 'THIRD PARTY' },
        { dataKey: ExceptionsSortFilterOptions.STATUS, label: 'STATUS' },
    ];

    const tableRow = (exception: ExceptionResponse): JSX.Element => {
        const exceptionsTableRowProps: ExceptionsTableRowProps = {
            users: props.users,
            colorTheme: props.colorTheme,
            ...(props.type === IssuesExceptionsModule.CONTROLS ? { type: props.type, displayMappedControlsModal: props.displayMappedControlsModal, exception: exception as ControlExceptionResponse } : { type: props.type, getThirdPartyName: getThirdPartyName!, exception: exception as ThirdPartyExceptionResponse }),
        };
        return <ExceptionsTableRow key={exception.id} {...exceptionsTableRowProps} />;
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: applySorting,
        currentSort: sortBy,
        currentSortDirection: sortDirection,
        variant: (props.colorTheme ?? 'light') === 'light' ? 'standard' : 'dashboard',
    };

    const createButtonDestination: string = (() => {
        if (props.type === IssuesExceptionsModule.CONTROLS) {
            return getCreateExceptionUrl(IssuesExceptionsModule.CONTROLS, props.preselectedControlIdForCreate);
        } else {
            return getCreateExceptionUrl(IssuesExceptionsModule.TPRM, props.preselectedThirdPartyIdForCreate);
        }
    })();

    return (
        <>
            {!props.hideTitle ? (
                <div className={styles.titleAndButtonContainer}>
                    <Text variant="Header2" color={props.colorTheme === 'dark' ? 'white' : 'blue'}>
                        Exceptions
                    </Text>
                    <Link variant="primaryButton" to={createButtonDestination} fontAwesomeImage={faPlus}>
                        CREATE EXCEPTION
                    </Link>
                </div>
            ) : (
                <div className={styles.alignRight}>
                    <Link variant="primaryButton" to={createButtonDestination} fontAwesomeImage={faPlus}>
                        CREATE EXCEPTION
                    </Link>
                </div>
            )}
            <div className={styles.alignRight}>
                <div className={styles.filtersContainer}>
                    {props.type === IssuesExceptionsModule.TPRM && (
                        <div className={styles.filter}>
                            <ThirdPartyFilter colorTheme={props.colorTheme} disabled={props.disableThirdPartyFilter} thirdParties={props.thirdParties} selectedFilterOptions={updateFilterSelection} selectedThirdPartyIds={selectedFilterOptions[ExceptionsSortFilterOptions.THIRD_PARTY] && selectedFilterOptions[ExceptionsSortFilterOptions.THIRD_PARTY].value ? (selectedFilterOptions[ExceptionsSortFilterOptions.THIRD_PARTY].value as string[]) : []} />
                        </div>
                    )}
                    <div className={styles.filter}>
                        <UserFilter colorTheme={props.colorTheme} filterLabel="Filter by Owner" onUsersSelected={updateFilterSelection} users={props.users} userFilterId="owner_subject" formFieldId="exceptionsOwnerFilter" />
                    </div>
                    <div className={styles.filter}>
                        <ExceptionsMultiOptionFilter {...exceptionsMultiOptionFilterProps} />
                    </div>
                </div>
            </div>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{sortedAndFilteredExceptions.map(tableRow)}</TableBody>
            </Table>
        </>
    );
};

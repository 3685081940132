import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { RiskTable } from 'Components/RiskRegister/RiskTable/RiskTable';
import { Text } from 'Components/Text/Text';
import { RISK_REGISTER, RISK_REVIEW } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useDisplayableTagsLookup } from 'Hooks/Tags';
import { ResponseModel } from 'Models/ResponseModel';
import { RiskAssessment } from 'Models/RiskRegister';

import styles from './ViewRiskAssessment.module.css';
import { RiskListingCallouts } from '../RiskListing/RiskListingCallouts/RiskListingCallouts';

export interface UrlParams {
    timestamp: string;
}

export interface ViewRiskAssessmentProps {
    riskRegisterApi: RiskRegisterApi;
    documentApi: DocumentApi;
    tagsApi: TagsApi;
}

export const ViewRiskAssessment = (props: ViewRiskAssessmentProps): JSX.Element => {
    const { timestamp } = useParams<keyof UrlParams>() as UrlParams;
    const [pageErrorMessage, setPageErrorMessage] = useState<string>();
    const [previousAssessment, setPreviousAssessment] = useState<RiskAssessment>();
    const [overallControlEffectiveness, setOverallControlEffectiveness] = useState<number>();
    const { users } = useUsers();
    const getDisplayableTagsState = useDisplayableTagsLookup(props.tagsApi);

    useEffect(() => {
        const getRiskAssessment = async (): Promise<void> => {
            try {
                const riskAssessmentResponse: ResponseModel<RiskAssessment> = await props.riskRegisterApi.getRiskAssessment(timestamp);
                setPreviousAssessment(riskAssessmentResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };
        getRiskAssessment();
    }, [props.riskRegisterApi, timestamp]);

    useEffect(() => {
        const fetchOverallControlEffectiveness = async (): Promise<void> => {
            try {
                const effectivenessResponse = await props.riskRegisterApi.getOverallControlEffectiveness();
                setOverallControlEffectiveness(effectivenessResponse.data);
            } catch (error) {
                setPageErrorMessage(error.message);
            }
        };

        fetchOverallControlEffectiveness();
    }, [props.riskRegisterApi]);

    if (pageErrorMessage) {
        return <Text>{pageErrorMessage}</Text>;
    }

    if (previousAssessment && overallControlEffectiveness !== undefined && getDisplayableTagsState.type === 'success') {
        return (
            <>
                <PageBackground color="blueMountains">
                    <div className={styles.header}>
                        <PageContent>
                            <Breadcrumb textColor="blue">
                                <BreadcrumbLink link={`/${RISK_REGISTER}/${RISK_REVIEW}`}>Risk Reviews</BreadcrumbLink>
                                <BreadcrumbText>View Risk Review</BreadcrumbText>
                            </Breadcrumb>
                            <Text variant="Header1" color="white">
                                View Risk Review
                            </Text>
                            <div className={styles.container}>
                                <PageCell variant="transparentBlue">
                                    <Text variant="Header2" color="white">
                                        Risk Review Details
                                    </Text>
                                    <div className={styles.detailContainer}>
                                        <div className={styles.stat}>
                                            <Text noStyles variant="Header4" color="darkGray">
                                                Review Date
                                            </Text>
                                            <Text noStyles variant="Text1" color="white">
                                                {iso8601ToUsDateShort(previousAssessment.timestamp)}
                                            </Text>
                                        </div>
                                        <div className={styles.stat}>
                                            <Text noStyles variant="Header4" color="darkGray">
                                                Submitted By
                                            </Text>
                                            <Text noStyles variant="Text1" color="white">
                                                {getUserNameFromSubject(previousAssessment.submitted_by, users, UserNameFormat.FIRST_SPACE_LAST)}
                                            </Text>
                                        </div>
                                        <div className={styles.stat}>
                                            <Text noStyles variant="Header4" color="darkGray">
                                                Category
                                            </Text>
                                            <Text noStyles variant="Text1" color="white">
                                                {previousAssessment.category.title}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className={styles.assessmentSection}>
                                        <Text variant="Header4" color="darkGray">
                                            Comments
                                        </Text>
                                        <Text variant="Text3" color="white">
                                            {previousAssessment.comments}
                                        </Text>
                                    </div>
                                    <div className={styles.assessmentSection}>
                                        <Text variant="Header4" color="darkGray">
                                            Attached Files
                                        </Text>
                                        {previousAssessment!.documents?.map((document) => {
                                            return <UploadedFileAndState documentApi={props.documentApi} file={document} key={document.file_id} />;
                                        })}
                                    </div>
                                </PageCell>
                            </div>
                            <RiskListingCallouts risks={previousAssessment.risk_histories} completeEnvironmentEffectivenessAverage={overallControlEffectiveness} />
                        </PageContent>
                    </div>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <PageCell>
                            <ModalHeader text="Risk Listing" />
                            <RiskTable type="assessment risks" assessmentTimestamp={previousAssessment.timestamp} riskHistories={previousAssessment.risk_histories} />
                        </PageCell>
                    </PageContent>
                </PageBackground>
            </>
        );
    } else return <Placeholder />;
};

import { Button } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ControlIssueHistoryResponse, ControlIssueResponse, IssueHistoryResponse, IssuesExceptionsModule, ThirdPartyIssueHistoryResponse, ThirdPartyIssueResponse, titleCaseIssueStatus } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './IssueHistoryTable.module.css';

export interface ControlIssueHistoryTableProps {
    type: IssuesExceptionsModule.CONTROLS;
    histories: ControlIssueHistoryResponse[];
    displayMappedControlsModal: (mappedControls: OperationalControl[]) => void;
    onSelectHistory: (history: IssueHistoryResponse) => void;
}

export interface ThirdPartyIssueHistoryTableProps {
    type: IssuesExceptionsModule.TPRM;
    histories: ThirdPartyIssueHistoryResponse[];
    getThirdPartyName: (thirdPartyId: string) => string;
    onSelectHistory: (history: IssueHistoryResponse) => void;
}

export type IssueHistoryTableProps = ControlIssueHistoryTableProps | ThirdPartyIssueHistoryTableProps;

export const IssueHistoryTable = (props: IssueHistoryTableProps) => {
    const { users } = useUsers();
    const sortedHistories = props.histories.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));

    // `dataKey`s are arbitrary; the table does not allow sorting.
    const headerValues: HeaderData[] = [
        { dataKey: 'DATE', label: 'DATE', disableSort: true },
        { dataKey: 'STATUS', label: 'STATUS', disableSort: true },
        { dataKey: 'OWNER', label: 'OWNER', disableSort: true },
        { dataKey: 'MAPPED ENTITIES', label: props.type === IssuesExceptionsModule.CONTROLS ? 'CONTROLS' : 'THIRD PARTY', disableSort: true },
        { dataKey: 'DUE DATE', label: 'DUE DATE', disableSort: true },
    ];

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: () => void 0, // Arbitrary; the table does not allow sorting.
        currentSort: 'DATE', // Arbitrary; the table does not allow sorting.
        currentSortDirection: SortDirection.ASC, // Arbitrary; the table does not allow sorting.
    };

    const tableRow = (history: IssueHistoryResponse) => {
        return (
            <TableRow key={history.timestamp}>
                <TableCell className={styles.titleCell}>
                    <Button variant="linkText" size="sm" onClick={() => props.onSelectHistory(history)}>
                        {iso8601ToUsDateShort(history.timestamp)}
                    </Button>
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles>{titleCaseIssueStatus(history.issue.status)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles>{getUserNameFromSubject(history.issue.owner_subject, users)}</Text>
                </TableCellDefaultText>
                {props.type === IssuesExceptionsModule.CONTROLS ? (
                    <TableCellDefaultText>
                        <Button variant="linkText" size="sm" onClick={() => props.displayMappedControlsModal((history.issue as ControlIssueResponse).impacted_controls)}>{`${(history.issue as ControlIssueResponse).impacted_controls.length} ${(history.issue as ControlIssueResponse).impacted_controls.length === 1 ? 'Control' : 'Controls'}`}</Button>
                    </TableCellDefaultText>
                ) : (
                    <TableCellDefaultText>
                        <Text noStyles>{props.getThirdPartyName((history.issue as ThirdPartyIssueResponse).impacted_vendor)}</Text>
                    </TableCellDefaultText>
                )}
                <TableCellDefaultText>
                    <Text noStyles>{iso8601ToUsDateShort(history.issue.due_date)}</Text>
                </TableCellDefaultText>
            </TableRow>
        );
    };

    return (
        <Table>
            <SortableTableHeader {...sortableTableProps} />
            <TableBody>{sortedHistories.map(tableRow)}</TableBody>
        </Table>
    );
};

/*
    GenericTooltip.tsx -- A general-purpose component for creating mouse-over popups. This is in contrast to <FormFieldTooltip /> which is specific for creating the "help" mouse-over popups for form fields.

    There are certain use cases where this will not provide sufficient functionality, such as when the mouse-over element is a hyperlink. In those case, implement the <OverlayTrigger> and <Tooltip> components manually, maintaining the configurations of this component as much as possible for consistency.
*/

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Text } from 'Components/Text/Text';

import styles from './Tooltips.module.css';

export enum IconColor {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED',
    WHITE = 'WHITE',
    GRAY = 'GRAY',
}

interface GenericTooltipProps {
    text: string;
    width?: number;
    height?: number;
    fontAwesomeIcon?: IconDefinition;
    iconColor?: IconColor;
    title?: string;
}

export class GenericTooltip extends Component<GenericTooltipProps> {
    getIconColor = (): string => {
        switch (this.props.iconColor) {
            case IconColor.GREEN:
                return styles.tooltipColorGreen;
            case IconColor.YELLOW:
                return styles.tooltipColorYellow;
            case IconColor.WHITE:
                return styles.tooltipColorWhite;
            case IconColor.GRAY:
                return styles.tooltipColorGray;
            default:
                return styles.tooltipColorRed;
        }
    };

    // This is necessary because <OverlayTrigger /> throws a "React.Children.only expected to receive a single React element child" even with conditional rendering.
    renderIcon = (): JSX.Element => {
        if (this.props.fontAwesomeIcon) {
            return <FontAwesomeIcon title={this.props.title} icon={this.props.fontAwesomeIcon} className={this.getIconColor()} />;
        } else {
            return <img src={'/i_toolTip.svg'} width={this.props.width || 15} height={this.props.height || 15} alt="tooltip" />;
        }
    };

    render(): JSX.Element {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 200 }}
                overlay={
                    <Tooltip id="information">
                        <Text color="white" noStyles={true}>
                            {this.props.text}
                        </Text>
                    </Tooltip>
                }
            >
                {this.renderIcon()}
            </OverlayTrigger>
        );
    }
}

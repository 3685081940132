import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { Text } from 'Components/Text/Text';
import { SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { TprmDashboardServiceExtended } from 'Models/Dashboards';

import styles from './TPRMServiceCallouts.module.css';
import { SelectedServiceDetails, ServicesDisplayModal } from '../ServicesDisplayModal/ServicesDisplayModal';

enum Modals {
    NONE,
    DISPLAY_SERVICES,
}

export interface TPRMServiceCalloutsProps {
    thirdPartyCount?: number;
    thirdPartyServicesCount?: number;
    overdueAssessments?: TprmDashboardServiceExtended[];
    thirdPartyServiceError?: string;
}

export const TPRMServiceCallouts = (props: TPRMServiceCalloutsProps): JSX.Element => {
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const navigate = useNavigate();

    const overDueAssessmentServices: SelectedServiceDetails[] | undefined = props.overdueAssessments?.map((assessment) => {
        return {
            serviceId: assessment.id,
            serviceName: assessment.name,
            thirdPartyId: assessment.third_party_id,
            thirdPartyName: assessment.third_party_name,
        };
    });

    const handleOverdueClick = () => {
        if (props.overdueAssessments) {
            setModalState(Modals.DISPLAY_SERVICES);
        }
    };

    return (
        <>
            {modalState === Modals.DISPLAY_SERVICES && overDueAssessmentServices && <ServicesDisplayModal hideModal={() => setModalState(Modals.NONE)} selectedServices={overDueAssessmentServices} subtitle={'Overdue Assessments'} />}
            <div className={styles.numberCallouts}>
                <PageCell variant="transparentBlue">
                    <div onClick={() => navigate(`/${TPRM}/${THIRD_PARTIES}`)} className={styles.assessmentScheduleBox}>
                        <Text variant="Header2" color="white">
                            Total Third Parties
                        </Text>
                        {props.thirdPartyCount !== undefined ? (
                            <div className={styles.assessmentNumber}>{props.thirdPartyCount}</div>
                        ) : (
                            <div className={styles.skeleton}>
                                <Skeleton>
                                    <div className={styles.assessmentNumber}>---</div>
                                </Skeleton>
                            </div>
                        )}
                    </div>
                </PageCell>
            </div>
            <div className={styles.numberCallouts}>
                <PageCell variant="transparentBlue">
                    <div onClick={() => navigate(`/${TPRM}/${SERVICES}`)} className={styles.assessmentScheduleBox}>
                        <Text variant="Header2" color="white">
                            Total Third-Party Services
                        </Text>
                        {props.thirdPartyServicesCount !== undefined ? (
                            <div className={styles.assessmentNumber}>{props.thirdPartyServicesCount}</div>
                        ) : (
                            <div className={styles.skeleton}>
                                <Skeleton>
                                    <div className={styles.assessmentNumber}>---</div>
                                </Skeleton>
                            </div>
                        )}
                    </div>
                </PageCell>
            </div>
            <div className={styles.numberCallouts}>
                <PageCell variant="transparentBlue">
                    <div onClick={handleOverdueClick} className={styles.assessmentScheduleBox}>
                        <Text variant="Header2" color="white">
                            Overdue Third-Party Service Assessments
                        </Text>
                        {props.overdueAssessments ? (
                            <div className={props.overdueAssessments.length > 0 ? styles.assessmentNumberOverdue : styles.assessmentNumber}>{props.overdueAssessments.length}</div>
                        ) : (
                            <div className={styles.skeleton}>
                                <Skeleton>
                                    <div className={styles.assessmentNumber}>---</div>
                                </Skeleton>
                            </div>
                        )}
                    </div>
                </PageCell>
            </div>
        </>
    );
};

import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { UsersApi } from 'Api/Users/UsersApi';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { UserResponse } from 'Models/User';

interface UsersContextValue {
    users: UserResponse[];
    refreshUsers: () => void;
}

export const UsersContext = createContext<UsersContextValue | undefined>(undefined);

interface UsersProviderProps {
    children: ReactNode;
    usersApi: UsersApi;
}

export const UsersProvider = ({ children, usersApi }: UsersProviderProps): JSX.Element => {
    const [users, setUsers] = useState<UserResponse[]>();
    const [error, setError] = useState<Error>();

    const getUsers = useCallback(() => {
        const getUsers = async (): Promise<void> => {
            try {
                const usersResponse = await usersApi.getUsers();
                const users = usersResponse.data;
                setUsers(users);
            } catch (error) {
                setError(error);
            }
        };
        getUsers();
    }, [usersApi]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    if (error) {
        return <Text>{error.message}</Text>;
    } else if (users === undefined) {
        return <Placeholder />;
    } else {
        return <UsersContext.Provider value={{ users, refreshUsers: getUsers }}>{children}</UsersContext.Provider>;
    }
};

export const useUsers = (): UsersContextValue => {
    const context = useContext(UsersContext);

    if (context === undefined) {
        throw new Error('useUsers must be used within a UsersProvider.');
    }
    return context;
};

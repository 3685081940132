import { Button, Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { TagListing } from 'Components/Tags/TagListing/TagListing';
import { Text } from 'Components/Text/Text';
import { ACTIONS } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { Action } from 'Models/Actions';
import { DisplayableTag } from 'Models/Tags';

import styles from './ActionsTableRow.module.css';

export interface ActionsTableRowProps {
    action: Action;
    showModal: () => void;
    getDisplayableTags: (tagIds: string[]) => DisplayableTag[];
}

export const ActionsTableRow = (props: ActionsTableRowProps) => {
    const { users } = useUsers();

    return (
        <TableRow>
            <TableCell className={styles.titleCell}>
                <div className={styles.actionsTitleContainer}>
                    <Link size="sm" to={`/${ACTIONS}/${props.action.id}`}>
                        {props.action.title}
                    </Link>
                </div>
            </TableCell>
            <TableCellDefaultText>
                <Text noStyles>{props.action.status}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{getUserNameFromSubject(props.action.owner, users)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>{props.action.associated_risks.length > 1 ? <Button variant="linkText" size="sm" onClick={props.showModal}>{`${props.action.associated_risks.length.toString()} risks`}</Button> : props.action.associated_risks.length === 1 && <Button variant="linkText" size="sm" onClick={props.showModal}>{`${props.action.associated_risks.length.toString()} risk`}</Button>}</TableCellDefaultText>
            <TableCellDefaultText>{props.action.due_date && <Text noStyles>{iso8601ToUsDateShort(props.action.due_date)}</Text>}</TableCellDefaultText>
            <TableCell className={styles.tagCell}>
                <TagListing tags={props.getDisplayableTags(props.action.tags)} />
            </TableCell>
        </TableRow>
    );
};

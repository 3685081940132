import { useUsers } from 'Components/Context/UsersContext';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsTimestampLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { Comment } from 'Models/OperationalControls';
import { UserResponse } from 'Models/User';

import styles from '../ConductAssessment.module.css';

export interface CurrentAssessmentOwnerCommentHistoryProps {
    handleRequestError: (error: Error) => void;
    ownerCommentHistory?: Comment[];
}

export const CurrentAssessmentOwnerCommentHistory = (props: CurrentAssessmentOwnerCommentHistoryProps): JSX.Element => {
    const { users } = useUsers();

    return (
        <div className={styles.fullWidth}>
            <Text variant="Header2">Current Control Assessment - Comment History</Text>
            <hr />
            <OwnerCommentHistory commentHistory={props.ownerCommentHistory} users={users} />
        </div>
    );
};

interface OwnerCommentHistoryProps {
    commentHistory?: Comment[];
    users: UserResponse[];
}
const OwnerCommentHistory = (props: OwnerCommentHistoryProps): JSX.Element | null => {
    if (!props.commentHistory || props.commentHistory.length < 1) {
        return null;
    } else {
        return (
            <div className={styles.alignVertical}>
                {props.commentHistory.map((comment, index) => {
                    return (
                        <div className={styles.ownerCommentHistoryContainer} key={index}>
                            <div className={styles.ownerCommentHistoryUserAndTimestamp}>
                                <Text>{comment.user_id ? getUserNameFromSubject(comment.user_id, props.users) : ''}</Text>
                                <Text>{comment.timestamp ? iso8601ToUsTimestampLong(comment.timestamp) : ''}</Text>
                            </div>
                            <Text>{comment.text}</Text>
                        </div>
                    );
                })}
            </div>
        );
    }
};

/*
    App.tsx -- The main component of the SummIT Security application. Maintains application-wide state and calls child containers for rendering.
*/
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { enableMapSet } from 'immer';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router';

import { AWSActionsApi } from 'Api/Actions/AWSActionsApi';
import { AWSAlertsLimitsApi } from 'Api/AlertsLimits/AWSAlertsLimitsApi';
import { AWSSessionApi } from 'Api/Auth/AWSSessionApi';
import { AWSPublicDDQAuthApi } from 'Api/Auth/PublicDDQ/AWSPublicDDQAuthApi';
import { AWSClientDetailsApi } from 'Api/Client/AWSClientDetailsApi';
import { AWSComplianceRequirementsApi } from 'Api/ComplianceRequirements/AWSComplianceRequirementsApi';
import { AWSControlsApi } from 'Api/Controls/AWSControlsApi';
import { AWSDashboardApi } from 'Api/Dashboards/AWSDashboardApi';
import { AWSDocumentApi } from 'Api/Document/AWSDocumentApi';
import { AWSPublicDocumentApi } from 'Api/Document/AWSPublicDocumentApi';
import { AWSExceptionsApi } from 'Api/Exceptions/AWSExceptionsApi';
import { AWSExportApi } from 'Api/Exports/AWSExportsApi';
import { AWSExternalIntegrationsApi } from 'Api/ExternalIntegrations/AWSExternalIntegrationsApi';
import { AWSGovernanceApi } from 'Api/Governance/AWSGovernanceApi';
import { AWSIssuesApi } from 'Api/Issues/AWSIssuesApi';
import { AWSNotificationsApi } from 'Api/Notifications/AWSNotificationsApi';
import { AWSRiskRegister } from 'Api/RiskRegister/AWSRiskRegister';
import { AWSPublicDDQApi } from 'Api/TPRM/AWSPublicDDQApi';
import { AWSTPRMApi } from 'Api/TPRM/AWSTPRMApi';
import { AWSTagsApi } from 'Api/Tags/AWSTagsApi';
import { AWSUsersApi } from 'Api/Users/AWSUsersApi';
import { NavProvider } from 'Components/Context/NavContext';
import { Role } from 'Components/Context/RBACContext';
import { UsersProvider } from 'Components/Context/UsersContext';
import { ErrorBoundary } from 'Components/ErrorBoundary/ErrorBoundary';
import { HeaderNetworkingApis } from 'Components/Header/Header';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { TimerNavigation, TimerNavigationProps } from 'Components/TimerNavigation/TimerNavigation';
import { ACTIONS, ALERTS, ASSESSMENTS, CLIENTS, COMPLIANCE_REQUIREMENTS, CONFIGURATION, CONTROLS, CREATE, DASHBOARDS, DDQ, DETAILS, EVIDENCE, EXCEPTIONS, EXPORTS, EXTERNAL_INTEGRATIONS, FOLDERS, FRAMEWORKS, GOVERNANCE, GROUPS, IRQ, ISSUES, ISSUES_EXCEPTIONS, LOGIN, LOGOUT, OPERATIONAL_CONTROLS, PUBLIC, REPORTS, REQUIREMENTS, RISKS, RISK_REGISTER, RISK_REVIEW, SERVICES, THIRD_PARTIES, TOGGLE, TPRM, USERS, VERSIONS } from 'Config/Paths';
import { PublicDDQBasicAuthHandler } from 'Helpers/Auth/BasicAuth/PublicDDQBasicAuthHandler';
import { WindowNavigator } from 'Helpers/WindowNavigator';
import { WindowScroller } from 'Helpers/WindowScroller';
import { IssuesExceptionsModule } from 'Models/Issues';
import { ActionsDetails } from 'Pages/ActionPlanning/ActionsDetails/ActionsDetails';
import { ActionsListing } from 'Pages/ActionPlanning/ActionsListing/ActionsListing';
import { ActionsPageType, ManageActions } from 'Pages/ActionPlanning/ManageActions/ManageActions';
import { AlertsDashboard } from 'Pages/AlertsDashboard/AlertsDashboard';
import { ComplianceRequirements } from 'Pages/ComplianceRequirements/ComplianceRequirements';
import { ConductAssessment } from 'Pages/ControlDetails/AssessmentDetails/ConductAssessment/ConductAssessment';
import { ControlDetails } from 'Pages/ControlDetails/ControlDetails';
import { ControlGroupDetails } from 'Pages/ControlGroupDetails/ControlGroupDetails';
import { ControlGroupSettings } from 'Pages/ControlGroupSettings/ControlGroupSettings';
import { ControlSettings } from 'Pages/ControlSettings/ControlSettings';
import { Dashboards } from 'Pages/Dashboards/Dashboards';
import { ActionsExport } from 'Pages/DataExports/ActionsExport/ActionsExport';
import { ComplianceRequirementsExport } from 'Pages/DataExports/ComplianceRequirementsExport/ComplianceRequirementsExport';
import { ControlAssessmentsExport } from 'Pages/DataExports/ControlAssessmentsExport/ControlAssessmentsExport';
import { EvidenceControl } from 'Pages/DataExports/EvidenceControlsExport/EvidenceControlsExport';
import { ExceptionsExport } from 'Pages/DataExports/ExceptionsExport/ExceptionsExport';
import { ExportListing } from 'Pages/DataExports/ExportListing/ExportListing';
import { GovernanceExport } from 'Pages/DataExports/GovernanceExport/GovernanceExport';
import { IssuesExport } from 'Pages/DataExports/IssuesExport/IssuesExport';
import { OperationalControlExport } from 'Pages/DataExports/OperationalControlsExport/OperationalControlsExport';
import { RiskAssessmentsExport } from 'Pages/DataExports/RiskAssessmentsExport/RiskAssessmentsExport';
import { RisksExport } from 'Pages/DataExports/RisksExport/RisksExport';
import { ThirdPartiesExport } from 'Pages/DataExports/ThirdPartiesExport/ThirdPartiesExport';
import { ThirdPartyServiceAssessments } from 'Pages/DataExports/ThirdPartyServiceAssessmentsExports/ThirdPartyServiceAssessmentsExport';
import { ThirdPartyServicesExport } from 'Pages/DataExports/ThirdPartyServicesExport/ThirdPartyServicesExport';
import { FrameworkDashboard } from 'Pages/FrameworkDashboard/FrameworkDashboard';
import { FrameworkSettings } from 'Pages/FrameworkSettings/FrameworkSettings';
import { AddGovernanceVersion } from 'Pages/Governance/AddGovernanceVersion/AddGovernanceVersion';
import { GovernanceLibrary } from 'Pages/Governance/GovernanceLibrary/GovernanceLibrary';
import { ManageGovernance } from 'Pages/Governance/ManageGovernance/ManageGovernance';
import { ExceptionDetails } from 'Pages/IssuesExceptions/Exceptions/ExceptionDetails/ExceptionDetails';
import { ManageException } from 'Pages/IssuesExceptions/Exceptions/ManageException/ManageException';
import { ExceptionsIssues } from 'Pages/IssuesExceptions/ExceptionsIssues';
import { IssueDetails } from 'Pages/IssuesExceptions/Issues/IssueDetails/IssueDetails';
import { ManageIssue } from 'Pages/IssuesExceptions/Issues/ManageIssue/ManageIssue';
import { Landing } from 'Pages/Landing/Landing';
import { ClientLogOut, PublicLogOut } from 'Pages/LogOut/LogOut';
import { LoggingIn } from 'Pages/LoggingIn/LoggingIn';
import { NotFound } from 'Pages/NotFound/NotFound';
import { RequirementDetails } from 'Pages/RequirementDetails/RequirementDetails';
import { CreateRiskAssessment } from 'Pages/RiskRegister/CreateRiskAssessment/CreateRiskAssessment';
import { ManageRisk, RiskPageType } from 'Pages/RiskRegister/ManageRisk/ManageRisk';
import { RiskAssessmentListing } from 'Pages/RiskRegister/RiskAssessmentListing/RiskAssessmentListing';
import { RiskDetails } from 'Pages/RiskRegister/RiskDetails/RiskDetails';
import { RiskListing } from 'Pages/RiskRegister/RiskListing/RiskListing';
import { ViewRiskAssessment } from 'Pages/RiskRegister/ViewRiskAssessment/ViewRiskAssessment';
import { ControlToggler } from 'Pages/Settings/ControlToggler/ControlToggler';
import { DueDiligenceQuestionnaireConfiguration } from 'Pages/Settings/DueDiligenceQuestionnaireConfiguration/DueDiligenceQuestionnaireConfiguration';
import { ExternalIntegrations } from 'Pages/Settings/ExternalIntegrations/ExternalIntegrations';
import { ManageUsers } from 'Pages/Settings/ManageUsers/ManageUsers';
import { Settings } from 'Pages/Settings/Settings';
import { DueDiligenceQuestionnaire } from 'Pages/TPRM/DueDiligenceQuestionnaire/DueDiligenceQuestionnaire';
import { FolderDetails } from 'Pages/TPRM/Folders/FolderDetails/FolderDetails';
import { FolderListing } from 'Pages/TPRM/Folders/FolderListing/FolderListing';
import { ManageFolders, TprmFolderPageType } from 'Pages/TPRM/Folders/ManageFolders/ManageFolders';
import { InherentRiskQuestionnaire } from 'Pages/TPRM/InherentRiskQuestionnaire/InherentRiskQuestionnaire';
import { ManageTPRMServices } from 'Pages/TPRM/ManageTPRMServices/ManageTPRMServices';
import { ManageTPRMThirdParties } from 'Pages/TPRM/ManageTPRMThirdParties/ManageTPRMThirdParties';
import { VSCLogin } from 'Pages/TPRM/Public/VSCLogin/VSCLogin';
import { ServiceControlAssessment } from 'Pages/TPRM/ServiceControlAssessment/ServiceControlAssessment';
import { ServiceDashboard } from 'Pages/TPRM/ServiceDashboard/ServiceDashboard';
import { UploadControlEvidence } from 'Pages/UploadControlEvidence/UploadControlEvidence';
import { authHandler } from 'RestApiConnector';

enableMapSet();

LicenseInfo.setLicenseKey('b54ace478abfb4801b004c7475246916Tz04MjQ1NSxFPTE3MzcyMDQ1NzAwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

export const App = (): JSX.Element => {
    const actionsApi = new AWSActionsApi();
    const riskRegisterApi = new AWSRiskRegister();
    const controlsApi = new AWSControlsApi();
    const alertsLimitsApi = new AWSAlertsLimitsApi();
    const usersApi = new AWSUsersApi();
    const clientDetailsApi = new AWSClientDetailsApi();
    const sessionApi = new AWSSessionApi();
    const issuesApi = new AWSIssuesApi();
    const exceptionsApi = new AWSExceptionsApi();
    const dashboardApi = new AWSDashboardApi();
    const windowNavigator = new WindowNavigator();
    const complianceRequirementsApi = new AWSComplianceRequirementsApi();
    const governanceApi = new AWSGovernanceApi();
    const tprmApi = new AWSTPRMApi();
    const documentApi = new AWSDocumentApi();
    const externalIntegrationsApi = new AWSExternalIntegrationsApi();
    const exportApi = new AWSExportApi();
    const tagsApi = new AWSTagsApi();
    const publicDDQBasicAuthHandler = new PublicDDQBasicAuthHandler();
    const publicDocumentApi = new AWSPublicDocumentApi();
    const publicDDQApi = new AWSPublicDDQApi();
    const publicDDQAuthApi = new AWSPublicDDQAuthApi();
    const windowScroller = new WindowScroller();

    const headerApis: HeaderNetworkingApis = {
        alertsLimitsApi: alertsLimitsApi,
        notificationsApi: new AWSNotificationsApi(),
        windowNavigator: windowNavigator,
    };

    const ServiceReportLazyLoaded = lazy(() => import('Pages/TPRM/ServiceReport/ServiceReport'));

    // Set the browser vertical scroll bar to the top on render. This way the user won't be confused if the scroll bar remains at the bottom of the page when they are navigating around.
    window.scrollTo(0, 0);

    if (window.location.pathname.startsWith(`/${PUBLIC}`)) {
        return (
            <NavProvider>
                <Routes>
                    <Route path={`/${PUBLIC}/${TPRM}/${DDQ}/${CLIENTS}/:client_id/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${LOGIN}`} element={<VSCLogin publicDDQAuthApi={publicDDQAuthApi} basicAuthHandler={publicDDQBasicAuthHandler} windowNavigator={windowNavigator} />} />
                    <Route path={`/${PUBLIC}/${TPRM}/${DDQ}`} element={<DueDiligenceQuestionnaire ddqApi={publicDDQApi} documentApi={publicDocumentApi} scroller={windowScroller} basicAuthHandler={publicDDQBasicAuthHandler} isThirdParty />} />
                    <Route path={`/${PUBLIC}/:error?`} element={<PublicLogOut />} />
                </Routes>
            </NavProvider>
        );
    }

    if (!authHandler.pageLoaded()) {
        return <LoggingIn />;
    }

    if (window.location.pathname.startsWith(`/${LOGOUT}`)) {
        return (
            <Routes>
                {/* Logout/Error page */}
                <Route path={`/${LOGOUT}/:error?`} element={<ClientLogOut authHandler={authHandler} />} />
            </Routes>
        );
    }

    const timerNavigationProps: Omit<TimerNavigationProps, 'roles' | 'children'> = {
        headerApis: headerApis,
        clientDetailsApi: clientDetailsApi,
        sessionApi: sessionApi,
        authHandler: authHandler,
    };

    return (
        <UsersProvider usersApi={usersApi}>
            <NavProvider>
                <TimerNavigation roles={Object.values(Role)} {...timerNavigationProps}>
                    <ErrorBoundary>
                        <Routes>
                            {/* Defined routes. */}
                            {/* A note on components that appear multiple times below: if we link to a page from the nav menu or from the header (alerts and notifications), and the corresponding component is associated with multiple routes below, it may need a `key` prop. For example, it's very important that `ExceptionsIssues` has the `key` prop. If it doesn't, and the user clicks directly between _Control Automation Issues and Exceptions_ and _Third-Party Risk Management Issues and Exceptions_ in the nav menu, then React Router will try to reuse the current component, leading to unintended behavior or even a crash (since the two "versions" of the component expect different types of data to be fetched on mount). The fact that React Router does not re-mount the current component if the URL changes to one still associated with the component can lead to bizarre behavior regardless of whether a component is reused for multiple routes. For example, clicking on a notification for a different control when already on the Control Details page can make the app appear momentarily unresponsive (particularly on a slow network connection) while the new details are fetched from the server. */}
                            <Route path={'/'} element={<Landing />} />
                            <Route path={`/${ACTIONS}`} element={<ActionsListing actionsApi={actionsApi} tagsApi={tagsApi} />} />
                            <Route path={`/${ACTIONS}/${CREATE}`} element={<ManageActions actionsApi={actionsApi} documentApi={documentApi} riskRegisterApi={riskRegisterApi} tagsApi={tagsApi} pageType={ActionsPageType.CREATE_NEW} />} />
                            <Route path={`/${ACTIONS}/:action_id`} element={<ActionsDetails actionsApi={actionsApi} documentApi={documentApi} tagsApi={tagsApi} />} />
                            <Route path={`/${ACTIONS}/:action_id/${CONFIGURATION}`} element={<ManageActions actionsApi={actionsApi} documentApi={documentApi} riskRegisterApi={riskRegisterApi} tagsApi={tagsApi} pageType={ActionsPageType.MANAGE} />} />
                            <Route path={`/${ALERTS}`} element={<AlertsDashboard alertsLimitsApi={alertsLimitsApi} />} />
                            <Route path={`/${DASHBOARDS}`} element={<Dashboards dashboardApi={dashboardApi} issuesApi={issuesApi} exceptionsApi={exceptionsApi} tprmApi={tprmApi} />} />
                            <Route path={`/${COMPLIANCE_REQUIREMENTS}`} element={<ComplianceRequirements controlsApi={controlsApi} complianceRequirementsApi={complianceRequirementsApi} />} />
                            <Route path={`/${COMPLIANCE_REQUIREMENTS}/:regulationName/${REQUIREMENTS}/:requirement/${DETAILS}`} element={<RequirementDetails controlsApi={controlsApi} complianceRequirementsApi={complianceRequirementsApi} />} />
                            <Route path={`/${CONFIGURATION}`} element={<Settings controlsApi={controlsApi} dashboardApi={dashboardApi} riskRegisterApi={riskRegisterApi} tagsApi={tagsApi} tprmApi={tprmApi} />} />
                            <Route path={`/${CONFIGURATION}/${EXTERNAL_INTEGRATIONS}`} element={<ExternalIntegrations clientDetailsApi={clientDetailsApi} controlsApi={controlsApi} externalIntegrationsApi={externalIntegrationsApi} />} />
                            <Route path={`/${CONFIGURATION}/${OPERATIONAL_CONTROLS}/${TOGGLE}`} element={<ControlToggler controlsApi={controlsApi} />} />
                            <Route path={`/${CONFIGURATION}/${TPRM}/${DDQ}`} element={<DueDiligenceQuestionnaireConfiguration tprmApi={tprmApi} />} />
                            <Route path={`/${CONFIGURATION}/${USERS}`} element={<ManageUsers usersApi={usersApi} />} />
                            <Route path={`/${EXCEPTIONS}/${CREATE}`} element={<ManageException exceptionsApi={exceptionsApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            {/* See the comment at the top of the route definitions to understand why `key` is used. */}
                            <Route path={`/${EXCEPTIONS}/:exceptionId`} element={<ExceptionDetails key={IssuesExceptionsModule.CONTROLS} type={IssuesExceptionsModule.CONTROLS} exceptionsApi={exceptionsApi} documentApi={documentApi} />} />
                            <Route path={`/${EXCEPTIONS}/:exceptionId/${CONFIGURATION}`} element={<ManageException exceptionsApi={exceptionsApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            <Route path={`/${EXPORTS}`} element={<ExportListing />} />
                            <Route path={`/${EXPORTS}/${ACTIONS}/`} element={<ActionsExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${COMPLIANCE_REQUIREMENTS}`} element={<ComplianceRequirementsExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${EXCEPTIONS}`} element={<ExceptionsExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${GOVERNANCE}`} element={<GovernanceExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${ISSUES}`} element={<IssuesExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${OPERATIONAL_CONTROLS}`} element={<OperationalControlExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${OPERATIONAL_CONTROLS}/${EVIDENCE}`} element={<EvidenceControl exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${OPERATIONAL_CONTROLS}/${ASSESSMENTS}`} element={<ControlAssessmentsExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${RISKS}/`} element={<RisksExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${RISKS}/${RISK_REVIEW}`} element={<RiskAssessmentsExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${TPRM}/${THIRD_PARTIES}`} element={<ThirdPartiesExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${TPRM}/${THIRD_PARTIES}/${SERVICES}`} element={<ThirdPartyServicesExport exportsApi={exportApi} />} />
                            <Route path={`/${EXPORTS}/${TPRM}/${THIRD_PARTIES}/${SERVICES}/${ASSESSMENTS}`} element={<ThirdPartyServiceAssessments exportsApi={exportApi} />} />
                            <Route path={`/${GOVERNANCE}`} element={<GovernanceLibrary documentApi={documentApi} controlsApi={controlsApi} governanceApi={governanceApi} navigator={windowNavigator} />} />
                            <Route path={`/${GOVERNANCE}/${CREATE}`} element={<AddGovernanceVersion documentApi={documentApi} controlsApi={controlsApi} governanceApi={governanceApi} />} />
                            <Route path={`/${GOVERNANCE}/:governanceId`} element={<ManageGovernance documentApi={documentApi} controlsApi={controlsApi} governanceApi={governanceApi} navigator={windowNavigator} />} />
                            <Route path={`/${GOVERNANCE}/:governanceId/${CREATE}`} element={<AddGovernanceVersion documentApi={documentApi} controlsApi={controlsApi} governanceApi={governanceApi} />} />
                            {/* See the comment at the top of the route definitions to understand why `key` is used. */}
                            <Route path={`/${ISSUES_EXCEPTIONS}`} element={<ExceptionsIssues key={IssuesExceptionsModule.CONTROLS} type={IssuesExceptionsModule.CONTROLS} documentApi={documentApi} exceptionsApi={exceptionsApi} issuesApi={issuesApi} />} />
                            <Route path={`/${ISSUES}/${CREATE}`} element={<ManageIssue issuesApi={issuesApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            {/* See the comment at the top of the route definitions to understand why `key` is used. */}
                            <Route path={`/${ISSUES}/:issueId`} element={<IssueDetails key={IssuesExceptionsModule.CONTROLS} type={IssuesExceptionsModule.CONTROLS} issuesApi={issuesApi} documentApi={documentApi} />} />
                            <Route path={`/${ISSUES}/:issueId/${CONFIGURATION}`} element={<ManageIssue issuesApi={issuesApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}`} element={<FrameworkDashboard controlsApi={controlsApi} alertsLimitsApi={alertsLimitsApi} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${CONFIGURATION}`} element={<FrameworkSettings controlsApi={controlsApi} alertsLimitsApi={alertsLimitsApi} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${GROUPS}/:controlGroupId`} element={<ControlGroupDetails controlsApi={controlsApi} alertsLimitsApi={alertsLimitsApi} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${GROUPS}/:controlGroupId/${CONFIGURATION}`} element={<ControlGroupSettings controlsApi={controlsApi} alertsLimitsApi={alertsLimitsApi} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${GROUPS}/:controlGroupId/${CONTROLS}/:controlId`} element={<ControlDetails controlsApi={controlsApi} issuesApi={issuesApi} exceptionsApi={exceptionsApi} documentApi={documentApi} alertsLimitsApi={alertsLimitsApi} riskRegisterApi={riskRegisterApi} governanceApi={governanceApi} complianceRequirementsApi={complianceRequirementsApi} tagsApi={tagsApi} authHandler={authHandler} navigator={windowNavigator} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${GROUPS}/:controlGroupId/${CONTROLS}/:controlId/${ASSESSMENTS}`} element={<ConductAssessment controlsApi={controlsApi} complianceRequirementsApi={complianceRequirementsApi} authHandler={authHandler} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${GROUPS}/:controlGroupId/${CONTROLS}/:controlId/${CONFIGURATION}`} element={<ControlSettings controlsApi={controlsApi} alertsLimitsApi={alertsLimitsApi} />} />
                            <Route path={`/${OPERATIONAL_CONTROLS}/${FRAMEWORKS}/:controlFramework/${GROUPS}/:controlGroupId/${CONTROLS}/:controlId/${EVIDENCE}`} element={<UploadControlEvidence controlsApi={controlsApi} documentApi={documentApi} />} />
                            <Route path={`/${RISK_REGISTER}/${CREATE}`} element={<ManageRisk riskRegisterApi={riskRegisterApi} controlsApi={controlsApi} actionsApi={actionsApi} tagsApi={tagsApi} pageType={RiskPageType.CREATE_NEW} />} />
                            <Route path={`/${RISK_REGISTER}/${RISKS}/:risk_id/${CONFIGURATION}`} element={<ManageRisk riskRegisterApi={riskRegisterApi} controlsApi={controlsApi} actionsApi={actionsApi} tagsApi={tagsApi} pageType={RiskPageType.MANAGE} />} />
                            <Route path={`/${RISK_REGISTER}/${RISKS}/:risk_id`} element={<RiskDetails riskRegisterApi={riskRegisterApi} tagsApi={tagsApi} />} />
                            <Route path={`/${RISK_REGISTER}/${RISKS}`} element={<RiskListing riskRegisterApi={riskRegisterApi} tagsApi={tagsApi} />} />
                            <Route path={`/${RISK_REGISTER}/${RISK_REVIEW}`} element={<RiskAssessmentListing riskRegisterApi={riskRegisterApi} />} />
                            <Route path={`/${RISK_REGISTER}/${RISK_REVIEW}/${CREATE}`} element={<CreateRiskAssessment riskRegisterApi={riskRegisterApi} documentApi={documentApi} tagsApi={tagsApi} />} />
                            <Route path={`/${RISK_REGISTER}/${RISK_REVIEW}/:timestamp`} element={<ViewRiskAssessment tagsApi={tagsApi} riskRegisterApi={riskRegisterApi} documentApi={documentApi} />} />
                            <Route path={`/${TPRM}/${EXCEPTIONS}/${CREATE}`} element={<ManageException exceptionsApi={exceptionsApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            {/* See the comment at the top of the route definitions to understand why `key` is used. */}
                            <Route path={`/${TPRM}/${EXCEPTIONS}/:exceptionId`} element={<ExceptionDetails key={IssuesExceptionsModule.TPRM} type={IssuesExceptionsModule.TPRM} exceptionsApi={exceptionsApi} documentApi={documentApi} tprmApi={tprmApi} />} />
                            <Route path={`/${TPRM}/${EXCEPTIONS}/:exceptionId/${CONFIGURATION}`} element={<ManageException exceptionsApi={exceptionsApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            {/* See the comment at the top of the route definitions to understand why `key` is used. */}
                            <Route path={`/${TPRM}/${ISSUES_EXCEPTIONS}`} element={<ExceptionsIssues key={IssuesExceptionsModule.TPRM} type={IssuesExceptionsModule.TPRM} tprmApi={tprmApi} issuesApi={issuesApi} exceptionsApi={exceptionsApi} documentApi={documentApi} />} />
                            <Route path={`/${TPRM}/${ISSUES}/${CREATE}`} element={<ManageIssue issuesApi={issuesApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            {/* See the comment at the top of the route definitions to understand why `key` is used. */}
                            <Route path={`/${TPRM}/${ISSUES}/:issueId`} element={<IssueDetails key={IssuesExceptionsModule.TPRM} type={IssuesExceptionsModule.TPRM} issuesApi={issuesApi} documentApi={documentApi} tprmApi={tprmApi} />} />
                            <Route path={`/${TPRM}/${ISSUES}/:issueId/${CONFIGURATION}`} element={<ManageIssue issuesApi={issuesApi} documentApi={documentApi} controlsApi={controlsApi} tprmApi={tprmApi} />} />
                            <Route path={`/${TPRM}/${SERVICES}`} element={<ManageTPRMServices tprmApi={tprmApi} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}`} element={<ManageTPRMThirdParties tprmApi={tprmApi} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${FOLDERS}`} element={<FolderListing tprmApi={tprmApi} documentApi={documentApi} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${FOLDERS}/${CONFIGURATION}`} element={<ManageFolders tprmApi={tprmApi} documentApi={documentApi} pageType={TprmFolderPageType.CREATE_NEW} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${FOLDERS}/:folder_id`} element={<FolderDetails tprmApi={tprmApi} documentApi={documentApi} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${FOLDERS}/:folder_id/${VERSIONS}`} element={<ManageFolders tprmApi={tprmApi} documentApi={documentApi} pageType={TprmFolderPageType.ADD_VERSION} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${FOLDERS}/:folder_id/${CONFIGURATION}`} element={<ManageFolders tprmApi={tprmApi} documentApi={documentApi} pageType={TprmFolderPageType.MANAGE} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${ASSESSMENTS}`} element={<ServiceControlAssessment tprmApi={tprmApi} documentApi={documentApi} exceptionsApi={exceptionsApi} externalIntegrationsApi={externalIntegrationsApi} issuesApi={issuesApi} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${DASHBOARDS}`} element={<ServiceDashboard tprmApi={tprmApi} issuesApi={issuesApi} documentApi={documentApi} exceptionsApi={exceptionsApi} externalIntegrationsApi={externalIntegrationsApi} clientDetailsApi={clientDetailsApi} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${DDQ}`} element={<DueDiligenceQuestionnaire ddqApi={tprmApi} documentApi={documentApi} scroller={windowScroller} />} />
                            <Route path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${IRQ}`} element={<InherentRiskQuestionnaire tprmApi={tprmApi} documentApi={documentApi} navigator={windowNavigator} />} />
                            <Route
                                path={`/${TPRM}/${THIRD_PARTIES}/:third_party_id/${SERVICES}/:service_id/${REPORTS}/:timestamp`}
                                element={
                                    <Suspense fallback={<Placeholder />}>
                                        <ServiceReportLazyLoaded tprmApi={tprmApi} clientDetailsApi={clientDetailsApi} issuesApi={issuesApi} />
                                    </Suspense>
                                }
                            />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </ErrorBoundary>
                </TimerNavigation>
            </NavProvider>
        </UsersProvider>
    );
};

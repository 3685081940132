import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { useUsers } from 'Components/Context/UsersContext';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserMultiSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { Text } from 'Components/Text/Text';
import { IRQ, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { validateEmail } from 'Helpers/InputValidation';
import { ValidationError } from 'Models/ErrorTypes';
import { SaveThirdPartyServiceRequest, Service, ServiceAssessmentState, ThirdPartyContact, ThirdPartyResponse, ThirdPartyResponseWithServices } from 'Models/TPRM';
import { OptionType } from 'Models/Types/GlobalType';
import { UserResponse } from 'Models/User';

import styles from './SaveTPRMThirdPartyServiceModal.module.css';

export interface SaveTPRMThirdPartyServiceModalProps {
    defaultThirdParty?: ThirdPartyResponseWithServices;
    hideModal: () => void;
    tprmApi: TPRMApi;
    users: UserResponse[];
    thirdParties?: ThirdPartyResponse[];
    thirdPartyService?: Service;
    thirdPartyServiceSaved: () => void;
}

interface FormFieldsState {
    thirdPartyId?: string;
    name?: string;
    description?: string;
    thirdPartyServiceManagerUserId?: string;
    delegates?: UserResponse[];
    third_party_contacts: ThirdPartyContact[];
    responsibleOrganization?: string;
}

export const SaveTPRMThirdPartyServiceModal = (props: SaveTPRMThirdPartyServiceModalProps): JSX.Element => {
    const { users } = useUsers();

    const [successMessage, setSuccessMessage] = useState<JSX.Element | string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [thirdPartyServiceManager, setThirdPartyServiceManager] = useState<UserResponse>(); // Keeps track of the currently selected User in the Third-Party Service Manager drop-down menu.
    const [formFieldsState, setFormFieldsState] = useState<FormFieldsState>({
        thirdPartyId: props.defaultThirdParty?.id || props.thirdPartyService?.vendor_id,
        name: props.thirdPartyService?.name,
        description: props.thirdPartyService?.description,
        thirdPartyServiceManagerUserId: props.thirdPartyService?.vendor_service_manager_user_id,
        delegates: props.thirdPartyService?.delegates ? props.users.filter((user) => props.thirdPartyService?.delegates?.includes(user.cognito_subject)) : undefined,
        third_party_contacts: props.thirdPartyService?.vendor_contacts || [],
        responsibleOrganization: props.thirdPartyService?.responsible_organization,
    });

    useEffect(() => {
        const getSelectedUser = async (): Promise<void> => {
            const thirdPartyServiceManager = users.find((user) => user.cognito_subject === props.thirdPartyService?.vendor_service_manager_user_id);
            setThirdPartyServiceManager(thirdPartyServiceManager);
        };
        getSelectedUser();
    }, [props.thirdPartyService?.vendor_service_manager_user_id, users]);

    const createThirdPartyOptions = (): OptionType[] => {
        const thirdPartyOptions: OptionType[] = [];
        props.thirdParties?.forEach((thirdParty) => {
            thirdPartyOptions.push({
                label: thirdParty.name,
                value: thirdParty.id,
            });
        });
        return thirdPartyOptions;
    };

    const saveThirdPartyService = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        setIsSubmitting(true);
        try {
            validateForm();
            const serviceResponse = await props.tprmApi.saveThirdPartyService(buildRequest(), formFieldsState.thirdPartyId!, props.thirdPartyService?.id);
            if (props.thirdPartyService !== undefined) {
                setSuccessMessage('Third-party service updated.');
            } else {
                setSuccessMessage(
                    <>
                        Third-party service created. The next step is to <Link to={`/${TPRM}/${THIRD_PARTIES}/${serviceResponse.data.vendor_id}/${SERVICES}/${serviceResponse.data.id}/${IRQ}`}>start the Inherent Risk Questionnaire</Link>.
                    </>
                );
            }
            props.thirdPartyServiceSaved();
        } catch (err) {
            handleRequestError(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const buildRequest = (): SaveThirdPartyServiceRequest => {
        const request: SaveThirdPartyServiceRequest = {
            name: formFieldsState.name!,
            description: formFieldsState.description!,
            vendor_service_manager_user_id: formFieldsState.thirdPartyServiceManagerUserId!,
            delegates: formFieldsState.delegates?.map((user) => user.cognito_subject),
            vendor_contacts: cloneDeep(formFieldsState.third_party_contacts), // cloneDeep() is used so that we can set empty strings to undefined in the next step without modifying the original state, which would throw a React error about switching from controlled to uncontrolled components.
            responsible_organization: formFieldsState.responsibleOrganization,
        };

        // Discard any Third-Party contacts where all attributes are "empty."
        // This is necessary because the API will save empty Third-Party contacts to the database (as {}) and then empty Third-Party contact "rows" will persist in the UI.
        request.vendor_contacts = request.vendor_contacts?.filter((thirdPartyContact) => {
            return Object.values(thirdPartyContact).some((value) => {
                if (typeof value === 'string' && value.trim().length === 0) {
                    return false;
                }
                return true;
            });
        });

        // Set any attributes of Third-Party contacts that are "empty" strings to undefined before submitting the request.
        // This is necessary because the API will return an error if email_address is an empty string. The other attributes are included simply for completeness.
        request.vendor_contacts?.forEach((thirdPartyContact) => {
            if ((thirdPartyContact.email_address?.trim().length ?? 0) === 0) {
                thirdPartyContact.email_address = undefined;
            }
            if ((thirdPartyContact.name?.trim().length ?? 0) === 0) {
                thirdPartyContact.name = undefined;
            }
            if ((thirdPartyContact.phone_number?.trim().length ?? 0) === 0) {
                thirdPartyContact.phone_number = undefined;
            }
            if ((thirdPartyContact.additional_information?.trim().length ?? 0) === 0) {
                thirdPartyContact.additional_information = undefined;
            }
        });

        return request;
    };

    const validateForm = (): void => {
        if (!formFieldsState.thirdPartyId) {
            throw new ValidationError('A service must be assigned to a third party.');
        }
        if (!formFieldsState.name) {
            throw new ValidationError('Service name is required.');
        }
        if (!formFieldsState.thirdPartyServiceManagerUserId) {
            throw new ValidationError('Service manager is required.');
        }
        if (!formFieldsState.description) {
            throw new ValidationError('Service description is required.');
        }
        formFieldsState.third_party_contacts.forEach((third_party_contact) => {
            if (third_party_contact.email_address && !validateEmail(third_party_contact.email_address)) {
                throw new ValidationError('Invalid email address.');
            }
        });
    };

    /** Handles error responses from the API. */
    const handleRequestError = (error: Error): void => {
        setFailureMessage(error.message);
        setSuccessMessage(undefined);
    };

    /** Handles changes made to general text fields. */
    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        setFormFieldsState({ ...formFieldsState, [event.currentTarget.name]: event.currentTarget.value });
    };

    /** Handles changes made to general drop-down (select) fields. */
    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: value });
    };

    /** Handles changes made to the Third-Party Service Manager drop-down (select) field. */
    const handleSelectUserChange = (user: UserResponse, formFieldId: string): void => {
        setThirdPartyServiceManager(user);
        setFormFieldsState({ ...formFieldsState, [formFieldId]: user.cognito_subject });
    };

    /** Handles changes made to the Third-Party Service Delegates drop-down (select) field. */
    const handleSelectUsersChange = (users: UserResponse[] | undefined, formFieldId: string): void => {
        setFormFieldsState({ ...formFieldsState, [formFieldId]: users });
    };

    /** Adds the fields (empty) for a new Third-Party contact. */
    const handleAddThirdPartyContact = (): void => {
        const thirdPartyContacts = [...formFieldsState.third_party_contacts];
        thirdPartyContacts.push({ name: '', email_address: '', phone_number: '', additional_information: '' });
        setFormFieldsState({ ...formFieldsState, third_party_contacts: thirdPartyContacts });
    };

    /** Deletes an existing Third-Party contact. */
    const handleDeleteThirdPartyContact = (index: number): void => {
        const thirdPartyContacts = [...formFieldsState.third_party_contacts];
        thirdPartyContacts.splice(index, 1);
        setFormFieldsState({ ...formFieldsState, third_party_contacts: thirdPartyContacts });
    };

    /** Handles changes made to an existing Third-Party contact. */
    const handleThirdPartyContactChange = (index: number, attribute: 'name' | 'email_address' | 'phone_number' | 'additional_information', value: string): void => {
        const thirdPartyContacts = [...formFieldsState.third_party_contacts];
        thirdPartyContacts[index] = {
            ...thirdPartyContacts[index],
            [attribute]: value,
        };
        setFormFieldsState({ ...formFieldsState, third_party_contacts: thirdPartyContacts });
    };

    const isArchiving = props.thirdPartyService?.assessment_state === ServiceAssessmentState.ARCHIVING;

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                {isArchiving && <Alert variant="warning">This service is being archived and changes cannot be made.</Alert>}
                <Form noValidate onSubmit={saveThirdPartyService}>
                    {props.thirdPartyService ? <Text variant="Header2">Update Third-Party Service</Text> : <Text variant="Header2">Create Third-Party Service</Text>}
                    <div className={styles.formFieldGroup}>
                        <div className={styles.formFieldContainer}>
                            <FormFieldSelect options={createThirdPartyOptions()} handleChange={handleSelectChange} formFieldId="thirdPartyId" formFieldLabel="Third-Party Name" isRequiredField selectedOption={formFieldsState.thirdPartyId} disabled={props.thirdPartyService !== undefined} />
                        </div>
                        <div className={styles.formFieldContainer}>
                            <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={handleChange} formFieldId="name" formFieldLabel="Third-Party Service Name" required={true} tooltip="The name of the service that the selected third party is providing." invalidMessage="Please enter a name for the service." value={formFieldsState.name || ''} />
                        </div>
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={handleChange} formFieldId="responsibleOrganization" formFieldLabel="Organization Responsible for Third-Party Relationship" tooltip="The internal organization or department responsible for managing the relationship with the third party for this service." invalidMessage="Please enter a valid organization." value={formFieldsState.responsibleOrganization || ''} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldUserSelect disabled={isArchiving} users={users ? users : []} onUserSelected={handleSelectUserChange} formFieldId="thirdPartyServiceManagerUserId" selectedUser={thirdPartyServiceManager} formFieldLabel="Third-Party Service Manager" tooltip={'The internal user responsible for continuous oversight of the service provided by the third party.'} isRequiredField />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldUserMultiSelect disabled={isArchiving} users={props.users} onUsersSelected={handleSelectUsersChange} formFieldId="delegates" selectedUsers={formFieldsState.delegates} formFieldLabel="Delegates" tooltip={'An optional list of additional internal users to receive notifications about the service.'} />
                    </div>
                    <div className={styles.formFieldContainer}>
                        <FormFieldTextArea disabled={isArchiving} handleChange={handleChange} formFieldId="description" formFieldLabel="Third-Party Service Description" rows={5} required invalidMessage="Please enter a valid description." tooltip="A description of the service that the third party is providing." value={formFieldsState.description || ''} />
                    </div>
                    <Text variant="Header3" color="darkGray">
                        Third-Party Service Contact Information
                    </Text>
                    {formFieldsState.third_party_contacts.map((thirdPartyContact, index) => (
                        <Fragment key={index}>
                            <div className={styles.formFieldGroup}>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'name', event.currentTarget.value)} formFieldId={`thirdPartyContactName${index}`} formFieldLabel={`Name`} tooltip="The name of the contact at this third party for this service." value={thirdPartyContact.name || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText disabled={isArchiving} formFieldType="email" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'email_address', event.currentTarget.value)} formFieldId={`thirdPartyContactEmailAddress${index}`} formFieldLabel={`Email Address`} tooltip="The email address of the contact at this third party for this service." value={thirdPartyContact.email_address || ''} />
                                </div>
                                <div className={styles.formFieldContainer}>
                                    <FormFieldText disabled={isArchiving} formFieldType="text" handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'phone_number', event.currentTarget.value)} formFieldId={`thirdPartyContactPhoneNumber${index}`} formFieldLabel={`Phone Number`} tooltip="The phone number of the contact at this third party for this service." value={thirdPartyContact.phone_number || ''} />
                                </div>
                                <div className={styles.trashIconContainer}>
                                    <IconButton aria-label={`delete third-party contact ${index}`} onClick={() => handleDeleteThirdPartyContact(index)} fontAwesomeImage={faTrash} />
                                </div>
                            </div>
                            <div className={styles.formFieldContainer}>
                                <FormFieldTextArea handleChange={(event: React.ChangeEvent<HTMLInputElement>) => handleThirdPartyContactChange(index, 'additional_information', event.currentTarget.value)} formFieldId={`thirdPartyContactAdditionalInformation${index}`} formFieldLabel={`Additional Information`} tooltip="Any additional information for the contact at this third party for this service." value={thirdPartyContact.additional_information || ''} />
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.formFieldContainer}>
                        <Button variant="linkText" size="lg" onClick={handleAddThirdPartyContact}>
                            {'+ Add Contact'}
                        </Button>
                    </div>
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes} disabled={isSubmitting}>
                            CLOSE
                        </Button>
                        <Button variant="submit" disabled={isSubmitting || isArchiving} isLoading={isSubmitting} loadingText="Saving...">
                            SAVE
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

import { useEffect, useState } from 'react';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { ExceptionsListing, ExceptionsListingProps } from 'Components/Exceptions/ExceptionsListing/ExceptionsListing';
import { AssociatedControlsModal } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { ControlExceptionResponse, ExceptionResponse, ThirdPartyExceptionResponse } from 'Models/Exceptions';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

export interface ExceptionsTabOperationalControlsProps {
    exceptionsType: IssuesExceptionsModule.CONTROLS;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    users: UserResponse[];
}

export interface ExceptionsTabTprmProps {
    exceptionsType: IssuesExceptionsModule.TPRM;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    users: UserResponse[];
    thirdParties: ThirdPartyResponseWithServices[];
    thirdPartyIdQueryParam?: string;
}

export type ExceptionsTabProps = ExceptionsTabOperationalControlsProps | ExceptionsTabTprmProps;

/**
 * Renders a table of exceptions after fetching them via the API.
 */
export const ExceptionsTab = (props: ExceptionsTabProps) => {
    const [exceptions, setExceptions] = useState<ExceptionResponse[]>();
    const [mappedControlsToDisplay, setMappedControlsToDisplay] = useState<OperationalControl[]>();
    const [loadingErrorOccurred, setLoadingErrorOccurred] = useState(false);

    useEffect(() => {
        const getExceptions = async (): Promise<void> => {
            try {
                const response = props.exceptionsType === IssuesExceptionsModule.CONTROLS ? await props.exceptionsApi.getAllControlExceptions() : await props.exceptionsApi.getAllThirdPartyExceptions();
                setExceptions(response.data);
            } catch (error) {
                setLoadingErrorOccurred(true);
            }
        };

        getExceptions();
    }, [props.exceptionsApi, props.exceptionsType]);

    if (loadingErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    } else if (!exceptions) {
        return <Placeholder />;
    }

    const exceptionsListingProps: ExceptionsListingProps = {
        users: props.users,
        ...(props.exceptionsType === IssuesExceptionsModule.CONTROLS
            ? {
                  type: IssuesExceptionsModule.CONTROLS,
                  exceptions: exceptions as ControlExceptionResponse[],
                  displayMappedControlsModal: setMappedControlsToDisplay,
              }
            : {
                  type: IssuesExceptionsModule.TPRM,
                  exceptions: exceptions as ThirdPartyExceptionResponse[],
                  thirdParties: props.thirdParties,
                  preselectedThirdPartyIdForFilter: props.thirdPartyIdQueryParam,
              }),
        hideTitle: true,
    };

    return (
        <>
            {mappedControlsToDisplay && <AssociatedControlsModal associatedControls={mappedControlsToDisplay} hideModal={() => setMappedControlsToDisplay(undefined)} />}
            <ExceptionsListing {...exceptionsListingProps} />
        </>
    );
};

import { cloneDeep } from 'lodash-es';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { ExternalIntegrationsApi } from 'Api/ExternalIntegrations/ExternalIntegrationsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button, Link } from 'Components/Buttons/Buttons';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { ExceptionsListing, ExceptionsListingProps } from 'Components/Exceptions/ExceptionsListing/ExceptionsListing';
import { ControlBarData, ControlBarGraph } from 'Components/Graphs/ControlBarGraph';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { IndicatorVariant } from 'Components/Indicator/Indicator';
import { ProgressBarIndicator, ProgressBarIndicatorProps } from 'Components/Indicator/ProgressBarIndicator';
import { IssuesListing, IssuesListingProps } from 'Components/Issues/IssuesListing/IssuesListing';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { DDQ, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { iso8601ToUsDateLong, iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { useThirdPartyMonitoringMetricValues } from 'Hooks/ExternalIntegrations';
import { ClientDetailsModel } from 'Models/ClientDetailsModel';
import { ExceptionResponse, ThirdPartyExceptionResponse } from 'Models/Exceptions';
import { IssueResponse, IssuesExceptionsModule, ThirdPartyIssueResponse } from 'Models/Issues';
import { ControlEffectivenessProgressBarVariantAndPercentage, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { NumberAsRiskScore, QuestionnaireAggregationResponse, RiskRating, RiskRatingAsString, Service, ThirdPartyContact, ThirdPartyResponse, getRiskRatingVariantColor, numberAsRiskRatingString, prettyPrintServiceAssessmentState } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import { ConfirmResetAndNotifyThirdPartyModal, ConfirmResetAndNotifyThirdPartyModalProps } from './ConfirmResetAndNotifyThirdPartyModal/ConfirmResetAndNotifyThirdPartyModal';
import { RiskWorkflow, RiskWorkflowProps } from './RiskWorkflow/RiskWorkflow';
import styles from './ServiceDashboard.module.css';
import { ThirdPartyLocation, ThirdPartyLocationProps } from './ThirdPartyLocation/ThirdPartyLocation';
import { CommonAssessment } from '../Components/CommonAssessment/CommonAssessment';
import { CommonAssessmentSelectionModal, CommonAssessmentSelectionModalProps } from '../Components/CommonAssessmentSelectionModal/CommonAssessmentSelectionModal';
import { ConfirmUnlinkServicesModal, ConfirmUnlinkServicesModalProps } from '../Components/ConfirmUnlinkServicesModal/ConfirmUnlinkServicesModal';
import { ThirdPartyContactModal } from '../Components/ThirdPartyContactModal/ThirdPartyContactModal';
import { ThirdPartyMonitoring } from '../Components/ThirdPartyMonitoring/ThirdPartyMonitoring';

export enum PageElements {
    ConfirmNotificationEmail,
    CommonAssessmentSelectionModal,
    UnlinkCommonAssessmentModal,
    ThirdPartyContactModal,
    ThirdPartyServiceContactModal,
    None,
}

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}

export interface ServiceDashboardRouteState {
    showConfirmNotificationEmailModal?: boolean;
}

export interface ServiceDashboardProps {
    tprmApi: TPRMApi;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    externalIntegrationsApi: ExternalIntegrationsApi;
    clientDetailsApi: ClientDetailsApi;
}

interface UIState {
    thirdPartyId: string;
    serviceId: string;
    thirdPartyServiceTitle: string;
    assessmentStateHeaderText: string;
    assessmentDueDateHeaderText: string;
    inherentRisk: string;
    inhereRiskVariant: IndicatorVariant;
    controlEffectivenessProgressBar?: ProgressBarIndicatorProps;
    controlEffectiveness?: string;
    residualRisk?: string;
    residualRiskVariant?: IndicatorVariant;
    description: string;
    location: ThirdPartyLocationProps;
    ddqPath: string;
    clientName: string;
    thirdPartyName: string;
    thirdPartyManager: string;
    serviceManager: string;
    riskWorkflowStep: number;
    irqStatus: string;
    irqPercentComplete: number;
    irqDateCompleted?: string;
    ddqStatus: string;
    ddqStatusLabel?: string;
    ddqPercentComplete: number;
    ddqDateStarted?: string;
    hasParent: boolean;
    assessmentStatus: string;
    assessmentStatusLabel?: string;
    assessmentPercentComplete: number;
    assessmentDateStarted?: string;
    reportPercentComplete: number;
    reportStatus: string;
    reportDateCompleted?: string;
    effectivenessSummary: ControlBarData;
    issues: ThirdPartyIssueResponse[];
    exceptions: ThirdPartyExceptionResponse[];
}

export const ServiceDashboard = (props: ServiceDashboardProps): JSX.Element => {
    const [serviceResponse, setServiceResponse] = useState<Service>();
    const [thirdPartyResponse, setThirdPartyResponse] = useState<ThirdPartyResponse>();
    const [clientDetailsResponse, setClientDetailsResponse] = useState<ClientDetailsModel>();
    const [questionnaireAggregationResponse, setQuestionnaireAggregationResponse] = useState<QuestionnaireAggregationResponse>();
    const [uiState, setUIState] = useState<UIState>();
    const { users } = useUsers();
    const [tprmAccessDenied, setTrpmAccessDenied] = useState<boolean>();
    const [zeroStateText, setZeroStateText] = useState<string>();
    const [displayedElement, setDisplayedElement] = useState<PageElements>(PageElements.None);
    const [selectedItem, setSelectedItem] = useState<IssueResponse | ExceptionResponse | Service>();
    const [questionnaireAggregationIsLoaded, setQuestionnaireAggregationIsLoaded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { service_id: serviceIdUrlParam, third_party_id: thirdPartyIdUrlParam } = useParams<keyof UrlParams>() as UrlParams;
    const [thirdPartyMonitoringMetricValues, thirdPartyMonitoringMetricValuesError] = useThirdPartyMonitoringMetricValues(thirdPartyIdUrlParam, props.externalIntegrationsApi);

    const getServiceDetails = useCallback(async (): Promise<void> => {
        try {
            const detailedServiceResponse = await props.tprmApi.getServiceDetails(thirdPartyIdUrlParam, serviceIdUrlParam);
            setServiceResponse(detailedServiceResponse.data);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTrpmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [serviceIdUrlParam, thirdPartyIdUrlParam, props.tprmApi]);

    const getThirdPartyDetails = useCallback(async (): Promise<void> => {
        try {
            const detailedThirdPartyResponse = await props.tprmApi.getThirdPartyDetails(thirdPartyIdUrlParam);
            setThirdPartyResponse(detailedThirdPartyResponse.data);
        } catch (error) {
            if (isForbiddenResponseError(error)) {
                setTrpmAccessDenied(true);
            } else {
                handleRequestError(error);
            }
        }
    }, [props.tprmApi, thirdPartyIdUrlParam]);

    /**
     * Initial network calls
     */
    useEffect(() => {
        const getClientDetails = async (): Promise<void> => {
            try {
                const clientDetailsResponse = await props.clientDetailsApi.getClientDetails();
                setClientDetailsResponse(clientDetailsResponse.data);
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTrpmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            }
        };

        getThirdPartyDetails();
        getServiceDetails();
        getClientDetails();
    }, [getServiceDetails, props.clientDetailsApi, serviceIdUrlParam, thirdPartyIdUrlParam, props.tprmApi, getThirdPartyDetails]);

    useEffect(() => {
        if (serviceResponse && serviceResponse.id !== serviceIdUrlParam) {
            /**
             * Reset state when service id params change.
             * This prop changes when a user clicks a link that redirects them to a different service dashboard.
             * React Router does not fully re-initialize the component. i.e. state is not re-initialized.
             * Resetting the values causes the placeholder animation to show while network calls are being made and state is being updated.
             * The client details and associated third party don't change when navigating between linked services. If a user navigates to a service associated with a different third party, the component is re-initialized.
             */
            setServiceResponse(undefined);
            setUIState(undefined);
            setQuestionnaireAggregationIsLoaded(false);
        }

        // Show confirm notification email modal when contact third-party service button clicked from a child service.
        if (location.state && location.state.showConfirmNotificationEmailModal) {
            // Prevent showing the modal every time the page is reloaded.
            navigate(location.pathname, { replace: true });

            setDisplayedElement(PageElements.ConfirmNotificationEmail);
        }
    }, [location.pathname, location.state, navigate, serviceIdUrlParam, serviceResponse]);

    /**
     * Get questionnaire aggregation whenever service details are retrieved.
     * If a service has a common assessment parent, the parent's aggregation is retrieved.
     */
    useEffect(() => {
        const getQuestionnaireAggregation = async (): Promise<void> => {
            if (!serviceResponse) {
                return;
            }

            try {
                const thirdPartyId = serviceResponse.common_assessment_parent?.vendor_id ?? serviceResponse.vendor_id;
                const serviceId = serviceResponse.common_assessment_parent?.id ?? serviceResponse.id;

                const questionnaireAggregationResponse = await props.tprmApi.getQuestionnaireAggregation(thirdPartyId, serviceId);
                if (questionnaireAggregationResponse && 'data' in questionnaireAggregationResponse) {
                    setQuestionnaireAggregationResponse(questionnaireAggregationResponse.data);
                }
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTrpmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            } finally {
                setQuestionnaireAggregationIsLoaded(true);
            }
        };

        getQuestionnaireAggregation();
    }, [serviceResponse, props.tprmApi]);

    /**
     * Set up UI State when network responses are updated
     */
    useEffect(() => {
        const getRiskWorkflowStep = (serviceResponse: Service, questionnaireAggregationResponse?: QuestionnaireAggregationResponse): number => {
            if (serviceResponse.assessment_completed_time) {
                return 4;
            } else if (questionnaireAggregationResponse && questionnaireAggregationResponse.number_of_controls === questionnaireAggregationResponse.number_of_control_assessments_completed) {
                return 3;
            } else if (questionnaireAggregationResponse && questionnaireAggregationResponse.number_of_questions === questionnaireAggregationResponse.number_of_questions_completed) {
                return 2;
            } else if (serviceResponse.irq_completed_time) {
                return 1;
            } else {
                return 0;
            }
        };

        const getEffectivenessSummary = (questionnaireAggregationResponse?: QuestionnaireAggregationResponse): ControlBarData => {
            return {
                inactive: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['0'] : 0,
                fail: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['1'] : 0,
                weak: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['2'] : 0,
                moderate: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['3'] : 0,
                strong: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['4'] : 0,
                robust: questionnaireAggregationResponse ? questionnaireAggregationResponse?.control_effectiveness_summary['5'] : 0,
            };
        };

        const buildUiState = (serviceResponse: Service, thirdPartyResponse: ThirdPartyResponse, users: UserResponse[], clientDetailsResponse: ClientDetailsModel, questionnaireAggregationResponse?: QuestionnaireAggregationResponse) => {
            const reportStatusText = ((): string => {
                if (serviceResponse.assessment_additional_information !== undefined || serviceResponse.assessment_control_effectiveness !== undefined || serviceResponse.assessment_documents.length > 0 || serviceResponse.assessment_residual_risk_score !== undefined) return 'In Progress';

                return 'Not Started';
            })();

            const uiState: UIState = {
                thirdPartyId: thirdPartyResponse.id,
                serviceId: serviceResponse.id,
                thirdPartyServiceTitle: `${serviceResponse.vendor_name} - ${serviceResponse.name}`,
                assessmentStateHeaderText: prettyPrintServiceAssessmentState(serviceResponse.assessment_state),
                assessmentDueDateHeaderText: serviceResponse.assessment_due_date ? iso8601ToUsDateLong(serviceResponse.assessment_due_date) : 'Not Set',
                inherentRisk: numberAsRiskRatingString(serviceResponse.inherent_risk_score),
                inhereRiskVariant: getRiskRatingVariantColor(NumberAsRiskScore(serviceResponse.inherent_risk_score)),
                controlEffectivenessProgressBar: serviceResponse.assessment_control_effectiveness
                    ? {
                          ...ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(serviceResponse.assessment_control_effectiveness)),
                          size: 'large',
                      }
                    : undefined,
                controlEffectiveness: serviceResponse.assessment_control_effectiveness ? numberAsEffectivenessString(serviceResponse.assessment_control_effectiveness) : undefined,
                residualRisk: serviceResponse.assessment_residual_risk_score ? numberAsRiskRatingString(serviceResponse.assessment_residual_risk_score) : undefined,
                residualRiskVariant: serviceResponse.assessment_residual_risk_score ? getRiskRatingVariantColor(NumberAsRiskScore(serviceResponse.assessment_residual_risk_score)) : undefined,
                description: serviceResponse.description,
                location: thirdPartyResponse,
                ddqPath: serviceResponse.common_assessment_parent ? `/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.common_assessment_parent.id}/${DDQ}` : `/${TPRM}/${THIRD_PARTIES}/${thirdPartyResponse.id}/${SERVICES}/${serviceResponse.id}/${DDQ}`,
                clientName: clientDetailsResponse?.client_name,
                thirdPartyName: thirdPartyResponse.name,
                thirdPartyManager: getUserNameFromSubject(thirdPartyResponse.vendor_manager_user_id, users, UserNameFormat.FIRST_SPACE_LAST)!,
                serviceManager: getUserNameFromSubject(serviceResponse.vendor_service_manager_user_id, users, UserNameFormat.FIRST_SPACE_LAST)!,
                riskWorkflowStep: getRiskWorkflowStep(serviceResponse, questionnaireAggregationResponse),
                irqStatus: serviceResponse.irq_completed_time ? 'Complete' : 'Not Started',
                irqPercentComplete: serviceResponse.irq_completed_time ? 100 : 0,
                irqDateCompleted: serviceResponse.irq_completed_time ? iso8601ToUsDateShort(serviceResponse.irq_completed_time) : undefined,
                ddqStatus: questionnaireAggregationResponse ? `${questionnaireAggregationResponse.number_of_questions_completed}/${questionnaireAggregationResponse.number_of_questions}` : 'Not Started',
                ddqStatusLabel: questionnaireAggregationResponse ? 'Questions Answered' : undefined,
                ddqPercentComplete: questionnaireAggregationResponse ? (questionnaireAggregationResponse.number_of_questions_completed / questionnaireAggregationResponse.number_of_questions) * 100 : 0,
                ddqDateStarted: questionnaireAggregationResponse?.due_diligence_started_time ? iso8601ToUsDateShort(questionnaireAggregationResponse.due_diligence_started_time) : undefined,
                hasParent: serviceResponse.common_assessment_parent ? true : false,
                assessmentStatus: questionnaireAggregationResponse ? `${questionnaireAggregationResponse.number_of_control_assessments_completed}/${questionnaireAggregationResponse.number_of_controls}` : 'Not Started',
                assessmentStatusLabel: questionnaireAggregationResponse ? 'Controls Assessed' : undefined,
                assessmentPercentComplete: questionnaireAggregationResponse ? (questionnaireAggregationResponse.number_of_control_assessments_completed / questionnaireAggregationResponse.number_of_controls) * 100 : 0,
                assessmentDateStarted: questionnaireAggregationResponse?.control_assessment_started_time ? iso8601ToUsDateShort(questionnaireAggregationResponse.control_assessment_started_time) : undefined,
                reportPercentComplete: serviceResponse.assessment_completed_time ? 100 : 0,
                reportStatus: reportStatusText,
                reportDateCompleted: serviceResponse.assessment_completed_time ? iso8601ToUsDateShort(serviceResponse.assessment_completed_time) : undefined,
                effectivenessSummary: getEffectivenessSummary(questionnaireAggregationResponse),
                issues: thirdPartyResponse.issues,
                exceptions: thirdPartyResponse.exceptions,
            };
            setUIState(uiState);
        };
        if (serviceResponse && thirdPartyResponse && users && clientDetailsResponse) {
            buildUiState(serviceResponse, thirdPartyResponse, users, clientDetailsResponse, questionnaireAggregationResponse);
        }
    }, [serviceResponse, thirdPartyResponse, questionnaireAggregationResponse, users, clientDetailsResponse]);

    const handleRequestError = (error: Error): void => setZeroStateText(error.message);

    const hideModal = () => {
        setDisplayedElement(PageElements.None);
        setSelectedItem(undefined);
    };

    const issuesListingProps: IssuesListingProps = {
        type: IssuesExceptionsModule.TPRM,
        issues: uiState?.issues ? uiState.issues : [],
        users: users,
        colorTheme: 'dark',
        disableThirdPartyFilter: true,
        preselectedThirdPartyIdForCreate: thirdPartyResponse?.id,
        preselectedThirdPartyIdForFilter: thirdPartyResponse?.id,
        thirdParties: thirdPartyResponse ? [thirdPartyResponse] : [],
    };

    const exceptionsListingProps: ExceptionsListingProps = {
        type: IssuesExceptionsModule.TPRM,
        exceptions: uiState?.exceptions ? uiState.exceptions : [],
        users: users,
        colorTheme: 'dark',
        disableThirdPartyFilter: true,
        preselectedThirdPartyIdForCreate: thirdPartyResponse?.id,
        preselectedThirdPartyIdForFilter: thirdPartyResponse?.id,
        thirdParties: thirdPartyResponse ? [thirdPartyResponse] : [],
    };

    const commonAssessmentSelectionModalProps = (service: Service): CommonAssessmentSelectionModalProps => {
        return {
            tprmApi: props.tprmApi,
            service: service,
            hideModal: hideModal,
            onComplete: getServiceDetails,
        };
    };

    const confirmUnlinkServicesModalProps = (service: Service): ConfirmUnlinkServicesModalProps => {
        return {
            tprmApi: props.tprmApi,
            service: service,
            hideModal: hideModal,
            onComplete: getServiceDetails,
        };
    };

    const displayConfirmationEmailModal = () => setDisplayedElement(PageElements.ConfirmNotificationEmail);

    const displayControlsClicked = (item: string) => {
        return;
    };

    if (tprmAccessDenied) {
        return <Text>{UNAUTHORIZED_MESSAGE}</Text>;
    }
    if (zeroStateText) {
        return <Text>{zeroStateText}</Text>;
    }

    /**
     * Renders the contents of the Third Party/Third-Party Service information widget.
     */
    const renderThirdPartyandThirdPartyServiceInformationWidget = (thirdParty: ThirdPartyResponse, thirdPartyService: Service): JSX.Element => {
        /**
         * Determine which Third-Party contact information to display, based on how many contacts there are and what information is available.
         */
        const determineThirdPartyContactInformationToDisplay = (modalToOpen: PageElements.ThirdPartyContactModal | PageElements.ThirdPartyServiceContactModal, thirdPartyContacts: ThirdPartyContact[]): JSX.Element | JSX.Element[] => {
            // If there are no Third-Party contacts, display a dash.
            if (thirdPartyContacts.length === 0) {
                return (
                    <Text variant="Text2" color="white">
                        -
                    </Text>
                );
            }

            // If there are is one Third-Party contact, display all of its available contact information.
            else if (thirdPartyContacts.length === 1) {
                const contactInfo = [];
                if (thirdPartyContacts[0].name) {
                    contactInfo.push(
                        <Fragment key={thirdPartyContacts[0].name}>
                            <Text color="white" variant="Text2" noStyles>
                                {thirdPartyContacts[0].name}
                            </Text>
                            <br />
                        </Fragment>
                    );
                }
                if (thirdPartyContacts[0].email_address) {
                    contactInfo.push(
                        <Fragment key={thirdPartyContacts[0].email_address}>
                            <Text color="white" variant="Text2" noStyles>
                                {thirdPartyContacts[0].email_address}
                            </Text>
                            <br />
                        </Fragment>
                    );
                }
                if (thirdPartyContacts[0].phone_number) {
                    contactInfo.push(
                        <Fragment key={thirdPartyContacts[0].phone_number}>
                            <Text color="white" variant="Text2" noStyles>
                                {thirdPartyContacts[0].phone_number}
                            </Text>
                            <br />
                        </Fragment>
                    );
                }
                return <div className={styles.thirdPartyContactInformation}>{contactInfo}</div>;
            }

            // If there is more than one Third-Party contact, display a link to a modal that contains all the contacts' information.
            else {
                return (
                    <Text>
                        <Button variant="linkText" size="lg" onClick={() => setDisplayedElement(modalToOpen)}>
                            {`Show ${thirdPartyContacts.length} Contacts`}
                        </Button>
                    </Text>
                );
            }
        };

        return (
            <div className={styles.smallSection}>
                <PageCell variant="transparentBlue">
                    <Text variant="Text4" color="darkGray" toUppercase>
                        Third-Party Contact Information
                    </Text>
                    {determineThirdPartyContactInformationToDisplay(PageElements.ThirdPartyContactModal, thirdParty.vendor_contacts)}
                    <Text variant="Text4" color="darkGray" toUppercase>
                        Service Contact Information
                    </Text>
                    {determineThirdPartyContactInformationToDisplay(PageElements.ThirdPartyServiceContactModal, thirdPartyService.vendor_contacts)}
                    <Text variant="Text4" color="darkGray" toUppercase>
                        Service Description
                    </Text>
                    <Text variant="Text2" color="white">
                        {thirdPartyService.description}
                    </Text>
                    {uiState!.location && (
                        <Fragment>
                            <Text variant="Text4" color="darkGray" toUppercase>
                                Third-Party Location
                            </Text>
                            <ThirdPartyLocation {...thirdParty} />
                        </Fragment>
                    )}
                    <Text variant="Text4" color="darkGray" toUppercase>
                        Third-Party Website
                    </Text>
                    {thirdParty.website ? (
                        <Link openInNewTab size="lg" to={thirdParty.website}>
                            {thirdParty.website}
                        </Link>
                    ) : (
                        <Text variant="Text2" color="white">
                            -
                        </Text>
                    )}
                </PageCell>
            </div>
        );
    };

    /**
     * If Third-Party Risk Scoring is available, render it.
     * If an error was returned when querying Third-Party Risk Scoring data, show the error.
     * If neither, show nothing.
     */
    const renderThirdPartyMonitoringWidget = () => {
        if (thirdPartyMonitoringMetricValues && thirdPartyMonitoringMetricValues.length > 0) {
            return (
                <div className={styles.dashboardSection}>
                    <Text variant="Header2" color="white">
                        Third-Party Risk Scoring Information
                    </Text>
                    <PageCell variant="transparentBlue">
                        <ThirdPartyMonitoring thirdPartyRiskScores={thirdPartyMonitoringMetricValues} />
                    </PageCell>
                </div>
            );
        } else if (thirdPartyMonitoringMetricValuesError) {
            return (
                <div className={styles.dashboardSection}>
                    <Text variant="Header2" color="white">
                        Third-Party Risk Scoring Information
                    </Text>
                    <PageCell variant="transparentBlue">
                        <Text color="white">{thirdPartyMonitoringMetricValuesError.message}</Text>
                    </PageCell>
                </div>
            );
        }
    };

    // The service ids are compared to prevent any stale data being shown when the service id parameter changes.
    if (uiState && serviceResponse && serviceResponse.id === serviceIdUrlParam && thirdPartyResponse && questionnaireAggregationIsLoaded) {
        const confirmNotificationModalProps: ConfirmResetAndNotifyThirdPartyModalProps = {
            tprmApi: props.tprmApi,
            service: serviceResponse,
            hideModal: () => setDisplayedElement(PageElements.None),
            questionnaireHasQuestions: questionnaireAggregationResponse?.number_of_questions !== undefined && questionnaireAggregationResponse.number_of_questions > 0,
        };

        const onSelectLinkToParent = () => {
            setSelectedItem(cloneDeep(serviceResponse));
            setDisplayedElement(PageElements.CommonAssessmentSelectionModal);
        };

        const onSelectUnlinkFromParent = () => {
            setSelectedItem(cloneDeep(serviceResponse));
            setDisplayedElement(PageElements.UnlinkCommonAssessmentModal);
        };

        const riskWorkflowProps: RiskWorkflowProps = {
            serviceResponse: serviceResponse,
            thirdPartyId: uiState.thirdPartyId,
            serviceId: uiState.serviceId,
            residualRisk: uiState.residualRisk,
            location: uiState.location,
            ddqPath: uiState.ddqPath,
            riskWorkflowStep: uiState.riskWorkflowStep,
            irqStatus: uiState.irqStatus,
            irqPercentComplete: uiState.irqPercentComplete,
            irqDateCompleted: uiState.irqDateCompleted,
            ddqStatus: uiState.ddqStatus,
            ddqStatusLabel: uiState.ddqStatusLabel,
            ddqPercentComplete: uiState.ddqPercentComplete,
            ddqDateStarted: uiState.ddqDateStarted,
            hasParent: uiState.hasParent,
            assessmentStatus: uiState.assessmentStatus,
            assessmentStatusLabel: uiState.assessmentStatusLabel,
            assessmentPercentComplete: uiState.assessmentPercentComplete,
            assessmentDateStarted: uiState.assessmentDateStarted,
            reportPercentComplete: uiState.reportPercentComplete,
            reportStatus: uiState.reportStatus,
            reportDateCompleted: uiState.reportDateCompleted,
            displayConfirmationEmailModal: displayConfirmationEmailModal,
            controlAssessmentHasControls: questionnaireAggregationResponse?.number_of_controls !== undefined && questionnaireAggregationResponse.number_of_controls > 0,
            thirdPartyQuestionnaireHasQuestions: questionnaireAggregationResponse?.number_of_questions !== undefined && questionnaireAggregationResponse.number_of_questions > 0,
        };

        return (
            <>
                {displayedElement === PageElements.ConfirmNotificationEmail && <ConfirmResetAndNotifyThirdPartyModal {...confirmNotificationModalProps} />}
                {displayedElement === PageElements.CommonAssessmentSelectionModal && <CommonAssessmentSelectionModal {...commonAssessmentSelectionModalProps(selectedItem as Service)} />}
                {displayedElement === PageElements.UnlinkCommonAssessmentModal && <ConfirmUnlinkServicesModal {...confirmUnlinkServicesModalProps(selectedItem as Service)} />}
                {displayedElement === PageElements.ThirdPartyContactModal && <ThirdPartyContactModal header="Third-Party Contacts" hideModal={hideModal} thirdPartyContacts={thirdPartyResponse.vendor_contacts} />}
                {displayedElement === PageElements.ThirdPartyServiceContactModal && <ThirdPartyContactModal header="Third-Party Service Contacts" hideModal={hideModal} thirdPartyContacts={serviceResponse.vendor_contacts} />}
                <div className={styles.contentWrapper}>
                    <PageBackground color="blueMountains">
                        <div className={styles.content}>
                            <PageContent>
                                <Breadcrumb textColor="blue">
                                    <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                                    <BreadcrumbText>{uiState.thirdPartyServiceTitle}</BreadcrumbText>
                                </Breadcrumb>
                                <div className={styles.dashboardSection}>
                                    <Text variant="Header1" color="white">
                                        {uiState.thirdPartyServiceTitle}
                                    </Text>
                                </div>
                                <div className={styles.dashboardSectionTop}>
                                    <div className={styles.smallSection}>
                                        <PageCell variant="transparentBlue">
                                            <div className={styles.riskAndEffectiveness}>
                                                <div>
                                                    <Text variant="Text4" color="darkGray" toUppercase>
                                                        INHERENT RISK
                                                    </Text>
                                                    {uiState.inherentRisk && uiState.inherentRisk !== RiskRatingAsString(RiskRating.INACTIVE) ? (
                                                        <div className={styles.riskScoreTextContainer}>
                                                            <CircleIndicator variant={uiState.inhereRiskVariant} />
                                                            <Text variant="Text1" color="white" noStyles>
                                                                {uiState.inherentRisk}
                                                            </Text>
                                                        </div>
                                                    ) : (
                                                        <Text variant="Text3" color="white">
                                                            Complete the inherent risk questionnaire to establish the inherent risk rating.
                                                        </Text>
                                                    )}
                                                </div>
                                                <div>
                                                    <Text variant="Text4" color="darkGray" toUppercase>
                                                        OVERALL CONTROL EFFECTIVENESS
                                                    </Text>
                                                    {uiState.controlEffectivenessProgressBar && <ProgressBarIndicator {...uiState.controlEffectivenessProgressBar} />}
                                                    {uiState.controlEffectiveness ? (
                                                        <Text variant="Text1" color="white" noStyles>
                                                            {uiState.controlEffectiveness}
                                                        </Text>
                                                    ) : (
                                                        <Text variant="Text3" color="white">
                                                            Complete the final review to establish the overall control effectiveness.
                                                        </Text>
                                                    )}
                                                </div>
                                                <div>
                                                    <Text variant="Text4" color="darkGray" toUppercase>
                                                        RESIDUAL RISK
                                                    </Text>
                                                    {uiState.residualRisk && uiState.residualRiskVariant ? (
                                                        <div className={styles.riskScoreTextContainer}>
                                                            <CircleIndicator variant={uiState.residualRiskVariant} />
                                                            <Text variant="Text1" color="white" noStyles>
                                                                {uiState.residualRisk}
                                                            </Text>
                                                        </div>
                                                    ) : (
                                                        <Text variant="Text3" color="white">
                                                            Complete the final review to establish the residual risk rating.
                                                        </Text>
                                                    )}
                                                </div>
                                                <div className={styles.assessment}>
                                                    <div>
                                                        <Text variant="Text4" color="darkGray" toUppercase>
                                                            Assessment State
                                                        </Text>
                                                        <Text variant="Text2" color="white" noStyles>
                                                            {uiState.assessmentStateHeaderText}
                                                        </Text>
                                                    </div>
                                                    <div>
                                                        <Text variant="Text4" color="darkGray" toUppercase>
                                                            Assessment Due Date
                                                        </Text>
                                                        <Text variant="Text2" color="white" noStyles>
                                                            {uiState.assessmentDueDateHeaderText}
                                                        </Text>
                                                    </div>
                                                </div>
                                            </div>
                                        </PageCell>
                                    </div>

                                    {renderThirdPartyandThirdPartyServiceInformationWidget(thirdPartyResponse, serviceResponse)}

                                    <div className={styles.smallSectionRight}>
                                        <PageCell variant="transparentBlue">
                                            <Text variant="Header2" color="white">
                                                {uiState.clientName}
                                            </Text>
                                            <Text variant="Text4" color="darkGray" toUppercase>
                                                Third-Party Manager
                                            </Text>
                                            <Text variant="Text2" color="white">
                                                {uiState.thirdPartyManager}
                                            </Text>
                                            <Text variant="Text4" color="darkGray" toUppercase>
                                                Service Manager
                                            </Text>
                                            <Text variant="Text2" color="white">
                                                {uiState.serviceManager}
                                            </Text>
                                        </PageCell>
                                        <PageCell variant="transparentBlue">
                                            <CommonAssessment service={serviceResponse} onSelectLinkToParent={onSelectLinkToParent} onSelectUnlinkFromParent={onSelectUnlinkFromParent} />
                                        </PageCell>
                                    </div>
                                </div>
                                <div className={styles.dashboardSection}>
                                    <PageCell variant="transparentBlue">
                                        <Text variant="Header2" color="white">
                                            Risk Workflow
                                        </Text>
                                        <RiskWorkflow {...riskWorkflowProps} />
                                    </PageCell>
                                </div>
                                <div className={styles.dashboardSection}>
                                    <PageCell variant="transparentBlue">
                                        <Text variant="Header2" color="white">
                                            Control Effectiveness Breakdown
                                        </Text>
                                        <div className={styles.barGraphContainer}>{questionnaireAggregationResponse ? <ControlBarGraph controlBarData={uiState.effectivenessSummary} yAxisLabel="NUMBER OF CONTROLS" onItemClick={displayControlsClicked} /> : <Text color="white">The inherent risk questionnaire must be completed before you can view a summary of control effectiveness.</Text>}</div>
                                    </PageCell>
                                </div>

                                {renderThirdPartyMonitoringWidget()}

                                <div className={styles.dashboardSection}>
                                    <PageCell variant="transparentBlue">
                                        <IssuesListing {...issuesListingProps} />
                                    </PageCell>
                                </div>
                                <div className={styles.dashboardSection}>
                                    <PageCell variant="transparentBlue">
                                        <ExceptionsListing {...exceptionsListingProps} />
                                    </PageCell>
                                </div>
                            </PageContent>
                        </div>
                    </PageBackground>
                </div>
            </>
        );
    } else {
        return <Placeholder />;
    }
};

/*
    RestApiConnector.js -- Connector for the AWS API Gateway.
    See `Auth.md` for information on this file and related components.
*/

import axios from 'axios';

import { AWSConfig } from 'Config/AWSConfig';
import { GENERIC_ERROR_MESSAGE, WAF_BLOCK_MESSAGE } from 'Config/Errors';
import { OktaAuthHandler } from 'Helpers/Auth/OktaAuthHandler';

export const authHandler = new OktaAuthHandler();

// Connect to a broadcast channel so that when one tab receives a 401 response, it can share an event and all tabs will know to navigate to the "Logged Out" page.
const UNAUTHORIZED_RESPONSE_RECEIVED = 'UNAUTHORIZED_RESPONSE_RECEIVED';
const BROADCAST_CHANNEL = new BroadcastChannel('BROADCAST_CHANNEL');
BROADCAST_CHANNEL.onmessage = (event) => {
    if (event.data === UNAUTHORIZED_RESPONSE_RECEIVED) {
        authHandler.userUnauthorized();
    }
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
    const accessToken = authHandler.getAccessToken();
    const authorizationHeader = accessToken === null ? {} : { Authorization: `Bearer ${accessToken}` };
    config['headers'] = authorizationHeader;
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        const accessToken = response.headers['x-hps-token'];
        if (accessToken) {
            authHandler.setAccessToken(accessToken);
        }
        return response.data;
    },
    (error) => {
        if (error.response.status === 401) {
            BROADCAST_CHANNEL.postMessage(UNAUTHORIZED_RESPONSE_RECEIVED);
            // The message will not be received by the tab that did the sending, so we still need to call `userUnauthorized` here.
            authHandler.userUnauthorized();
        }
        return Promise.reject(error);
    }
);

axiosInstance.defaults.baseURL = AWSConfig.apiGateway.URL;

const parseAxiosError = (error) => {
    console.error(error.response);

    if (error.response.status >= 500 && error.response.status <= 599) {
        return Error(GENERIC_ERROR_MESSAGE);
    }
    // This is intended to provide a useful message when the WAF blocks requests, and possibly for CORS errors. Anything else should fall through and hit the "else" statement.
    else if (error.code === 'ERR_NETWORK' && error.message === 'Network Error' && error.name === 'AxiosError') {
        return Error(WAF_BLOCK_MESSAGE);
    } else {
        return Error(error.response.data.message);
    }
};

// Send an HTTP POST to the specified API Gateway and endpoint.
export const restApiPost = async (endpointPath, data) => {
    try {
        return await axiosInstance.post(endpointPath, data);
    } catch (error) {
        throw parseAxiosError(error);
    }
};

// Send an HTTP GET to the specified API Gateway and endpoint.
export const restApiGet = async (endpointPath, queryParameters) => {
    try {
        return await axiosInstance.get(endpointPath, {
            params: queryParameters,
        });
    } catch (error) {
        throw parseAxiosError(error);
    }
};

// Send an HTTP PUT to the specified API Gateway and endpoint.
export const restApiPut = async (endpointPath, data) => {
    try {
        return await axiosInstance.put(endpointPath, data);
    } catch (error) {
        throw parseAxiosError(error);
    }
};

export const restApiDelete = async (endpointPath) => {
    try {
        return await axiosInstance.delete(endpointPath);
    } catch (error) {
        throw parseAxiosError(error);
    }
};

export const documentUpload = async (url, fields, file) => {
    const formData = new FormData();
    for (const key in fields) {
        formData.append(key, fields[key]);
    }
    formData.append('file', file);
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const documentGet = async (filePath) => {
    const customHeaders = {};
    customHeaders['content-type'] = 'application/json';
    customHeaders['Accept'] = 'application/octet-stream';
    const method = 'GET';
    const url = `${filePath}`;
    const params = {
        method,
        url,
        headers: customHeaders,
        responseType: 'text',
    };

    return axiosInstance(params)
        .then((response) => {
            return new Blob([response]);
        })
        .catch((error) => {
            throw error;
        });
};

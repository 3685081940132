import { Accordion } from 'Components/Accordion/Accordion';
import { AccordionCollapse } from 'Components/Accordion/AccordionCollapse/AccordionCollapse';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';
import { Button, Link } from 'Components/Buttons/Buttons';
import { useUsers } from 'Components/Context/UsersContext';
import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { Text } from 'Components/Text/Text';
import { RISKS, RISK_REGISTER } from 'Config/Paths';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { AssociatedRisk, TreatmentPlanStatus } from 'Models/Actions';
import { getEffectivenessVariantColor, numberAsEffectiveness } from 'Models/OperationalControls';
import { RiskResponse } from 'Models/RiskRegister';

import styles from './RiskAccordion.module.css';

type RiskItem = RiskResponse | AssociatedRisk;

export interface RiskAccordionProps {
    risks: RiskItem[];
    showModal: (index: number) => void;
    disableLinks?: boolean;
}

export const RiskAccordion = (props: RiskAccordionProps) => {
    const { users } = useUsers();

    const titles = props.risks.map((risk) =>
        props.disableLinks ? (
            <Text noStyles>{risk.title}</Text>
        ) : (
            <Link size="sm" to={`/${RISK_REGISTER}/${RISKS}/${risk.id}`}>
                {risk.title}
            </Link>
        )
    );

    const scoresSection = (impact: number | undefined, probability: number | undefined) => {
        if (impact === undefined || probability === undefined) {
            return <></>;
        }

        return (
            <div className={styles.scoreInfo}>
                <div className={styles.scoreFactors}>
                    <Text variant="Text3" noStyles>
                        {`Impact: ${impact}`}
                    </Text>
                    <Text variant="Text3" noStyles>
                        {`Probability: ${probability}`}
                    </Text>
                </div>
            </div>
        );
    };

    const accordionForRisk = (risk: RiskItem, index: number): JSX.Element => {
        const key = risk.id;

        const controls = (() => {
            const text = `${risk.controls.length} ${risk.controls.length === 1 ? 'Control' : 'Controls'}`;

            if (props.disableLinks) {
                return <Text>{text}</Text>;
            } else {
                return (
                    <Button variant="linkText" size="sm" onClick={() => props.showModal(index)}>
                        {text}
                    </Button>
                );
            }
        })();

        return (
            <Accordion key={key}>
                <div className={styles.accordionContainer}>
                    <div className={styles.accordionColumnTitle}>
                        <AccordionToggle eventKey={key} ariaLabelSuffix={risk.title} />
                        <div className={styles.titleAndCategory}>
                            <div className={styles.title}>{titles[index]}</div>
                            <Text color="darkGray" variant="Text3" noStyles>
                                {risk.category.title}
                            </Text>
                        </div>
                    </div>
                    <div className={styles.evenlySpacedAccordionItemsContainer}>
                        <div className={styles.accordionColumn}>
                            <Text variant="Text3" noStyles>
                                {risk.total_inherent_risk !== undefined ? risk.total_inherent_risk.toFixed(1) : '-'}
                            </Text>
                        </div>
                        <div className={styles.accordionColumn}>
                            <Text variant="Text3" noStyles>
                                {risk.strategy !== undefined ? risk.strategy.title : '-'}
                            </Text>
                        </div>
                        <div className={styles.accordionColumn}>
                            <Text variant="Text3" noStyles>
                                {risk.total_current_risk !== undefined ? risk.total_current_risk.toFixed(1) : '-'}
                            </Text>
                        </div>
                        <div className={styles.accordionColumn}>
                            <Text variant="Text3" noStyles>
                                {risk.treatment_plan_status === TreatmentPlanStatus.MISSING ? '' : risk.treatment_plan_status}
                            </Text>
                        </div>
                        <div className={styles.accordionColumn}>
                            <Text variant="Text3" noStyles>
                                {risk.total_target_risk !== undefined ? risk.total_target_risk.toFixed(1) : '-'}
                            </Text>
                        </div>
                    </div>
                </div>
                <AccordionCollapse eventKey={key}>
                    <div className={styles.additionalInfo}>
                        <div className={styles.additionalInfoRowsContainer}>
                            <div className={styles.accordionColumnTitle} />
                            <div className={styles.evenlySpacedAccordionItemsContainer}>
                                <div className={styles.accordionColumn}>{scoresSection(risk.inherent_risk_impact, risk.inherent_risk_probability)}</div>
                                <div className={styles.accordionColumn}>
                                    <div className={styles.controlEnvironmentEffectivenessContainer}>
                                        <CircleIndicator variant={getEffectivenessVariantColor(numberAsEffectiveness(risk.control_environment_effectiveness))} />
                                        <Text noStyles>{risk.control_environment_effectiveness === 0 ? 'Inactive' : risk.control_environment_effectiveness}</Text>
                                    </div>
                                    {controls}
                                </div>
                                <div className={styles.accordionColumn}>{scoresSection(risk.current_risk_impact, risk.current_risk_probability)}</div>
                                <div className={styles.accordionColumn} />
                                <div className={styles.accordionColumn}>{scoresSection(risk.target_risk_impact, risk.target_risk_probability)}</div>
                            </div>
                        </div>
                        <div className={styles.lastUpdatedInfo}>
                            <Text noStyles>
                                <b>Updated By:</b>
                            </Text>
                            <Text noStyles>{getUserNameFromSubject(risk.last_updated_by, users)}</Text>
                        </div>
                        <div className={styles.lastUpdatedInfo}>
                            <Text noStyles>
                                <b>Comment:</b>
                            </Text>
                            <Text noStyles>{risk.scores_comment ?? '-'}</Text>
                        </div>
                    </div>
                </AccordionCollapse>
                <hr className={styles.hrModifier} />
            </Accordion>
        );
    };

    return (
        <>
            <div className={styles.accordionHeaderContainer}>
                <div className={styles.accordionHeaderTitle}>
                    <Text variant="Text3" noStyles>
                        RISK
                    </Text>
                </div>
                <div className={styles.evenlySpacedAccordionItemsContainer}>
                    <div className={styles.accordionHeader}>
                        <Text variant="Text3" noStyles>
                            {'INHERENT '}
                            <br />
                            RISK SCORE
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text variant="Text3" noStyles>
                            {'RISK '}
                            <br />
                            STRATEGY
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text variant="Text3" noStyles>
                            {'CURRENT RESIDUAL '}
                            <br />
                            RISK SCORE
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text variant="Text3" noStyles>
                            {'TREATMENT '}
                            <br />
                            PLAN
                        </Text>
                    </div>
                    <div className={styles.accordionHeader}>
                        <Text variant="Text3" noStyles>
                            {'TARGET RESIDUAL '}
                            <br />
                            RISK SCORE
                        </Text>
                    </div>
                </div>
            </div>
            {props.risks.map(accordionForRisk)}
        </>
    );
};

import { DocumentApi } from 'Api/Document/DocumentApi';
import { useUsers } from 'Components/Context/UsersContext';
import { FieldLabel } from 'Components/FieldLabel/FieldLabel';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { TagListing } from 'Components/Tags/TagListing/TagListing';
import { Text } from 'Components/Text/Text';
import { UserDefinedReference } from 'Components/UserDefinedHyperlink/UserDefinedHyperlink';
import { ACTIONS_LINK, ACTIONS_OWNER } from 'Config/Tooltips';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { UserNameFormat, getUserNameFromSubject } from 'Helpers/UserUtils';
import { Action, ActionHistoryResponse } from 'Models/Actions';
import { DisplayableTag } from 'Models/Tags';
import styles from 'Styles/DetailsPage.module.css';

interface ActionDetailsCurrentSnapshotProps {
    type: 'current';
    displayableTags: DisplayableTag[];
    action: Action;
    documentApi: DocumentApi;
}

interface ActionDetailsHistoricalSnapshotProps {
    type: 'historical';
    action: ActionHistoryResponse;
    documentApi: DocumentApi;
}

export type ActionDetailsSnapshotProps = ActionDetailsCurrentSnapshotProps | ActionDetailsHistoricalSnapshotProps;

/**
 * Renders details for an action (current or historical), minus the associated risks.
 * Used in the "main" area of the Action Details page, and in historical modals on the same page.
 */
export const ActionDetailsSnapshot = (props: ActionDetailsSnapshotProps) => {
    const { users } = useUsers();

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsGridSection}>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Status" />
                        <Text variant="Text2">{props.action.status}</Text>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Owner" tooltipText={ACTIONS_OWNER} />
                        <Text variant="Text2">{getUserNameFromSubject(props.action.owner, users, UserNameFormat.FIRST_SPACE_LAST)}</Text>
                    </div>
                </div>
                <div className={styles.detailsGridColumn}>
                    <div className={styles.labelAndValueContainer}>
                        <FieldLabel fieldName="Due Date" />
                        <Text variant="Text2">{props.action.due_date ? iso8601ToUsDateShort(props.action.due_date) : '-'}</Text>
                    </div>
                </div>
            </div>
            {props.action.created_timestamp !== undefined && props.action.created_by !== undefined && (
                <div className={styles.labelAndValueContainer}>
                    <FieldLabel fieldName="Created" />
                    <Text>{`${iso8601ToUsDateShort(props.action.created_timestamp)} by ${getUserNameFromSubject(props.action.created_by, users, UserNameFormat.FIRST_SPACE_LAST)}`}</Text>
                </div>
            )}
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Link" tooltipText={ACTIONS_LINK} />
                <UserDefinedReference reference={props.action.link} />
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Description" />
                <Text>{props.action.description}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Comments" />
                <Text>{props.action.comments ?? '-'}</Text>
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Additional Documents" />
                {props.action.documents.length === 0 ? <Text>-</Text> : [...props.action.documents].sort((a, b) => (a.filename > b.filename ? 1 : -1)).map((file) => <UploadedFileAndState key={file.file_id} file={file} documentApi={props.documentApi} />)}
            </div>
            <div className={styles.labelAndValueContainer}>
                <FieldLabel fieldName="Tags" />
                {props.action.tags.length > 0 ? <TagListing tags={props.type === 'historical' ? props.action.tags : props.displayableTags} showAll /> : <Text noStyles>-</Text>}
            </div>
        </div>
    );
};

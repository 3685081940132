import { UserResponse } from 'Models/User';

import { ServiceCard } from './ServiceCard/ServiceCard';
import styles from './ServiceListingGridView.module.css';
import { ServiceDisplayData } from '../ServiceListing';

export interface ServiceListingGridViewProps {
    services: ServiceDisplayData[];
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
    users: UserResponse[];
}

export const ServiceListingGridView = (props: ServiceListingGridViewProps) => {
    return <div className={styles.gridView}>{props.services && props.services.map((thirdParty: ServiceDisplayData) => <ServiceCard key={thirdParty.serviceID} serviceCard={thirdParty} selectedDeleteService={props.selectedDeleteService} selectedModifyService={props.selectedModifyService} selectedServiceAssessmentDueDateService={props.selectedServiceAssessmentDueDateService} users={props.users} />)}</div>;
};

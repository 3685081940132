import { Skeleton } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { Link } from 'Components/Buttons/Buttons';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldSelectDark } from 'Components/FormField/FormFieldSelectDark/FormFieldSelectDark';
import { Text } from 'Components/Text/Text';
import { COMPLIANCE_REQUIREMENTS } from 'Config/Paths';
import { ComplianceHistoryFFIECCAT, ComplianceSummary, ComplianceSummarySnapshot, FFIECCATMaturityLevel, MonthNumber, targetMaturitySelectOptions } from 'Models/Dashboards';
import { OptionType } from 'Models/Types/GlobalType';

import { ComplianceRequirementDisplayInformation, ComplianceRequirementDisplayModal } from './ComplianceRequirementDisplayModal/ComplianceRequirementDisplayModal';
import { ComplianceRequirementSummary } from './ComplianceRequirementSummary/ComplianceRequirementSummary';
import styles from './ComplianceRequirements.module.css';
import { stringToComplianceModalType } from '../OperationalControlsDashboard.helpers';
import { FFIECRequirementSummary } from './FFIECRequirementSummary/FFIECRequirementSummary';

export interface ComplianceRequirementsProps {
    complianceSummary?: ComplianceSummary[];
    complianceSummaryError?: Error;
    api: DashboardApi;
}

enum Modals {
    NONE,
    DISPLAY_REQUIREMENTS,
}

export const ComplianceRequirements = (props: ComplianceRequirementsProps): JSX.Element => {
    const [selectedRegulation, setSelectedRegulation] = useState<string>();
    const [complianceSummarySnapshots, setComplianceSummarySnapshots] = useState<ComplianceSummarySnapshot[]>();
    const [displayModalProps, setDisplayModalProps] = useState<ComplianceRequirementDisplayInformation>();
    const [modalState, setModalState] = useState<Modals>(Modals.NONE);
    const [ffiecSummarySnapshot, setFfiecSummarySnapshot] = useState<ComplianceHistoryFFIECCAT>();
    const [targetMaturity, setTargetMaturity] = useState<FFIECCATMaturityLevel>(FFIECCATMaturityLevel.BASELINE); // This will be set to the company's target maturity once it is retrieved, but BASELINE is used as a default in case the retrieval fails.
    const [errorMessage, setErrorMessage] = useState<string>();

    const createRegulationOptions = (complianceSummary: ComplianceSummary[]): OptionType[] => {
        return complianceSummary.map((regulationSummary) => {
            return {
                label: regulationSummary.regulation,
                value: regulationSummary.regulation,
            };
        });
    };

    useEffect(() => {
        const getTargetMaturity = async () => {
            try {
                const response = await props.api.getTargetMaturity();
                setTargetMaturity(response.data);
            } catch (error) {
                setErrorMessage(error.message);
            }
        };

        getTargetMaturity();
    }, [props.api]);

    const getRegulation = useCallback(
        (regulationName: string) => {
            const getRegulationData = async () => {
                try {
                    if (regulationName === 'FFIEC CAT (May 2017)') {
                        const ffiecSummary = await props.api.getComplianceHistorySummaryFFIECCAT(regulationName, targetMaturity);
                        setFfiecSummarySnapshot(ffiecSummary.data);
                        setComplianceSummarySnapshots(undefined);
                    } else {
                        const complianceHistorySummary = await props.api.getComplianceHistorySummary(regulationName);
                        setComplianceSummarySnapshots(complianceHistorySummary.data);
                        setFfiecSummarySnapshot(undefined);
                    }
                } catch (err) {
                    setErrorMessage(err.message);
                }
            };
            getRegulationData();
        },
        [props.api, targetMaturity]
    );

    useEffect(() => {
        if (selectedRegulation !== undefined) {
            getRegulation(selectedRegulation);
        } else if (props.complianceSummary) {
            setSelectedRegulation(props.complianceSummary[0].regulation);
        }
    }, [getRegulation, props.complianceSummary, selectedRegulation]);

    const handleSelectChange = (value: ChangeEventType): void => {
        setSelectedRegulation(value as string);
        getRegulation(value as string);
    };

    const handleSelectTargetMaturityChange = (value: ChangeEventType): void => {
        setTargetMaturity(value as FFIECCATMaturityLevel);
    };

    const handleChartClick = (item: string): void => {
        const clickedItem = item.split('|');
        const requirementClicked = clickedItem[1].slice(1);
        const dateClicked = moment(clickedItem[0], 'MMM YY ');
        if (stringToComplianceModalType(requirementClicked)) {
            const regulationTypeSelected = stringToComplianceModalType(requirementClicked);
            setDisplayModalProps({ regulationName: selectedRegulation!, regulationTypeSelected: regulationTypeSelected!, timeframe: { month: (dateClicked.month() + 1) as MonthNumber, year: dateClicked.year() }, targetMaturity: selectedRegulation === 'FFIEC CAT (May 2017)' ? targetMaturity : undefined });
            setModalState(Modals.DISPLAY_REQUIREMENTS);
        }
    };

    const handleProgressBarClicked = (requirementType: string) => {
        const dateClicked = moment();
        if (stringToComplianceModalType(requirementType)) {
            const regulationTypeSelected = stringToComplianceModalType(requirementType);
            setDisplayModalProps({ regulationName: selectedRegulation!, regulationTypeSelected: regulationTypeSelected!, timeframe: { month: (dateClicked.month() + 1) as MonthNumber, year: dateClicked.year() }, targetMaturity: selectedRegulation === 'FFIEC CAT (May 2017)' ? targetMaturity : undefined });
            setModalState(Modals.DISPLAY_REQUIREMENTS);
        }
    };

    if (props.complianceSummaryError) {
        return (
            <PageCell variant="transparentBlue">
                <Text color="white" variant="Header4">
                    {props.complianceSummaryError.message}
                </Text>
            </PageCell>
        );
    }

    if (errorMessage && selectedRegulation && props.complianceSummary) {
        return (
            <PageCell variant="transparentBlue">
                <div className={styles.header}>
                    <Link size="lg" to={`/${COMPLIANCE_REQUIREMENTS}#${selectedRegulation}`}>
                        {selectedRegulation}
                    </Link>
                    <FormFieldSelectDark formFieldId="riskType" formFieldLabel="Risk Type" options={createRegulationOptions(props.complianceSummary)} selectedOption={selectedRegulation} handleChange={handleSelectChange} />
                </div>
                <Text color="white">{errorMessage}</Text>
            </PageCell>
        );
    }

    return (
        <>
            {modalState === Modals.DISPLAY_REQUIREMENTS && displayModalProps && <ComplianceRequirementDisplayModal api={props.api} hideModal={() => setModalState(Modals.NONE)} regulationInformation={displayModalProps} />}
            <PageCell variant="transparentBlue">
                <div className={styles.header}>
                    {selectedRegulation && props.complianceSummary ? (
                        <>
                            <Link size="lg" to={`/${COMPLIANCE_REQUIREMENTS}#${selectedRegulation}`}>
                                {selectedRegulation}
                            </Link>
                            <FormFieldSelectDark formFieldId="riskType" formFieldLabel="Risk Type" options={createRegulationOptions(props.complianceSummary)} selectedOption={selectedRegulation} handleChange={handleSelectChange} />
                        </>
                    ) : (
                        <Skeleton>
                            <Link size="lg" to={`/${COMPLIANCE_REQUIREMENTS}`}>
                                Loading Regulation
                            </Link>
                            <FormFieldSelectDark formFieldId="riskType" formFieldLabel="Risk Type" />
                        </Skeleton>
                    )}
                </div>
                {ffiecSummarySnapshot && (
                    <div className={styles.targetMaturitySelectContainer}>
                        <div className={styles.targetMaturitySelect}>
                            <FormFieldSelectDark formFieldId="targetMaturity" formFieldLabel="Target Maturity" options={targetMaturitySelectOptions} selectedOption={targetMaturity} handleChange={handleSelectTargetMaturityChange} />
                        </div>
                    </div>
                )}
                {complianceSummarySnapshots && props.complianceSummary && selectedRegulation && <ComplianceRequirementSummary complianceSummary={props.complianceSummary.find((compliance) => compliance.regulation === selectedRegulation)!} complianceSummarySnapshots={complianceSummarySnapshots} handleChartClick={handleChartClick} handleProgressBarClicked={handleProgressBarClicked} />}
                {ffiecSummarySnapshot && <FFIECRequirementSummary complianceSummarySnapshots={ffiecSummarySnapshot} handleChartClick={handleChartClick} handleProgressBarClicked={handleProgressBarClicked} />}
                {complianceSummarySnapshots === undefined && ffiecSummarySnapshot === undefined && <Skeleton variant="rounded" width={'100%'} height={300} />}
            </PageCell>
        </>
    );
};

/*
	Header.tsx -- Application logic for the Header bar.
*/
import { useContext, useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { AlertsLimitsApi } from 'Api/AlertsLimits/AlertsLimitsApi';
import { LogoutApi } from 'Api/Auth/LogoutApi';
import { ClientDetailsApi } from 'Api/Client/ClientDetailsApi';
import { NotificationsApi } from 'Api/Notifications/NotificationsApi';
import { IconButton } from 'Components/Buttons/IconButton';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { RBACContext, Role, userHasAuthorizedRole } from 'Components/Context/RBACContext';
import { useUsers } from 'Components/Context/UsersContext';
import { AWSConfig } from 'Config/AWSConfig';
import { NumberOfAlertsToDisplay } from 'Config/Parameters';
import { AuthHandler } from 'Helpers/Auth/AuthHandler';
import { saveFileFromApi } from 'Helpers/FileUtils';
import { WindowNavigator } from 'Helpers/WindowNavigator';
import { AlertRequest, AlertResponse } from 'Models/Alerts';
import { UserNotification } from 'Models/Notification';

import styles from './Header.module.css';
import { HeaderAlerts, HeaderAlertsProps } from './HeaderAlerts/HeaderAlerts';
import { HeaderNotificationProps, HeaderNotifications } from './HeaderNotifications/HeaderNotifications';
import { HeaderProfile, HeaderProfileProps } from './HeaderProfile/HeaderProfile';

export interface HeaderProps {
    authHandler: AuthHandler;
    clientDetailsApi: ClientDetailsApi;
    isNavOpen: boolean;
    logoutApi: LogoutApi;
    navigate: NavigateFunction;
    setNavOpen: (open: boolean) => void;
    windowNavigator: WindowNavigator;
}

export interface HeaderNetworkingApis {
    alertsLimitsApi: AlertsLimitsApi;
    notificationsApi: NotificationsApi;
    windowNavigator: WindowNavigator;
}

export const Header = (props: HeaderProps & HeaderNetworkingApis): JSX.Element => {
    const rbacContext = useContext(RBACContext);
    const { users } = useUsers();

    const [usersFullName, setUsersFullName] = useState<string>('');
    const [notifications, setNotifications] = useState<UserNotification[]>([]);
    const [alerts, setAlerts] = useState<AlertResponse[]>([]);

    /**
     * Get Alerts when the component mounts.
     */
    useEffect(() => {
        const getAlerts = async (): Promise<void> => {
            try {
                const alertRequest: AlertRequest = { limit: NumberOfAlertsToDisplay };
                const alertResponse = await props.alertsLimitsApi.getAllAlerts(alertRequest);
                setAlerts(alertResponse.data);
            } catch (error) {
                handleRequestError(error);
            }
        };
        if (rbacContext && userHasAuthorizedRole(rbacContext.roles, [Role.ADMIN, Role.CONTROL_USER])) {
            getAlerts();
        }
    }, [props.alertsLimitsApi, rbacContext]);

    /**
     * Get Notifications when the component mounts.
     */
    useEffect(() => {
        const getNotifications = async (): Promise<void> => {
            try {
                const response = await props.notificationsApi.getNotifications();
                setNotifications(response.data);
            } catch (error) {
                handleRequestError(error);
            }
        };
        getNotifications();
    }, [props.notificationsApi]);

    /**
     * Get the current user's full name.
     */
    useEffect(() => {
        const getUsersFullName = async (): Promise<void> => {
            try {
                const full_name = props.authHandler.getAuthenticatedUserFullName(users);
                setUsersFullName(full_name);
            } catch (error) {
                handleRequestError(error);
            }
        };
        getUsersFullName();
    }, [props.authHandler, users]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    /**
     * Open a new tab allowing the user to change their password.
     */
    const handleChangePasswordClick = (): void => {
        props.windowNavigator.openExternalUrl(AWSConfig.oidc.PASSWORD_CHANGE_URL, true);
    };

    /**
     * Log out and redirect to the login page.
     */
    const handleLogout = (): void => {
        try {
            props.logoutApi.logOut();
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    /**
     * Download the Quickstart Guide.
     */
    const handleQuickstartGuideClick = async (): Promise<void> => {
        try {
            const fileDownload = (await props.clientDetailsApi.getCustomerDocumentation('Quickstart Guide.pdf')).data;
            saveFileFromApi('binary', fileDownload);
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    /**
     * Download the Release Notes.
     */
    const handleReleaseNotesClick = async (): Promise<void> => {
        try {
            const fileDownload = (await props.clientDetailsApi.getCustomerDocumentation('Release Notes.pdf')).data;
            saveFileFromApi('binary', fileDownload);
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    /**
     * Open the user's mail client to send an email to High Peaks Solutions support.
     */
    const handleSupportClick = (): void => {
        props.windowNavigator.openExternalUrl('mailto:support@highpeakssolutions.com', true);
    };

    // Call the child "Header" component, to render the HTML for the Header bar.
    const headerProfile: HeaderProfileProps = {
        username: usersFullName,
        handleChangePasswordClick: handleChangePasswordClick,
        handleLogout: handleLogout,
        handleQuickstartGuideClick: handleQuickstartGuideClick,
        handleReleaseNotesClick: handleReleaseNotesClick,
        handleSupportClick: handleSupportClick,
    };
    const headerNotifications: HeaderNotificationProps = {
        notifications: notifications,
    };
    const headerAlertsProps: HeaderAlertsProps = {
        alerts: alerts,
    };

    const closeMenuUrl = new URL('/open-menu.svg', import.meta.url).href;

    return (
        <div className={styles.Header}>
            <div className={props.isNavOpen === true ? styles.menuOpen : styles.menuClosed}>
                <IconButton aria-label="Open Menu Navigation" onClick={() => props.setNavOpen(true)} iconImage={<img src={closeMenuUrl} alt="open-menu-navigation" />} />
            </div>
            <div className={styles.HeaderRight}>
                <RBACComponent roles={[Role.ADMIN, Role.CONTROL_USER]}>
                    <HeaderAlerts {...headerAlertsProps} />
                </RBACComponent>
                <HeaderNotifications {...headerNotifications} />
                <HeaderProfile {...headerProfile} />
            </div>
        </div>
    );
};

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useUsers } from 'Components/Context/UsersContext';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { RiskRating, Service, ThirdPartyResponseWithServices } from 'Models/TPRM';

import { DeleteTPRMServiceModal, DeleteTPRMServiceModalProps } from './DeleteTPRMServiceModal/DeleteTPRMServiceModal';
import styles from './ManageTPRMServices.module.css';
import { SaveTPRMThirdPartyServiceModal, SaveTPRMThirdPartyServiceModalProps } from './SaveTPRMThirdPartyServiceModal/SaveTPRMThirdPartyServiceModal';
import { ServiceDisplayData, ServiceListing, ServiceListingProps } from './ServiceListing/ServiceListing';
import { ServiceAssessmentDueDateModal, ServiceAssessmentDueDateModalProps } from '../ServiceAssessmentDueDateModal/ServiceAssessmentDueDateModal';

export interface ManageTPRMServicesProps {
    tprmApi: TPRMApi;
}

export enum Modals {
    DeleteTPRMServiceModal,
    SaveTPRMThirdPartyServiceModal,
    ServiceAssessmentDueDateModal,
    None,
}

export interface ManageTPRMServicesRouteState {
    createServiceForThirdParty?: ThirdPartyResponseWithServices;
}

export const ManageTPRMServices = (props: ManageTPRMServicesProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [defaultThirdPartyByWhichToFilter, setDefaultThirdPartyByWhichToFilter] = useState<ThirdPartyResponseWithServices>();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);
    const [selectedService, setSelectedService] = useState<Service>();
    const [selectedThirdParty, setSelectedThirdParty] = useState<ThirdPartyResponseWithServices>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>(false);
    const { users } = useUsers();
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>();

    const getThirdParties = useCallback(async (): Promise<void> => {
        try {
            const thirdPartyResponse = await props.tprmApi.getThirdParties();
            const thirdParties = thirdPartyResponse.data;
            setThirdParties(thirdParties);

            const searchParams = new URLSearchParams(location.search);
            const thirdPartyId = searchParams.get('thirdPartyId');
            const defaultThirdPartyByWhichToFilter = thirdPartyId ? thirdParties.find((thirdParty) => thirdParty.id === thirdPartyId) : undefined;
            setDefaultThirdPartyByWhichToFilter(defaultThirdPartyByWhichToFilter);
        } catch (error) {
            handleRequestError(error);
        }
    }, [location.search, props.tprmApi]);

    useEffect(() => {
        getThirdParties();

        if (location.state && (location.state as ManageTPRMServicesRouteState).createServiceForThirdParty) {
            // Prevent showing the modal every time the page is reloaded.
            navigate(location.pathname, { state: {}, replace: true });

            setDisplayedModal(Modals.SaveTPRMThirdPartyServiceModal);
            setSelectedThirdParty((location.state as ManageTPRMServicesRouteState).createServiceForThirdParty);
            setTprmAccessDenied(false);
        }
    }, [getThirdParties, location.pathname, location.state, navigate]);

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const hideModal = (): void => {
        setDisplayedModal(Modals.None);
        setSelectedService(undefined);
        setSelectedThirdParty(undefined);
    };

    const handleRequestError = (error: Error): void => {
        if (isForbiddenResponseError(error)) {
            setTprmAccessDenied(true);
        } else {
            console.error('Error: ', error);
        }
    };

    const displayModalWithService = (modal: Modals, selectedService: ServiceDisplayData): void => {
        if (thirdParties) {
            const service = thirdParties.find((v) => v.id === selectedService.thirdPartyId)?.services.find((s) => s.id === selectedService.serviceID);
            setSelectedService(service);
            setDisplayedModal(modal);
        }
    };

    const getDeleteTPRMServiceModalProps = (): DeleteTPRMServiceModalProps => {
        const deleteTPRMServiceModalProps: DeleteTPRMServiceModalProps = {
            hideModal: hideModal,
            serviceDeleted: () => getThirdParties(),
            tprmApi: props.tprmApi,
            service: selectedService!,
        };

        return deleteTPRMServiceModalProps;
    };

    const getSetTPRMServiceDueDateModalProps = (): ServiceAssessmentDueDateModalProps => {
        return {
            hideModal: hideModal,
            serviceAssessmentDueDateSet: () => getThirdParties(),
            tprmApi: props.tprmApi,
            service: selectedService!,
        };
    };

    const createServiceListing = (thirdParties: ThirdPartyResponseWithServices[]): ServiceDisplayData[] => {
        return thirdParties.flatMap((thirdParty) => {
            return thirdParty.services.map((service) => {
                return {
                    thirdPartyName: thirdParty.name,
                    thirdPartyId: thirdParty.id,
                    serviceName: service.name,
                    serviceID: service.id,
                    riskRating: service.inherent_risk_score,
                    thirdPartyManagerId: service.vendor_service_manager_user_id,
                    dateCreated: service.created_time,
                    commonAssessmentChildren: service.common_assessment_children,
                    residualRisk: service.assessment_residual_risk_score ? service.assessment_residual_risk_score : RiskRating.INACTIVE,
                    assessmentDueDate: service.assessment_due_date,
                };
            });
        });
    };

    if (tprmAccessDenied) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>{UNAUTHORIZED_MESSAGE}</Text>
            </div>
        );
    }

    if (thirdParties && users) {
        const saveTPRMThirdPartyServiceModalProps: SaveTPRMThirdPartyServiceModalProps = {
            defaultThirdParty: selectedThirdParty,
            hideModal: hideModal,
            tprmApi: props.tprmApi,
            users: users,
            thirdParties: thirdParties,
            thirdPartyService: selectedService,
            thirdPartyServiceSaved: () => getThirdParties(),
        };
        const serviceListingProps: ServiceListingProps = {
            thirdParties: thirdParties,
            services: createServiceListing(thirdParties),
            users: users,
            defaultThirdPartyByWhichToFilter: defaultThirdPartyByWhichToFilter,
            selectedModifyService: (service: ServiceDisplayData) => displayModalWithService(Modals.SaveTPRMThirdPartyServiceModal, service),
            selectedDeleteService: (service: ServiceDisplayData) => displayModalWithService(Modals.DeleteTPRMServiceModal, service),
            selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => displayModalWithService(Modals.ServiceAssessmentDueDateModal, service),
        };

        return (
            <>
                {displayedModal === Modals.DeleteTPRMServiceModal && <DeleteTPRMServiceModal {...getDeleteTPRMServiceModalProps()} />}
                {displayedModal === Modals.SaveTPRMThirdPartyServiceModal && <SaveTPRMThirdPartyServiceModal {...saveTPRMThirdPartyServiceModalProps} />}
                {displayedModal === Modals.ServiceAssessmentDueDateModal && <ServiceAssessmentDueDateModal {...getSetTPRMServiceDueDateModalProps()} />}

                <PageBackground color="grey">
                    <PageContent>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue">
                                Manage Third-Party Services
                            </Text>
                            {thirdParties.length > 0 && (
                                <Button variant="primary" onClick={() => displayModal(Modals.SaveTPRMThirdPartyServiceModal)} fontAwesomeImage={faPlus}>
                                    CREATE NEW SERVICE
                                </Button>
                            )}
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <ServiceListing {...serviceListingProps} />
                    </PageContent>
                </PageBackground>
            </>
        );
    }

    return <Placeholder />;
};

import { Button, Link } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { Service } from 'Models/TPRM';

import styles from './CommonAssessment.module.css';

export interface CommonAssessmentProps {
    service: Service;
    onSelectLinkToParent: () => void;
    onSelectUnlinkFromParent: () => void;
}

export const CommonAssessment = (props: CommonAssessmentProps): JSX.Element => {
    const editButton = ((): JSX.Element | undefined => {
        const hasParent = props.service.common_assessment_parent;
        const hasChildren = props.service.common_assessment_children.length > 0;

        if (hasParent) {
            return (
                <Button variant="linkText" onClick={props.onSelectUnlinkFromParent} size="lg">
                    Unlink from Common Assessment
                </Button>
            );
        } else if (!hasParent && !hasChildren) {
            return (
                <Button variant="linkText" onClick={props.onSelectLinkToParent} size="lg">
                    Link to Common Assessment
                </Button>
            );
        } else {
            return undefined;
        }
    })();

    return (
        <>
            <Text variant="Header4" color="darkGray">
                COMMON ASSESSMENT
            </Text>

            {getCommonAssessmentMessage(props.service)}
            {editButton && <div className={styles.editButton}>{editButton}</div>}
        </>
    );
};

const getCommonAssessmentMessage = (service: Service): JSX.Element => {
    const parent = service.common_assessment_parent;
    const children = service.common_assessment_children;

    if (parent) {
        return (
            <>
                <Text variant="Text2" color="white" noStyles>
                    This third-party service is using the common assessment provided by:
                </Text>
                <div className={styles.linkedServiceName}>
                    <Link to={`/${TPRM}/${THIRD_PARTIES}/${parent.vendor_id}/${SERVICES}/${parent.id}/${DASHBOARDS}`} size="sm">
                        {parent.name}
                    </Link>
                </div>
            </>
        );
    } else if (children.length > 0) {
        const sortedChildren = children.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return (
            <>
                <Text variant="Text2" color="white">
                    This third-party service is providing a common assessment for:
                </Text>
                {sortedChildren.map((secondaryService) => {
                    return (
                        <div className={styles.linkedServiceName} key={secondaryService.id}>
                            <Link to={`/${TPRM}/${THIRD_PARTIES}/${secondaryService.vendor_id}/${SERVICES}/${secondaryService.id}/${DASHBOARDS}`} size="sm">
                                {secondaryService.name}
                            </Link>
                        </div>
                    );
                })}
            </>
        );
    }
    return (
        <Text variant="Text3" color="white">
            This third-party service is not using a common assessment.
        </Text>
    );
};

import { Component, Fragment } from 'react';

import { AccordionItem } from 'Components/Accordion/AccordionItem/AccordionItem';
import { AccordionToggle } from 'Components/Accordion/AccordionToggle/AccordionToggle';

import styles from './ControlSelectorRow.module.css';

export interface ControlSelectorRowProps {
    eventKey?: string;
    level: number;
    'aria-label': string;
    children?: React.ReactNode;
}
export class ControlSelectorRow extends Component<ControlSelectorRowProps> {
    getStyleClassArrow = (): string => {
        switch (this.props.level) {
            case 1:
                return styles.inlineStylesLevel0;
            case 2:
                return styles.inlineStylesLevel1;
            case 3:
                return styles.inlineStylesLevel2;
            case 4:
                return styles.inlineStylesLevel3;
            default:
                return styles.inlineStylesLevel4;
        }
    };

    getStyleClassNoArrow = (): string => {
        switch (this.props.level) {
            case 1:
                return styles.inlineStylesLevel1;
            case 2:
                return styles.inlineStylesLevel2;
            case 3:
                return styles.inlineStylesLevel3;
            case 4:
                return styles.inlineStylesLevel4;
            default:
                return styles.inlineStylesLevel5;
        }
    };

    render(): JSX.Element {
        if (this.props.eventKey) {
            return (
                <AccordionItem eventKey={this.props.eventKey}>
                    <div className={`${styles.rowText} ${this.getStyleClassArrow()}`}>
                        <AccordionToggle eventKey={this.props.eventKey} ariaLabelSuffix={this.props['aria-label']} />
                        {this.props.children}
                    </div>
                    <hr className={styles.hrModifier} />
                </AccordionItem>
            );
        }
        return (
            <Fragment>
                <div className={`${styles.rowText} ${this.getStyleClassNoArrow()}`}>{this.props.children}</div>
                <hr className={styles.hrModifier} />
            </Fragment>
        );
    }
}

import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { Link } from 'Components/Buttons/Buttons';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { TableCell, TableCellDefaultText, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { FOLDERS, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { UploadedFile } from 'Models/Files';
import { FolderVersion } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './FoldersTableRow.module.css';

export interface FoldersTableRowProps {
    folder: FolderVersion;
    users: UserResponse[];
    documentApi: DocumentApi;
}

interface UrlParams {
    third_party_id: string;
}

export const FoldersTableRow = (props: FoldersTableRowProps) => {
    const getFileName = (file: UploadedFile): JSX.Element => {
        return (
            <div key={file.file_id} className={styles.uploadedFileInTable}>
                <UploadedFileAndState file={file} documentApi={props.documentApi} />
            </div>
        );
    };

    const { third_party_id } = useParams<keyof UrlParams>() as UrlParams;

    return (
        <TableRow>
            <TableCellDefaultText>
                <div className={styles.folderTitleContainer}>
                    <Link size={'sm'} to={`/${TPRM}/${THIRD_PARTIES}/${third_party_id}/${FOLDERS}/${props.folder.id}`}>
                        {props.folder.name}
                    </Link>
                </div>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{props.folder.type}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>{props.folder.effective_date ? <Text noStyles>{iso8601ToUsDateShort(props.folder.effective_date)}</Text> : undefined}</TableCellDefaultText>
            <TableCellDefaultText>{props.folder.expiration_date ? <Text noStyles>{iso8601ToUsDateShort(props.folder.expiration_date)}</Text> : undefined}</TableCellDefaultText>
            <TableCell>{props.folder.files ? props.folder.files?.map((file) => getFileName(file)) : <Text color="blue">N/A</Text>}</TableCell>
            <TableCellDefaultText>
                <Text noStyles>{getUserNameFromSubject(props.folder.last_updated_by, props.users)}</Text>
            </TableCellDefaultText>
            <TableCellDefaultText>
                <Text noStyles>{iso8601ToUsDateShort(props.folder.last_updated)}</Text>
            </TableCellDefaultText>
        </TableRow>
    );
};

import { Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';

import { AdornedDonutChart } from 'Components/BaseCharts/AdornedDonutChart';
import { CSSColors } from 'Components/Colors';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { AssociatedControlsModal, AssociatedControlsModalProps } from 'Components/Modal/AssociatedControlsModal/AssociatedControlsModal';
import { Text } from 'Components/Text/Text';
import { ControlStrengthResponse } from 'Models/Dashboards';
import { OperationalControl, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';

import styles from './ControlStrength.module.css';

export interface ControlStrengthProps {
    controlStrength?: ControlStrengthResponse;
    controlStrengthError?: Error;
}

export const ControlStrength = (props: ControlStrengthProps): JSX.Element => {
    const robustColor = CSSColors.DARK_GREEN;
    const strongColor = CSSColors.LIGHT_GREEN;
    const moderateColor = CSSColors.YELLOW;
    const weakColor = CSSColors.ORANGE;
    const failColor = CSSColors.RED;
    const inactiveColor = CSSColors.DARK_GRAY;

    const [displayedControls, setDisplayedControls] = useState<{ controls: OperationalControl[]; title: string }>();

    const [activeControlsCount, totalControlsCount] = useMemo(() => {
        if (!props.controlStrength) {
            return [undefined, undefined];
        }

        const inactiveCount = props.controlStrength.controls_by_effectiveness.INACTIVE.length;
        const activeCount = Object.values(props.controlStrength.controls_by_effectiveness).reduce((total, arr) => total + arr.length, 0) - inactiveCount;
        return [activeCount, activeCount + inactiveCount];
    }, [props.controlStrength]);

    const handleChartClick = (item: string): void => {
        if (!props.controlStrength) {
            return;
        }

        let controls: OperationalControl[];
        switch (item) {
            case 'Robust':
                controls = props.controlStrength.controls_by_effectiveness.ROBUST;
                break;
            case 'Strong':
                controls = props.controlStrength.controls_by_effectiveness.STRONG;
                break;
            case 'Moderate':
                controls = props.controlStrength.controls_by_effectiveness.MODERATE;
                break;
            case 'Weak':
                controls = props.controlStrength.controls_by_effectiveness.WEAK;
                break;
            case 'Fail':
                controls = props.controlStrength.controls_by_effectiveness.FAIL;
                break;
            case 'Inactive':
                controls = props.controlStrength.controls_by_effectiveness.INACTIVE;
                break;
            default:
                return;
        }

        setDisplayedControls({ controls, title: item });
    };

    const associatedControlsModalProps: AssociatedControlsModalProps | undefined = (() => {
        if (!displayedControls) {
            return undefined;
        }

        return {
            hideModal: () => setDisplayedControls(undefined),
            associatedControls: displayedControls.controls,
            headerText: { text: 'Control Effectiveness', secondaryText: displayedControls.title },
        };
    })();

    if (props.controlStrengthError) {
        return (
            <div className={styles.controlStrength}>
                <PageCell variant="transparentBlue">
                    <Text variant="Header2" color="white">
                        Overall Control Effectiveness
                    </Text>
                    <Text variant="Header4" color="lightGray">
                        {props.controlStrengthError.message}
                    </Text>
                </PageCell>
            </div>
        );
    } else {
        return (
            <>
                {associatedControlsModalProps && <AssociatedControlsModal {...associatedControlsModalProps} />}
                <div className={styles.controlStrength}>
                    <PageCell variant="transparentBlue">
                        <Text variant="Header2" color="white">
                            Overall Control Effectiveness
                        </Text>
                        {props.controlStrength ? (
                            <AdornedDonutChart
                                name="Overall Control Effectiveness"
                                data={[
                                    { value: props.controlStrength.controls_by_effectiveness.ROBUST.length, name: 'Robust', color: robustColor },
                                    { value: props.controlStrength.controls_by_effectiveness.STRONG.length, name: 'Strong', color: strongColor },
                                    { value: props.controlStrength.controls_by_effectiveness.MODERATE.length, name: 'Moderate', color: moderateColor },
                                    { value: props.controlStrength.controls_by_effectiveness.WEAK.length, name: 'Weak', color: weakColor },
                                    { value: props.controlStrength.controls_by_effectiveness.FAIL.length, name: 'Fail', color: failColor },
                                    { value: props.controlStrength.controls_by_effectiveness.INACTIVE.length, name: 'Inactive', color: inactiveColor },
                                ]}
                                adornment={{ body: { value: props.controlStrength.controls_effectiveness_average.toFixed(1), fontSize: 60 }, subtitle: { value: `${numberAsEffectivenessString(numberAsEffectiveness(props.controlStrength.controls_effectiveness_average))}`, fontSize: 20 } }}
                                onChartClick={handleChartClick}
                            />
                        ) : (
                            <div className={styles.services}>
                                <Skeleton sx={{ width: '250px', height: '250px' }} variant="circular" />
                            </div>
                        )}

                        <div className={styles.services}>
                            {props.controlStrength ? (
                                <Text variant="Header4" color="lightGray">
                                    {`${activeControlsCount} of ${totalControlsCount} Controls Active`}
                                </Text>
                            ) : (
                                <Skeleton>
                                    <Text variant="Header4" color="lightGray">
                                        0 of ---- Controls
                                    </Text>
                                </Skeleton>
                            )}
                        </div>
                    </PageCell>
                </div>
            </>
        );
    }
};
